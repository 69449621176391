import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import TranslationService from "../../Services/translationService";
import ActionButton from "../../Components/ActionButton/actionButton";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import AuthInput from "../../Components/Inputs/authInput";
import { userLogin, removeLoginErrorMessage } from "../../Store/Actions/user";
import { FORGOT_PASSWORD_KEY } from "../../Constants/urlKeys";
import {
	BOOLEAN_KEY,
	INVALID_USERNAME_OR_PASSWORD,
	REMEMBER_ME_ITEM_KEY,
	REMEMBER_ME_KEY,
} from "../../Constants/mainKeys";
import {
	setCookie,
	setCookieForaMonth,
	setCookieForAnHour,
} from "../../Services/cookieService";
import { compose } from "redux";
import Input from "../../Components/Inputs/input";

class Login extends Component {
	state = {
		form: {
			username: "",
			password: "",
		},
		spinnerId: uuid(),
		isInvalidSubmit: false,
		errorMessage: "",
		isChecked: false,
		translationService: null,
	};

	componentDidMount() {
		this.setTranslations();
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	onChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		this.props.errorMessage && this.props.removeLoginErrorMessage();
		this.setState(prevState => ({
			...prevState,
			form: {
				...prevState.form,
				[event.target.name]: event.target.value,
			},
		}));
	};

	onTriggerCheckbox = event => {
		typeof event.target.checked === BOOLEAN_KEY &&
			this.setState({
				[event.target.name]: event.target.checked,
			});
	};

	changeIsinvalidFieldName = (isValid, fieldName) => {
		if (!isValid) {
			this.setState({ [fieldName]: true });
		} else {
			this.setState({ [fieldName]: false });
		}
	};

	onSubmit = e => {
		const {
			form,
			translationService,
			spinnerId,
			isInvalidUserName,
			isChecked,
		} = this.state;

		e.preventDefault();
		if (form.username && form.password && !isInvalidUserName) {
			this.props.userLogin({ ...form }, spinnerId, this.props.history);
			if (isChecked) {
				setCookieForaMonth(REMEMBER_ME_KEY, true);
				setCookie(REMEMBER_ME_ITEM_KEY, true);
			} else {
				setCookieForAnHour(REMEMBER_ME_KEY, true);
				setCookie(REMEMBER_ME_ITEM_KEY, false);
			}
		} else {
			this.setState({
				isInvalidSubmit: true,
				errorMessage: translationService.translate(
					"TR_LOGIN_REQUIRED_FIELDS_EXC",
				),
			});
		}
	};

	render() {
		const {
			isInvalidSubmit,
			errorMessage,
			isChecked,
			spinnerId,
			translationService,
			isInvalidUserName,
		} = this.state;

		const { username, password } = this.state.form;

		const { settings } = this.props;

		return translationService ? (
			settings ? (
				<div className="w-100" style={{ minHeight: "calc(100vh - 100px)" }}>
					<div className="container" style={{ marginTop: "40px" }}>
						<div className=" row justify-content-between align-items-stretch">
							{settings &&
							(settings.headerOrganizationName || settings?.mainLogoPath) ? (
								<div className="col-12 mb-4">
									<div className="d-flex justify-content-center mb-3">
										<img
											src={settings?.mainLogoPath}
											alt="/"
											style={{
												width: "100px",
												maxWidth: "150px",
												maxHeight: "150px",
											}}
										/>
									</div>

									{settings?.headerOrganizationName ? (
										<div className="info-cover-text-wrapper content-background w-100 content-transparent-background authorization-form m-md-0 p-2 blur-box">
											<p
												className="text-center authorization-form-title max-line-2 word-break-break-word"
												style={{
													color: settings?.textColor
														? settings?.textColor
														: "var(--mindalay--primary-color)",
												}}>
												{settings?.headerOrganizationName}
											</p>
										</div>
									) : null}
								</div>
							) : null}

							<div
								className={`d-flex justify-content-center col-md-6 ${
									!settings?.title || !settings?.description ? "" : "d-md-block"
								}`}
								style={{ margin: "0 auto" }}>
								<div
									className={`authorization-form content-background w-100 p-5 blur-box ${
										settings?.title || settings?.description ? "m-0 " : ""
									} `}>
									<p
										className="authorization-form-title"
										style={{
											color: settings?.textColor
												? settings?.textColor
												: "var(--mindalay--primary-color)",
										}}>
										{translationService.translate("TR_SIGN_IN")}
									</p>
									<form onSubmit={this.onSubmit}>
										{settings?.loginPrompt && settings?.passwordPrompt ? (
											<Input
												id="username"
												name="username"
												type="text"
												inputClassName={""}
												blockClassName={"pt-3"}
												value={username}
												textColor={settings?.textColor}
												isInvalidSubmit={isInvalidSubmit}
												labelValue={settings?.loginPrompt}
												placeholder={
													settings?.loginPlaceholder
														? settings?.loginPlaceholder
														: ` ${translationService.translate(
																"TR_USERNAME",
														  )} *`
												}
												onChange={this.onChange}
											/>
										) : (
											<AuthInput
												type="text"
												id="username"
												name="username"
												value={username}
												textColor={settings?.textColor}
												isInvalidSubmit={isInvalidSubmit}
												placeholder={
													settings?.loginPlaceholder
														? settings?.loginPlaceholder
														: ` ${translationService.translate(
																"TR_USERNAME",
														  )} *`
												}
												onChange={this.onChange}
											/>
										)}
										{isInvalidUserName ? (
											<small className="red-color">
												{translationService.translate("TR_INVALID_USERNAME")}
											</small>
										) : null}
										{settings?.loginPrompt && settings?.passwordPrompt ? (
											<Input
												type="password"
												id="password"
												name="password"
												value={password}
												textColor={settings?.textColor}
												isInvalidSubmit={isInvalidSubmit}
												labelValue={settings?.passwordPrompt}
												placeholder={
													settings?.passwordPlaceholder
														? settings?.passwordPlaceholder
														: ` ${translationService.translate(
																"TR_USERNAME",
														  )} *`
												}
												onChange={this.onChange}
											/>
										) : (
											<AuthInput
												type="password"
												id="password"
												name="password"
												value={password}
												textColor={settings?.textColor}
												isInvalidSubmit={isInvalidSubmit}
												placeholder={
													settings?.passwordPlaceholder
														? settings?.passwordPlaceholder
														: `${translationService.translate("TR_PASSWORD")} *`
												}
												onChange={this.onChange}
											/>
										)}
										<div className="fail-block">
											{isInvalidSubmit && errorMessage ? (
												<small className="fail mb-2 d-block">
													{errorMessage}
												</small>
											) : null}
											{this.props.errorMessage ===
											INVALID_USERNAME_OR_PASSWORD ? (
												<small className="fail mb-2 d-block">
													{translationService.translate("TR_INVALID_USER")}
												</small>
											) : null}
										</div>
										<div className="d-flex mb-3">
											<InputCheckBox
												id="rememberMe"
												name="isChecked"
												checked={isChecked}
												textColor={settings?.textColor}
												labelValue={`${translationService.translate(
													"TR_REMEMBER_ME",
												)}`}
												onChange={this.onTriggerCheckbox}
											/>
											<div className="flex-1 text-right">
												<Link
													to={`/${this.props.language}/${FORGOT_PASSWORD_KEY}`}
													className="text-main gray-color">
													{translationService.translate(
														"TR_FORGOT_YOUR_PASSWORD",
													)}
												</Link>
											</div>
										</div>
										<div className="mb-3">
											<ActionButton
												spinnerId={spinnerId}
												type="submit"
												className="mindalay--btn-dark w-100"
												name={translationService.translate("TR_SIGN_IN")}
											/>
										</div>
									</form>
								</div>
							</div>

							{(settings?.title || settings?.description) && (
								<div className="col-12 col-md-6 d-flex justify-content-end">
									<div className="info-cover-text-wrapper content-background w-100 content-transparent-background authorization-form m-md-0 p-5 blur-box">
										{settings?.title && (
											<p
												className="authorization-form-title"
												style={{
													color: settings?.textColor
														? settings?.textColor
														: "var(--mindalay--primary-color)",
												}}>
												{settings?.title}
											</p>
										)}
										{settings?.description && (
											<p
												className="info-cover-subtitle pt-3"
												style={{
													color: settings?.textColor
														? settings?.textColor
														: "var(--mindalay--black-color)",
												}}>
												{settings?.description}
											</p>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			) : (
				<>
					<div className="col-12 ">
						<div
							className="authorization-form content-background"
							style={{ maxWidth: "500px" }}>
							<p className="authorization-form-title">
								{translationService.translate("TR_SIGN_IN")}
							</p>
							<form onSubmit={this.onSubmit}>
								<AuthInput
									type="text"
									id="username"
									name="username"
									value={username}
									isInvalidSubmit={isInvalidSubmit}
									placeholder={`${translationService.translate(
										"TR_USERNAME",
									)} *`}
									onChange={this.onChange}
								/>
								{isInvalidUserName ? (
									<small className="red-color">
										{translationService.translate("TR_INVALID_USERNAME")}
									</small>
								) : null}
								<AuthInput
									type="password"
									id="password"
									name="password"
									value={password}
									isInvalidSubmit={isInvalidSubmit}
									placeholder={`${translationService.translate(
										"TR_PASSWORD",
									)} *`}
									onChange={this.onChange}
								/>
								<div className="fail-block">
									{isInvalidSubmit && errorMessage ? (
										<small className="fail mb-2 d-block">{errorMessage}</small>
									) : null}
									{this.props.errorMessage === INVALID_USERNAME_OR_PASSWORD ? (
										<small className="fail mb-2 d-block">
											{translationService.translate("TR_INVALID_USER")}
										</small>
									) : null}
								</div>
								<div className="d-flex mb-3">
									<InputCheckBox
										id="rememberMe"
										name="isChecked"
										checked={isChecked}
										labelValue={`${translationService.translate(
											"TR_REMEMBER_ME",
										)}`}
										onChange={this.onTriggerCheckbox}
									/>
									<div className="flex-1 text-right">
										<Link
											to={`/${this.props.language}/${FORGOT_PASSWORD_KEY}`}
											className="text-main gray-color">
											{translationService.translate("TR_FORGOT_YOUR_PASSWORD")}
										</Link>
									</div>
								</div>
								<div className="mb-3">
									<ActionButton
										spinnerId={spinnerId}
										type="submit"
										className="mindalay--btn-dark w-100"
										name={translationService.translate("TR_SIGN_IN")}
									/>
								</div>
							</form>
						</div>
					</div>
				</>
			)
		) : null;
	}
}

const mapStateToProps = state => {
	return {
		language: state.language.language,
		settings: state.settings.settings,
		translations: state.translation.translations,
		errorMessage: state.user.loginErrorMessage,
	};
};
const mapDispatchToProps = {
	userLogin,
	removeLoginErrorMessage,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(Login);
// export default connect(mapStateToProps, mapDispatchToProps)(Login);
