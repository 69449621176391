import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "./../../../Services/translationService";
import {
	ORGANIZATION_ADMIN_KEY,
	COURSES_KEY,
} from "../../../Constants/urlKeys";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import DollarSvg from "../../../Components/Svg/dollarSvg";
import UserSvg from "./../../../Components/Svg/userSvg";
import ReactPlayer from "react-player";

const CourseBlock = props => {
	const courseData = props.course;
	const currency = props.currency;

	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);
	return translationService && courseData ? (
		<div className="course-wrapper--instructor ">
			<div className="course-image-wrapper--instructor">
				<Auxiliary>
					{courseData.published ? (
						<span
							className="course-status--instructor course-live--instructor"
							style={{ left: "6px", right: "auto" }}>
							{translationService.translate("TR_LIVE")}
						</span>
					) : (
						<span
							className="course-status--instructor course-verified--instructor"
							style={{ left: "6px", right: "auto" }}>
							{translationService.translate("TR_IN_PROCESS")}
						</span>
					)}
				</Auxiliary>
				<div className="course-button-group" style={{ top: "0" }}>
					<Auxiliary>
						<Link
							className="course-edit-button mt-0"
							to={`/${language}/organization-admin/courses/${courseData.id}`}
							title={translationService.translate("TR_PAYMENT")}>
							{currency && currency.symbol ? (
								<span>{currency.symbol}</span>
							) : (
								<DollarSvg />
							)}
						</Link>
						<Link
							className="course-gamify-button"
							to={`/${language}/organization-admin/course/students/${courseData.id}`}
							title={translationService.translate("TR_STUDENTS")}>
							<UserSvg />
						</Link>
					</Auxiliary>
				</div>
				{courseData.imagePath ? (
					<div
						style={{ backgroundImage: `url(${courseData.imagePath})` }}
						className="course-image"
					/>
				) : courseData.videoLink ? (
					<div className="course-image">
						<ReactPlayer
							className="course-video-link"
							url={courseData?.videoLink}
							config={{
								youtube: {
									playerVars: { showinfo: 0, controls: 1 },
								},
							}}
						/>
					</div>
				) : (
					<div
						style={{ backgroundImage: `url(${courseData.imagePath})` }}
						className="course-image"
					/>
				)}
			</div>
			<div className="course-body-wrapper--instructor">
				<div className="p-2 border-bottom">
					<h6 className="course-name--instructor" title={courseData.name}>
						{courseData.name}
					</h6>
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(CourseBlock);
