import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import TranslationService from "../../Services/translationService";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import ApiService from "../../Services/apiService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import HomeWorkSvg from "../../Components/Svg/homeWorkSvg";
import TestsSvg from "../../Components/Svg/testsSvg";

function StudentsView(props) {
	const pageSize = 10;
	const { coursesId } = props.match.params;
	const [students, setStudents] = useState([]);
	const [quizAndAssignment, setQuizAndAssignment] = useState([]);
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const dispatch = useDispatch();
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getStudentsquizassignments();
	}, []);

	const getStudentsquizassignments = () => {
		if (!coursesId) return false;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getStudentsquizassignments(coursesId)
			.then(response => {
				if (response && response.data) {
					if (
						response.data.quizAssignmentResponseModels &&
						response.data.quizAssignmentResponseModels.length
					) {
						setQuizAndAssignment(response.data.quizAssignmentResponseModels);
					}
					if (response.data.students && response.data.students.length) {
						setStudents(response.data.students);
					}
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_STUDENTS")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_STUDENTS")}
						pageName="curator_lecturer_selection_committee_studens"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{students &&
			students.length &&
			quizAndAssignment &&
			quizAndAssignment.length ? (
				<div className="row">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th>{translationService.translate("TR_NAME")}</th>
										{quizAndAssignment.map((item, index) => (
											<th key={index}>
												<div className="d-flex  align-items-center justify-content-between">
													{item.type === 1 ? (
														<div
															className="th_icons green"
															title={translationService.translate("TR_QUIZ")}>
															<TestsSvg />
														</div>
													) : (
														<div
															className="th_icons blue"
															title={translationService.translate(
																"TR_ASSIGNMENT",
															)}>
															<HomeWorkSvg />
														</div>
													)}
													<span className="text-nowrap ml-1">{item.name}</span>
												</div>
											</th>
										))}
										{/* <th>{translationService.translate("TR_ACTIONS")}</th> */}
									</tr>
								</thead>
								<tbody>
									{students.map((student, index) => {
										return (
											<tr key={index} className="cursor-default">
												<td style={{ minWidth: "150px" }}>
													<div className="d-flex align-items-center">
														{student.avatarFilePath ? (
															<div
																className="navbar-profile-image background-image-cover background-image mr-2"
																style={{
																	backgroundImage: `url(https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg)`,
																	cursor: "pointer",
																	backgroundColor:
																		"var(--mindalay--secondary-color)",
																	border:
																		"2px solid var(--mindalay--white-color)",
																	boxSizing: "content-box",
																	width: "35px",
																	height: "35px",
																	borderRadius: "50%",
																	flex: "none",
																}}></div>
														) : (
															<div
																className="border d-flex justify-content-center align-items-center mr-2"
																style={{
																	cursor: "pointer",
																	border:
																		"2px solid var(--mindalay--white-color)",
																	backgroundColor:
																		"var(--mindalay--secondary-color)",
																	boxSizing: "content-box",
																	width: "35px",
																	height: "35px",
																	borderRadius: "50%",
																	flex: "none",
																}}>
																<b className="text-white">
																	{Array.from(student.name)[0]}
																</b>
															</div>
														)}

														<p className="mb-0 mt-1">{student.name}</p>
													</div>
												</td>
												{quizAndAssignment.map((item, i) => {
													const element =
														student.quizAssignmentResponseModels.find(
															el => el.type === item.type && el.id === item.id,
														);

													if (element) {
														return (
															<td
																key={i}
																className="cursor-pointer"
																onClick={() => {
																	props.history.push(
																		element.type === 0
																			? `/${language}/student/${student.id}/quiz/${element.id}`
																			: `/${language}/student/courses/${student.id}/${coursesId}/assignment/${element.id}`,
																	);
																}}
																style={{ minWidth: "150px" }}>
																{/* <Link
																	to={
																		element.type === 0
																			? `/${language}/student/${item.id}/quiz/${element.id}`
																			: `/${language}/student/courses/${item.id}/${coursesId}/assignment/${element.id}`
																	}> */}
																<div className="d-flex flex-column justify-content-between">
																	<div>
																		{element.isPassed !== null ? (
																			<div className="d-flex align-items-center justify-content-between mb-1">
																				<p className="fw-500">
																					{translationService.translate(
																						"TR_PASSED",
																					)}
																				</p>
																				{element.isPassed ? (
																					<div className="payment-modal-status-icon-block">
																						<i className="fas fa-check transaction-successful fs-20"></i>
																					</div>
																				) : (
																					<div className="payment-modal-status-icon-block">
																						<i className="fas fa-times transaction-unsuccessful fs-20"></i>
																					</div>
																				)}
																			</div>
																		) : null}
																		{element.isFailed !== null ? (
																			<div className="d-flex align-items-center justify-content-between mb-1">
																				<p className="fw-500">
																					{translationService.translate(
																						"TR_FAILED",
																					)}
																				</p>
																				{element.isFailed ? (
																					<div className="payment-modal-status-icon-block">
																						<i className="fas fa-check transaction-successful fs-20"></i>
																					</div>
																				) : (
																					<div className="payment-modal-status-icon-block">
																						<i className="fas fa-times transaction-unsuccessful fs-20"></i>
																					</div>
																				)}
																			</div>
																		) : null}
																		{element.isExpired !== null ? (
																			<div className="d-flex align-items-center justify-content-between mb-1">
																				<p className="fw-500">Expired</p>
																				{element.isExpired ? (
																					<div className="payment-modal-status-icon-block">
																						<i className="fas fa-check transaction-successful fs-20"></i>
																					</div>
																				) : (
																					<div className="payment-modal-status-icon-block">
																						<i className="fas fa-times transaction-unsuccessful fs-20"></i>
																					</div>
																				)}
																			</div>
																		) : null}
																		{element.grade !== null ? (
																			<div className="d-flex align-items-center justify-content-between mb-1">
																				<p className="fw-500">
																					{translationService.translate(
																						"TR_GRADE",
																					)}
																				</p>
																				<p>
																					<strong>{element.grade}</strong>
																				</p>
																			</div>
																		) : null}
																		{element.lecturerResponseNeeded !== null ? (
																			<div className="d-flex align-items-center justify-content-between mb-1">
																				<p className="fw-500 text-nowrap mr-3">
																					You have message
																				</p>
																				<p>
																					<div className="circle-impulse" />
																				</p>
																			</div>
																		) : null}
																	</div>
																	{/* <div>
																		<button
																			className="mindalay--btn mindalay--btn-small mindalay--btn-default w-100 "
																			onClick={() => props.history.push(`/`)}>
																			{translationService.translate("TR_VIEW")}
																		</button>
																	</div> */}
																</div>
																{/* </Link> */}
															</td>
														);
													}
													return (
														<td key={i} style={{ minWidth: "150px" }}></td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div>
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				</div>
			)}
		</div>
	) : null;
}

export default withRouter(StudentsView);
