import React, { useState, useEffect, Children } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MindalaySvg from "../../../Components/Svg/midalaySvg";
import TranslationService from "../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import Collapse from "react-bootstrap/Collapse";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";

const OrganizationAdminLeftmenu = props => {
	const dispatch = useDispatch();
	const language = useSelector(state => state.language.language);
	const { user } = useSelector(state => state.user);
	const translations = useSelector(state => state.translation.translations);
	const showOrHideGeneralLeftMenu = useSelector(
		state => state.generalLeftMenu.showOrHideGeneralLeftMenu,
	);
	const [translationService, setTranslationService] = useState(null);
	const unreadMessgesCount = useSelector(
		state => state.signalR.unreadMessgesCount,
	);
	const { settings } = useSelector(state => state.settings);
	const { availablePages } = useSelector(state => state.user);
	const [availablePageId, setAvailablePageId] = useState(null);
	// const [currentUserCountry, setCurrentUserCountry] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	// useEffect(() => {
	//   getCurrentUserCountry();
	//   return () => {
	//     setCurrentUserCountry(null);
	//   };
	// }, []);

	// const getCurrentUserCountry = () => {
	//   const spinnerId = uuid();
	//   dispatch(addPartialViewSpinner(spinnerId))
	//   GuestApiService.getIpLocationData().then(response => {
	//     if (response && response.data && response.data.country) {
	//       setCurrentUserCountry(response.data.country);
	//     }
	//     dispatch(removePartialViewSpinner(spinnerId))
	//   }).catch(error => getFail(error, spinnerId))
	// }

	// const getFail = (error, spinnerId) => {
	//   error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
	//   spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	// }

	const showOrHideLeftMenu = bool => {
		dispatch(showOrHideGeneralDashboardLeftMenu(bool));
	};

	const setMenuClass = field => {
		if (typeof field === "string" && props.location.pathname.includes(field)) {
			return "active-side-left-menu-item";
		}
		if (
			props.location.pathname.includes(`/${field.uri}`) &&
			field.uri !== "/"
		) {
			return "active-side-left-menu-item";
		}
		if (field.uri === "/" && field.children && field.children.length) {
			let isExist = field.children.find(item =>
				props.location.pathname.includes(item.uri),
			);
			if (isExist) {
				return "active-side-left-menu-item";
			}
		}
		return "";
	};

	const setSubMenuClass = field => {
		if (typeof field === "string" && props.location.pathname.includes(field)) {
			return "ml-2";
		}
		return "";
	};

	const showOrHideSubPages = (pageId, uri, subAvailablePages) => {
		if (pageId === availablePageId) {
			setAvailablePageId(null);
		} else {
			setAvailablePageId(pageId);
		}
		checkUriAndRedirect(uri, subAvailablePages);
	};

	const checkUriAndRedirect = (uri, subAvailablePages) => {
		if (
			uri === "organization-admin/courses" &&
			props.location.pathname.includes(uri)
		) {
			return false;
		}
		if (subAvailablePages && !subAvailablePages.length) {
			props.history.push(`/${language}/${uri}`);
			showOrHideLeftMenu(false);
			localStorage.removeItem("collapseIds");
		}
	};

	// const showGamifyMenu = () => {
	// 	if (!user || (user && !user.isGamifyAvailable)) {
	// 		return false;
	// 	}
	// 	if (
	// 		user.userTypeId === ADMIN_USER_TYPE ||
	// 		(user.defaultUserTypeIds &&
	// 			user.defaultUserTypeIds.includes(ADMIN_USER_TYPE))
	// 	) {
	// 		return true;
	// 	}
	// };

	return translationService && user ? (
		<section
			className={`side-menu-container ${showOrHideGeneralLeftMenu ? "show-general-left-menu" : ""
				}`}>
			<div
				className={showOrHideGeneralLeftMenu ? "layer" : ""}
				onClick={() => showOrHideLeftMenu(false)}
			/>
			<aside
				className={`side-left instructor-header-zindex w-fix-left-menu-organization side-left-organization`}>
				<div className="side-left-container">
					<div className="side-left-inner-wrapper">
						<ul className="side-left-list-wrapper">
							<li
								className={`side-left-menu-item has-submenu d-inline-flex w-100`}>
								<Link
									to={`/${language}`}
									title={translationService.translate("TR_HOME")}
									className="p-0 d-flex justify-content-between"
									onClick={() => showOrHideLeftMenu(false)}>
									{settings && settings.mainLogoPath ? (
										<img src={settings.mainLogoPath} alt="/" style={{ width: "120px", maxWidth: "150px", maxHeight: "150px", display: "block", margin: "0 auto" }} />
									) : (
										<MindalaySvg />
									)}
								</Link>
							</li>
							{
								settings && settings.headerOrganizationName ?
									<li
										className={`side-left-menu-item w-100 px-3`}
										style={{ pointerEvents: "none" }}
									>
										<h6
											className="text-center w-100 word-break-break-word"
											title={settings.headerOrganizationName}
											style={{ lineHeight: "25px" }}
										>
											{settings.headerOrganizationName}
										</h6>
									</li>
									: null
							}
							<br />
							<ul>
								<Auxiliary>
									{availablePages && availablePages.length
										? availablePages.map(availablePage => {
											const subAvailablePages = availablePage.children;
											if (availablePage) {
												if (
													availablePage.name === "TR_CONTAINER_TWO_PAGE" ||
													availablePage.name === "TR_CONTAINER_FIVE_PAGE" ||
													availablePage.name === "TR_CONTAINER_TWO_PAGE" ||
													availablePage.name === "TR_CONTAINER_THREE_PAGE" ||
													availablePage.name ===
													"TR_ORGANIZATION_SETTINGS_PAGE"
												) {
													return null;
												} else {
													return (
														<li
															key={availablePage.id}
															className={`
                        side-left-menu-item d-block has-submenu w-100 p-0 mb-0
                        ${availablePage.uri ===
																	"organization-admin/payment-package"
																	? setMenuClass(
																		availablePage,
																		"organization-admin/history",
																	)
																	: setMenuClass(availablePage)
																} 
                         `}>
															<Link
																to="#"
																title={translationService.translate(
																	availablePage.name,
																)}
																className={` nav-link d-block 
                              ${subAvailablePages && subAvailablePages.length
																		? "dropdown-toggle dropdown-toggle-organization"
																		: ""
																	}
                              `}
																onClick={() =>
																	showOrHideSubPages(
																		availablePage.id,
																		availablePage.uri,
																		subAvailablePages,
																	)
																}>
																{translationService.translate(
																	availablePage.name,
																)}
																{availablePage.uri.includes("message") &&
																	unreadMessgesCount ? (
																	<span className="unread-messages-count-org">
																		{unreadMessgesCount}
																	</span>
																) : null}
															</Link>
															{subAvailablePages &&
																subAvailablePages.length ? (
																<ul className="organization-dropdown-sub-pages mt-1 ml-4 d-block">
																	{subAvailablePages.map(subAvailablePage => {
																		return (
																			<li
																				key={subAvailablePage.id}
																				className={`nav-item`}>
																				<Collapse
																					in={
																						availablePageId ===
																						availablePage.id
																					}>
																					<div className=" content-block">
																						<Link
																							to="#"
																							title={translationService.translate(
																								subAvailablePage.name,
																							)}
																							className={`nav-link left-submenu-item pl-2 ${setSubMenuClass(
																								subAvailablePage.uri,
																							)}`}
																							onClick={() => {
																								checkUriAndRedirect(
																									subAvailablePage.uri,
																									subAvailablePage.children,
																								);
																							}}>
																							{translationService.translate(
																								subAvailablePage.name,
																							)}
																						</Link>
																					</div>
																				</Collapse>
																			</li>
																		);
																	})}
																</ul>
															) : null}
														</li>
													);
												}
											}
										})
										: null}
								</Auxiliary>
							</ul>
						</ul>
					</div>
				</div>
			</aside>
		</section>
	) : null;
};

export default withRouter(OrganizationAdminLeftmenu);
