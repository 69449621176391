import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import EditSvg from "../../Components/Svg/editSvg";
import DeleteSvg from "../../Components/Svg/deleteSvg";
import FileSvg from "../../Components/Svg/fileSvg";
import ButtonSpinner from "../../Components/Spinners/buttonSpinner";
import uuid from "react-uuid";
import CloseSvg from "../../Components/Svg/closeSvg";
import videoSvg from "./../../assets/icons/video.svg";
import audioSvg from "./../../assets/icons/audio.svg";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import { TR_DELETE_MESSAGE_KEY } from "../../Constants/translationKeys";
import {
	FORM_KEY,
	FILE_LIBRARY_KEY,
	INSTRUCTOR_KEY,
	COURSES_KEY,
	FILELIBRARY_KEY,
	FILE_LIBRARY_CATEGORY_KEY,
} from "../../Constants/urlKeys";
import { getCurrentQuestionMarkData } from "./../../Store/Actions/main";
import HelpService from "./../../Services/helpService";
import {
	ACTIVE_TAB_KEY,
	AUDIO_KEY,
	ERROR_KEY,
	IMAGE_KEY,
	SUCCESS_KEY,
	TR_DELETE_CONFIRM_MESSAGE_KEY,
	TR_NO,
	TR_YES,
	VIDEO_KEY,
} from "../../Constants/mainKeys";
import { compose } from "redux";
import SmallQuestionMark from "../../Components/SmallQuestionMark/smallQuestionMark";
import ReactPaginate from "react-paginate";
import NoDataImage from "./../../assets/images/illustrations/nodata.svg";
import DownloadSvg from "./../../Components/Svg/downloadSvg";
import Input from "../../Components/Inputs/input";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import MainService from "../../Services/mainService";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import CopySvg from "./../../Components/Svg/copy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CustomTitle from "../../Components/CustomTitle/customTitle";

const WAIT_INTERVAL = 500;

class FileLibraryView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultFiles: [],
			files: [],
			fileCategories: [],
			currentCategories: [],
			breadCrumbData: [],
			deleteFileId: null,
			translationService: null,
			zoomImagePath: null,
			zoomVideoPath: null,
			zoomAudioPath: null,
			pagination: null,
			activePageNumber: 0,
			pageSize: 10,
			searchText: "",
			serachTextMaxLength: 120,
			isSort: false,
		};
		this.timer = null;
	}

	componentDidMount() {
		this.setTranslations();
		this.getFileLibraries(1);
		window.addEventListener("keydown", this.zoomOut);
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.zoomOut);
	}

	componentDidUpdate() {
		this.setTranslations();
		if (this.props.fileCategories !== this.state.fileCategories) {
			this.setState({
				fileCategories: this.props.fileCategories,
				currentCategories: this.props.fileCategories,
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	handlePageClick = event => {
		this.getFileLibraries(event.selected + 1);
		this.setState({ activePageNumber: event.selected, isSort: false });
	};

	getFileLibraries = (currentPage, withoutSpinner) => {
		const { pageSize } = this.state;
		const spinnerId = uuid();
		!withoutSpinner && this.props.addPartialViewSpinner(spinnerId);
		ApiService.getLibraryFiles(currentPage, pageSize)
			.then(response => {
				if (response.data) {
					const data = [...response.data];
					// if (data && data.length) {
					// 	data.forEach(file => {
					// 		if (file.mimeType.includes(IMAGE_KEY)) {
					// 			file.filePath = `${file.filePath}?${uuid()}`;
					// 		}
					// 	});
					// }
					this.setState({
						files: data,
						defaultFiles: data,
						currentCategories: [...this.props.fileCategories],
						breadCrumbData: [],
						pagination: response.pagination
							? JSON.parse(response.pagination)
							: null,
					});
				}
				this.props.removePartialViewSpinner(spinnerId);
			})
			.catch(e => this.getFail(spinnerId, e));
	};

	getFileLibrariesByCategoryId = category => {
		const spinnerId = uuid();
		this.props.addPartialViewSpinner(spinnerId);
		if (category.id) {
			ApiService.getFileLibraryByCategoryId(category.id)
				.then(response => {
					const breadCrumbData = [...this.state.breadCrumbData];
					const breadCrumbCategoryIndex = breadCrumbData.findIndex(
						data => data.id === category.id,
					);
					if (breadCrumbCategoryIndex === -1) {
						breadCrumbData.push({
							id: category.id,
							name: category.name,
							children: category.children,
						});
					} else {
						breadCrumbData.splice(breadCrumbCategoryIndex + 1);
					}
					this.setState({
						files: response.data,
						defaultFiles: response.data,
						currentCategories: category.children,
						breadCrumbData,
					});
					this.props.removePartialViewSpinner(spinnerId);
				})
				.catch(error => this.getFail(spinnerId, error));
			this.setState({ currentCategoryName: category.name });
		}
	};

	onChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		if (!event.target.value) {
			this.getFileLibraries(1, true);
		}
		clearTimeout(this.timer);
		this.setState({ [event.target.name]: event.target.value }, () => {
			this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
		});
	};

	onCheckboxChange = event => {
		const { files, defaultFiles } = this.state;
		if (event.target.checked) {
			const sortedByAge = [...files].sort((a, b) => b.fileSize - a.fileSize);
			this.setState({ files: sortedByAge, isSort: true });
		} else {
			this.setState({ files: defaultFiles, isSort: false });
		}
	};

	triggerChange = () => {
		var { searchText } = this.state;
		searchText = searchText.trim();
		if (searchText.trim().length > 1) {
			ApiService.getLibraryFiles(1, this.state.pageSize, searchText.trim())
				.then(response => {
					this.setState({
						files: response.data,
						defaultFiles: response.data,
						currentCategories: [...this.props.fileCategories],
						breadCrumbData: [],
						pagination: response.pagination
							? JSON.parse(response.pagination)
							: null,
					});
				})
				.catch(e => this.getFail(null, e));
		}
		// this.props.setSearchText(name);
	};

	delete = file => {
		const { translationService } = this.state;
		const fileId = file.id;
		const spinnerId = `file-${fileId}`;
		if (fileId) {
			AlertService.alertConfirm(
				`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${
					file.name
				} ?`,
				"",
				translationService.translate(TR_YES),
				translationService.translate(TR_NO),
			)
				.then(() => {
					this.setState({ deleteFileId: fileId });
					this.props.addButtonSpinner(spinnerId);
					ApiService.libraryFileDelete(fileId)
						.then(() => {
							AlertService.alert(
								SUCCESS_KEY,
								translationService.translate(TR_DELETE_MESSAGE_KEY),
							);
							this.props.removeButtonSpinner(spinnerId);
							const files = this.state.files.filter(data => data.id !== fileId);
							this.setState({ files });
						})
						.catch(e => this.deleteFail(spinnerId, e));
				})
				.catch(e => this.deleteFail(spinnerId, e));
		}
	};

	zoomOut = event => {
		if (event.key === "Escape") {
			this.cancel();
		}
	};

	zoomImage = imagePath => {
		if (!imagePath) {
			return;
		}
		this.setState({ zoomImagePath: imagePath });
	};

	zoomVideo = videoPath => {
		if (!videoPath) {
			return;
		}
		this.setState({ zoomVideoPath: videoPath });
	};

	zoomAudio = audioPath => {
		if (!audioPath) {
			return;
		}
		this.setState({ zoomAudioPath: audioPath });
	};

	cancel = () => {
		this.setState({
			zoomImagePath: null,
			zoomVideoPath: null,
			zoomAudioPath: null,
		});
	};

	getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(this.props.location.pathname)) {
			this.props.getCurrentQuestionMarkData(
				HelpService.setPageName(this.props.location.pathname),
				key,
			);
		}
	};

	getFail = (spinnerId, error) => {
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	deleteFail = (spinnerId, error) => {
		spinnerId && this.props.removeButtonSpinner(spinnerId);
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	bytesToSize = bytes => {
		var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes == 0) return "0";
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
	};

	render() {
		const {
			translationService,
			files,
			deleteFileId,
			breadCrumbData,
			currentCategories,
			zoomImagePath,
			zoomVideoPath,
			zoomAudioPath,
			pagination,
			activePageNumber,
			searchText,
			serachTextMaxLength,
			isSort,
		} = this.state;

		const { language, buttonSpinners, user } = this.props;
		const currentButtonSpinner = buttonSpinners.find(
			spinner => spinner === `file-${deleteFileId}`,
		);

		return translationService ? (
			<Auxiliary>
				<div className="row mt-3">
					{zoomImagePath ? (
						<div className="zoom-image-container" onClick={this.cancel}>
							<div className="close-svg">
								<CloseSvg />
							</div>
							<img
								src={zoomImagePath}
								alt="/"
								onClick={event => event.stopPropagation()}
							/>
						</div>
					) : null}
					{zoomVideoPath ? (
						<div className="zoom-image-container" onClick={this.cancel}>
							<div className="zoom-image-block">
								<div className="close-svg">
									<CloseSvg />
								</div>
								<video
									controls
									controlsList="nodownload nopictonpicture"
									disablePictureInPicture={true}
									onClick={event => event.stopPropagation()}>
									<source src={zoomVideoPath} type="video/mp4" />
								</video>
							</div>
						</div>
					) : null}
					{zoomAudioPath ? (
						<div className="zoom-image-container" onClick={this.cancel}>
							<div className="zoom-image-block">
								<div className="close-svg">
									<CloseSvg />
								</div>
								<audio src={zoomAudioPath} controls />
							</div>
						</div>
					) : null}
				</div>
				<div className="row mt-2">
					<div className="col-12">
						<p>{translationService.translate("TR_FILE_LIBRARY_INFO")}</p>
						<hr />
						<div className="d-sm-flex align-items-center">
							<div className="d-flex align-items-center">
								<Link
									to={
										this.props.user.isOrganizationUser
											? `/${language}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}`
											: `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}`
									}
									className="mindalay--btn-default w-100 text-center"
									onClick={() => {
										localStorage.setItem(ACTIVE_TAB_KEY, "file-library");
									}}>
									{translationService.translate("TR_CREATE_FOLDER")}
								</Link>
								<SmallQuestionMark
									id="folder_create"
									blockClassName="ml-2"
									onMouseEnter={() => this.getQuestionMarkData("folder_create")}
								/>
							</div>
							<div className="d-flex align-items-center ml-sm-4 mt-3 mt-sm-0">
								<Link
									to={
										!user.isOrganizationUser
											? `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_KEY}/${FORM_KEY}`
											: `/${language}/${FILELIBRARY_KEY}/${FORM_KEY}`
									}
									className="mindalay--btn-default mr-2 w-100 text-center">
									{translationService.translate("TR_CREATE_FILE_LIBRARY")}
								</Link>
								<SmallQuestionMark
									id="file_library_create"
									onMouseEnter={() =>
										this.getQuestionMarkData("file_library_create")
									}
								/>
							</div>
						</div>
						<hr className="mb-0" />
					</div>
				</div>
				<div className="row align-items-end mt-2">
					{!breadCrumbData.length ? (
						<div className="col-md-6">
							<Input
								type="text"
								id="searchText"
								name="searchText"
								value={searchText}
								inputClassName="ml-1"
								labelValue={`${translationService.translate(
									"TR_SEARCH_FILE_BY_NAME",
								)}`}
								placeholder={`${translationService.translate(
									"TR_SEARCH_FILE_BY_NAME",
								)}`}
								labelClassName="mt-2"
								onChange={event => this.onChange(event, serachTextMaxLength)}
							/>
						</div>
					) : null}
					{files && files.length ? (
						<div className="col-md-12">
							<InputCheckBox
								id="isLifeTime"
								name="isLifeTime"
								checked={isSort}
								labelClassName="cursor-pointer"
								labelValue={translationService.translate(
									"TR_SORT_FILES_BY_SIZE",
								)}
								onChange={this.onCheckboxChange}
							/>
						</div>
					) : null}
				</div>
				<div className="row mt-2">
					{breadCrumbData?.length ? (
						<div className="col-12 mb-3">
							<ul className="bread-crump d-flex flex-wrap file-category">
								<li
									className="bread-crump-item pointer"
									onClick={() => this.getFileLibraries(1)}>
									{translationService.translate("TR_HOME")}
									<i className="fas fa-chevron-right mr-3 ml-3"></i>
								</li>
								{breadCrumbData.map(item => (
									<li
										key={item.id}
										className="bread-crump-item pointer word-break-break-word"
										onClick={() => this.getFileLibrariesByCategoryId(item)}>
										{item.name}
										<i className="fas fa-chevron-right mr-3 ml-3"></i>
									</li>
								))}
							</ul>
						</div>
					) : null}
					<div className="col-12">
						{currentCategories && currentCategories.length
							? currentCategories.map(category => (
									<button
										key={category.id}
										type="button"
										title={category.name}
										className="mindalay--btn-default mr-2 mindalay--btn-small folder-button ml-0"
										onClick={() => this.getFileLibrariesByCategoryId(category)}>
										{category.name}
									</button>
							  ))
							: null}
					</div>

					{files && files.length ? (
						<div className="col-12">
							<div className="mindayal-admin-table mt-2  table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>{translationService.translate("TR_FILE")}</th>
											<th>{translationService.translate("TR_NAME")}</th>
											<th>{translationService.translate("TR_FOLDER")}</th>
											<th>{translationService.translate("TR_SIZE")}</th>
											<th>{translationService.translate("TR_ACTIONS")}</th>
										</tr>
									</thead>
									<tbody>
										{files.map((file, index) => {
											return (
												<tr key={index} className="cursor-default ">
													{file.mimeType &&
													file.mimeType.includes(IMAGE_KEY) ? (
														<td>
															<div
																className="file-library-item cursor-pointer"
																style={{
																	backgroundImage: `url(${file.filePath})`,
																}}
																onClick={() => this.zoomImage(file.filePath)}
															/>
														</td>
													) : file.mimeType &&
													  file.mimeType.includes(AUDIO_KEY) ? (
														<td>
															<div
																className="file-library-item cursor-pointer"
																style={{ backgroundImage: `url(${audioSvg})` }}
																onClick={() => this.zoomAudio(file.filePath)}
															/>
														</td>
													) : file.mimeType &&
													  file.mimeType.includes(VIDEO_KEY) ? (
														<td>
															<div
																className="file-library-item cursor-pointer"
																style={{ backgroundImage: `url(${videoSvg})` }}
																onClick={() => this.zoomVideo(file.filePath)}
															/>
														</td>
													) : file.mimeType &&
													  (file.mimeType.includes("pdf") || //pdf
															file.mimeType.includes("text") || //txt
															file.mimeType.includes("application")) ? (
														<td>
															<div className="file-library-item cursor-pointer">
																{file.mimeType &&
																(file.mimeType.includes(
																	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
																) ||
																	file.mimeType.includes(
																		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
																	)) ? (
																	<div className="file-item">
																		<a
																			href={MainService.getDownloadUrl(
																				file.filePath,
																			)}
																			target="blank"
																			className="float-right brand-color">
																			<FileSvg />
																		</a>
																	</div>
																) : null}
																{file.mimeType &&
																!file.mimeType.includes(
																	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
																) &&
																!file.mimeType.includes(
																	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
																) ? (
																	<div
																		className="file-item"
																		onClick={() => {
																			window.open(file.filePath);
																		}}>
																		<FileSvg />
																	</div>
																) : null}
															</div>
														</td>
													) : null}
													<td className="word-break-break-word">{file.name}</td>
													<td className="word-break-break-word">
														{file.categoryName}
													</td>
													<td>
														<p className="no-wrap">
															{this.bytesToSize(file.fileSize)}
														</p>
													</td>
													<td>
														<div className="table-action-btn-group d-flex align-items-center">
															<CustomTitle
																title={translationService.translate("TR_EDIT")}>
																<Link
																	to={
																		user.isOrganizationUser
																			? `/${language}/${FILELIBRARY_KEY}/${FORM_KEY}/${file.id}`
																			: `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_KEY}/${FORM_KEY}/${file.id}`
																	}
																	className="table-action-btn edit-btn">
																	<EditSvg />
																</Link>
															</CustomTitle>
															{currentButtonSpinner &&
															file.id === deleteFileId ? (
																<ButtonSpinner spinner={currentButtonSpinner} />
															) : (
																<CustomTitle
																	title={translationService.translate(
																		"TR_DELETE",
																	)}>
																	<Link
																		to="#"
																		className="table-action-btn delete-btn"
																		onClick={() => this.delete(file)}>
																		<DeleteSvg />
																	</Link>
																</CustomTitle>
															)}
															<CustomTitle
																title={translationService.translate(
																	"TR_DOWNLOAD",
																)}>
																<a
																	className="table-action-btn add-btn "
																	href={`${MainService.getDownloadUrl(
																		file.filePath,
																	)}`}
																	target="blank">
																	<DownloadSvg />
																</a>
															</CustomTitle>
															{file.mimeType.includes(IMAGE_KEY) ? (
																<CustomTitle
																	title={translationService.translate(
																		"TR_FILE_COPY_TEXT",
																	)}>
																	<Link
																		to="#"
																		title="Copy file Id"
																		className="table-action-btn edit-btn"
																		onClick={event => {
																			event.preventDefault();
																			file.id &&
																				navigator.clipboard.writeText(file.id);
																			file.id &&
																				AlertService.alert(
																					SUCCESS_KEY,
																					translationService.translate(
																						"TR_COPIED",
																					),
																				);
																		}}>
																		<CopySvg />
																	</Link>
																</CustomTitle>
															) : (
																<CustomTitle
																	title={translationService.translate(
																		"TR_COPY_FILE_PATH",
																	)}>
																	<Link
																		to="#"
																		className="table-action-btn edit-btn"
																		onClick={event => {
																			event.preventDefault();
																			file.filePath &&
																				navigator.clipboard.writeText(
																					file.filePath,
																				);
																			file.filePath &&
																				AlertService.alert(
																					SUCCESS_KEY,
																					translationService.translate(
																						"TR_COPIED",
																					),
																				);
																		}}>
																		<CopySvg />
																	</Link>
																</CustomTitle>
															)}
														</div>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					) : (
						<div className="no-data-container mt-4">
							<div className="no-data-wrapper">
								<p>{translationService.translate("TR_NO_DATA")}</p>
								<img src={NoDataImage} alt="/" />
							</div>
						</div>
					)}
				</div>
				<div className="row mt-3">
					{pagination && !breadCrumbData.length ? (
						<div className="col-12">
							<div className="d-flex justify-content-md-end justify-content-center">
								<ReactPaginate
									nextLabel={translationService.translate("TR_NEXT")}
									onPageChange={this.handlePageClick}
									pageRangeDisplayed={3}
									marginPagesDisplayed={2}
									pageCount={pagination.TotalPages}
									previousLabel={translationService.translate("TR_PREVIOUS")}
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									breakLabel="..."
									breakClassName="page-item"
									breakLinkClassName="page-link"
									containerClassName="pagination"
									activeClassName="active"
									renderOnZeroPageCount={null}
									forcePage={activePageNumber}
								/>
							</div>
						</div>
					) : null}
				</div>
			</Auxiliary>
		) : null;
	}
}

const mapStateToProps = state => ({
	buttonSpinners: state.spinner.buttonSpinners,
	translations: state.translation.translations,
	language: state.language.language,
	user: state.user.user,
	currentQuestionMarkData: state.main.currentQuestionMarkData,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
	getCurrentQuestionMarkData,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(FileLibraryView);
