import React from "react";

function StudentsSvg(props) {
	return (
		<svg
			fill="#000000"
			height="800px"
			width="800px"
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 345.628 345.628"
			xmlSpace="preserve">
			<path
				d="M319.001,345.628c-3.161,0-6.154-1.885-7.416-4.994l-3.925-9.674c-13.417-33.055-29.202-47.158-52.782-47.158h-30
	c-9.103,0-16.931,2.116-23.933,6.469c-3.752,2.334-8.685,1.182-11.018-2.57s-1.182-8.686,2.57-11.018
	c9.479-5.893,20.373-8.881,32.379-8.881h30c39.811,0,57.652,32.611,67.609,57.142l3.925,9.675c1.661,4.095-0.312,8.76-4.405,10.421
	C321.021,345.438,320.002,345.628,319.001,345.628z M184.875,345.628c-3.161,0-6.154-1.885-7.416-4.994l-3.925-9.674
	c-13.415-33.055-29.2-47.158-52.782-47.158H90.751c-23.582,0-39.368,14.104-52.783,47.159l-3.925,9.673
	c-1.662,4.095-6.328,6.063-10.421,4.405c-4.094-1.661-6.066-6.327-4.405-10.421l3.925-9.674
	c9.956-24.531,27.796-57.143,67.609-57.143h30c39.815,0,57.655,32.611,67.61,57.142l3.925,9.675
	c1.661,4.095-0.312,8.76-4.405,10.421C186.895,345.438,185.876,345.628,184.875,345.628z M239.874,252.384
	c-24.764,0-44.91-20.147-44.91-44.912c0-20.389,13.656-37.648,32.302-43.11c0.1-0.033,0.2-0.063,0.302-0.093
	c3.992-1.135,8.133-1.71,12.307-1.71c22.9,0,41.916,16.941,44.596,39.558c0.21,1.757,0.317,3.543,0.317,5.355
	C284.787,232.236,264.64,252.384,239.874,252.384z M224.105,183.249c-7.905,5.165-13.141,14.095-13.141,24.223
	c0,15.942,12.969,28.912,28.91,28.912c13.994,0,25.697-9.992,28.35-23.216c-0.579,0.022-1.158,0.033-1.739,0.033
	C246.966,213.201,230.269,200.89,224.105,183.249z M239.433,178.562c4.14,11.011,14.721,18.639,27.052,18.639
	c0.14,0,0.278-0.001,0.417-0.003C262.699,186.183,251.924,178.403,239.433,178.562z M105.75,252.384
	c-24.764,0-44.912-20.147-44.912-44.912c0-24.712,20.062-44.826,44.754-44.912c0.113-0.001,0.227-0.001,0.34-0.001
	c23.2,0,42.413,17.39,44.691,40.451c0.062,0.636,0.049,1.265-0.035,1.876c0.049,0.855,0.074,1.719,0.074,2.586
	C150.662,232.236,130.514,252.384,105.75,252.384z M90.128,183.153c-7.989,5.149-13.29,14.127-13.29,24.318
	c0,15.942,12.97,28.912,28.912,28.912c13.99,0,25.692-9.989,28.347-23.209c-0.518,0.018-1.037,0.026-1.556,0.026
	C112.987,213.201,96.265,200.845,90.128,183.153z M105.489,178.562c4.14,11.011,14.721,18.64,27.052,18.64
	c0.079,0,0.158,0,0.236-0.001c-4.151-10.886-14.701-18.641-27.027-18.641C105.663,178.561,105.576,178.56,105.489,178.562z
	 M235.345,148.53c-2.589,0-5.128-1.254-6.669-3.57c-10.763-16.182-23.746-23.718-40.859-23.718h-30.003
	c-17.114,0-30.098,7.536-40.861,23.718c-2.448,3.679-7.415,4.677-11.092,2.23c-3.679-2.447-4.677-7.413-2.23-11.092
	c13.619-20.475,31.849-30.856,54.183-30.856h29.999c22.337,0,40.566,10.382,54.186,30.856c2.447,3.679,1.448,8.645-2.23,11.092
	C238.406,148.097,236.866,148.53,235.345,148.53z M172.813,89.825c-24.763,0-44.91-20.147-44.91-44.912
	C127.903,20.147,148.05,0,172.813,0c0.061,0,0.122,0,0.182,0c23.199,0,42.413,17.39,44.692,40.45
	c0.063,0.637,0.049,1.266-0.034,1.877c0.049,0.855,0.073,1.719,0.073,2.586C217.726,69.678,197.578,89.825,172.813,89.825z
	 M157.192,20.594c-7.988,5.15-13.289,14.128-13.289,24.319c0,15.942,12.969,28.912,28.91,28.912c13.992,0,25.694-9.989,28.349-23.21
	c-0.519,0.018-1.038,0.026-1.559,0.026C180.05,50.642,163.328,38.285,157.192,20.594z M172.553,16.003
	c4.139,11.011,14.719,18.639,27.049,18.639c0.079,0,0.159,0,0.238-0.001C195.699,23.78,185.192,16.038,172.904,16
	C172.787,16.001,172.67,16.002,172.553,16.003z"
			/>
		</svg>
	);
}

export default StudentsSvg;
