import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "./../../Services/translationService";
import {
	ORGANIZATION_KEY,
	COURSE_DASHBOARD_KEY,
	COURSE_KEY,
} from "./../../Constants/urlKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ViewSvg from "../../Components/Svg/viewSvg";
import EditSvg from "../../Components/Svg/editSvg";
import CopySvg from "../../Components/Svg/copy";
import GameSvg from "../../Components/Svg/gameSvg";
import DeleteSvg from "../../Components/Svg/deleteSvg";
import {
	CURATOR_USER_TYPE,
	ERROR_KEY,
	GAMIFY_COURSE_ENTITY_TYPE_ID,
	LECTURER_USER_TYPE,
	NUMBER_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import uuid from "react-uuid";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import GamificationItemsComponent from "../Gamification/Components/Modals/GamificationItemsComponent";
import GamifyApiService from "../../Services/gamifyApiService";
import PosterImage from "./../../assets/images/video-poster.png";
import ReactPlayer from "react-player";
import StudentsSvg from "../../Components/Svg/studentsSvg";

const OrganizationCourseBlock = props => {
	const courseData = props.course;
	const studentId = props.match.params.studentId || null;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [selectedCourse, setSelectedCourse] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [gamifyData, setGamifyData] = useState(null);
	const [ongoingVideoId, setOngoingVideoId] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const courseCopy = courseData => {
		if (!courseData) {
			return false;
		}
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService
				.translate("TR_COURSE_COPY_CONFIRM_MSG")
				.replace("{0}", `"${courseData.name}"`)} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPageSpinner(spinnerId));
			ApiService.courseCopy(+courseData.id)
				.then(() => {
					dispatch(removePageSpinner(spinnerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_COURSE_COPIED_SUCCESSFULLY"),
					);
					props.getCourses && props.getCourses();
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const getGamifyDataByEntity = (courseData, entityType) => {
		if (!courseData || !entityType || isLoading) {
			return false;
		}
		setIsLoading(true);
		const data = {
			entityId: courseData.id.toString(),
			entityTypeId: entityType,
		};
		GamifyApiService.getGamifyDataByEntity(data)
			.then(response => {
				if (response && response.data) {
					setGamifyData(response.data);
					setSelectedCourse(courseData);
				}
				setIsLoading(false);
			})
			.catch(error => getFail(error));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePageSpinner(spinnerId));
		setIsLoading(false);
	};

	const cancel = () => {
		setSelectedCourse(null);
		setGamifyData(null);
		setIsLoading(false);
	};

	return translationService && courseData ? (
		<div className="course-wrapper--instructor d-flex flex-column justify-content-between">
			<GamificationItemsComponent
				entityData={selectedCourse}
				gamifyData={gamifyData}
				entityTypeId={GAMIFY_COURSE_ENTITY_TYPE_ID}
				cancel={cancel}
			/>
			<div className="course-image-wrapper--instructor">
				{props.isShowCommunications ? (
					<div
						className={`course-button-group pt-0 ${
							isLoading ? "pointer-events-none" : ""
						}`}
						style={{ top: 0, zIndex: 1 }}>
						<Auxiliary>
							<Link
								className="course-view-button"
								to={`/${language}/course/${courseData.id}`}
								title={translationService.translate("TR_VIEW")}>
								<ViewSvg />
							</Link>
							<Link
								className="course-edit-button"
								to={`/${language}/organization/coursedashboard/${courseData.id}`}
								title={translationService.translate("TR_EDIT")}>
								<EditSvg />
							</Link>
							{user && user.isGamifyAvailable ? (
								<Link
									className="course-gamify-button"
									to="#"
									title={translationService.translate("TR_GAMIFICATION_ICON")}
									onClick={() =>
										getGamifyDataByEntity(
											courseData,
											GAMIFY_COURSE_ENTITY_TYPE_ID,
										)
									}>
									{isLoading ? (
										<div
											className="spinner-border text-success"
											style={{
												width: "15px",
												height: "15px",
												borderWidth: "1px",
											}}
										/>
									) : (
										<GameSvg />
									)}
								</Link>
							) : null}

							<Link
								className="course-edit-button"
								to="#"
								title={translationService.translate("TR_COPY_COURSE")}
								onClick={() => courseCopy(courseData)}>
								<CopySvg />
							</Link>
							{user && user.userTypeId === LECTURER_USER_TYPE ? (
								<Link
									className="course-delet-button"
									to={`/${language}/course-students/${courseData.id}`}>
									<StudentsSvg />
								</Link>
							) : null}

							<Link
								className="course-delet-button"
								to="#"
								title={translationService.translate("TR_DELETE")}
								onClick={() => props.delete(courseData)}>
								<DeleteSvg />
							</Link>
						</Auxiliary>
					</div>
				) : null}

				<Auxiliary>
					{props.isShowCommunications ? (
						courseData.published ? (
							<span
								className="course-status--instructor course-live--instructor"
								style={{ left: "6px", right: "auto" }}>
								{translationService.translate("TR_LIVE")}
							</span>
						) : (
							<span
								className="course-status--instructor course-verified--instructor"
								style={{ left: "6px", right: "auto" }}>
								{translationService.translate("TR_IN_PROCESS")}
							</span>
						)
					) : null}
				</Auxiliary>

				{/* <div
					style={{
						backgroundImage: `url(${courseData.imagePath})`,
						height: "218px",
					}}
					className="course-image"></div> */}

				{courseData.presentationFilePath ? (
					<div className="course-media-wrapper cursor-pointer">
						<video
							className="m-0 cursor-pointer video-tag"
							controls
							style={{ height: "200px" }}
							poster={PosterImage}
							autoPlay={true}
							onPointerDown={() => setOngoingVideoId(courseData.id)}>
							{ongoingVideoId === courseData.id ? (
								<source
									src={courseData.presentationFilePath}
									type="video/mp4"
								/>
							) : null}
						</video>
					</div>
				) : courseData.videoLink && !courseData.imagePath ? (
					<div
						className="course-media-wrapper w-100"
						style={{ height: "200px" }}>
						<ReactPlayer
							className="course-video-link"
							url={courseData?.videoLink}
							config={{
								youtube: {
									playerVars: { showinfo: 0, controls: 1 },
								},
							}}
						/>
					</div>
				) : (
					<div
						className="course-image"
						style={{
							backgroundImage: `url(${courseData.imagePath})`,
						}}></div>
				)}
			</div>
			<div className="course-body-wrapper--instructor border-top">
				<div className="p-2">
					<h6 className="course-name--instructor" title={courseData.name}>
						{courseData.name}
					</h6>
				</div>
				<div className="">
					<div className="px-2">
						{/* <div className="content-sub-title">
            <h3 className="course-title">{translationService.translate("TR_USERS")}</h3>
          </div> */}
						{!props.isShowCommunications ? (
							courseData.completionPercentage ||
							typeof courseData.completionPercentage === NUMBER_KEY ? (
								<div className="mt-2 mb-3">
									<div className="d-flex justify-content-between">
										<small>
											<b>{"0%"}</b>
										</small>
										<small>
											<b>{"100%"}</b>
										</small>
									</div>
									<div
										className="line-loader line-loader-org-student-course"
										style={{ height: "7px" }}>
										<div
											className="line-loader-item"
											style={{
												width: `${
													(+courseData.completionPercentage / 100) * 100
												}%`,
											}}
										/>
									</div>
								</div>
							) : null
						) : null}
					</div>
				</div>
				{!props.isShowCommunications ? (
					<div>
						<div className="d-flex flex-wrap">
							{!courseData.isLifetimeAccess ? (
								<button
									className="mindalay--btn-small m-1 mindalay--btn-blue mindalay--btn outline-none w-100"
									onClick={() => props.openPauseModal(courseData)}>
									{courseData.isPaused
										? translationService.translate("TR_CANCEL_PAUSE")
										: translationService.translate("TR_PAUSE")}
								</button>
							) : null}
							<button
								className="mindalay--btn-small m-1 mindalay--btn-blue mindalay--btn outline-none w-100"
								onClick={() => props.openBlockModal(courseData)}>
								{courseData.isBlocked
									? translationService.translate("TR_UNBLOCK")
									: translationService.translate("TR_BLOCK")}
							</button>
							<Link
								to={`/${language}/student/${studentId}/quizzes/${courseData.id}`}
								className="mindalay--btn-small m-1 mindalay--btn-blue mindalay--btn text-center outline-none w-100">
								{translationService.translate("TR_GET_QUIZ_RESULTS")}
							</Link>
							<Link
								to={`/${language}/student/courses/${studentId}/${courseData.id}/assignment`}
								className="mindalay--btn-small m-1 mindalay--btn-blue mindalay--btn text-center outline-none w-100"
								onClick={() => {}}>
								{translationService.translate("TR_ASSIGNMENTS")}
							</Link>
						</div>
					</div>
				) : null}
			</div>
		</div>
	) : null;
};

export default withRouter(OrganizationCourseBlock);
