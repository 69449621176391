import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LANGUAGE_KEY } from "../Constants/mainKeys";
import NotFoundPage from "../Pages/404/notFoundPage";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import _Help from "../Pages/Help/_help";
import RolesService from "../Services/rolesService";
import { useSelector } from "react-redux";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import MainService from "../Services/mainService";
import Students from "../MetDepartmentPages/Students";
import OrganizationSettings from "../MetDepartmentPages/OrganizationSettings";
import Documents from "../MetDepartmentPages/Documents";
// import DocumentForm from "../OrganizationPages/Document/documentForm";
import DocumentForm from "../MetDepartmentPages/DocumentForm";

const MetDepartmentRouet = () => {
	const { role } = useSelector(state => state.user);
	const [roleService, setRoleService] = useState(null);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	return roleService ? (
		<Switch>
			<Route path="/:language/student" exact component={Students} />
			<Route
				path="/:language/organizationSettings"
				exact
				component={OrganizationSettings}
			/>
			<Route path="/:language/document" exact component={Documents} />
			<Route path="/:language/document-form" exact component={DocumentForm} />
			<Route
				path="/:language/document-form/:documentId"
				exact
				component={DocumentForm}
			/>
			{/* <Route path="/:language/student" /> */}
			<Route path="/:language/help" exact component={_Help} />
			<Route path="/:language/help/topic/:topicId" exact component={_Help} />
			<Route path="/:language/help/faq/:faqId" exact component={_Help} />
			{/* AuthenticationByRefreshToken */}
			<Route
				path="/:language/authentication/:refreshToken"
				exact
				component={AuthenticationByRefreshToken}
			/>
			{/* 404 */}
			<Route path="/:language/not-found/404" exact component={NotFoundPage} />
			{/* Server error page */}
			<Route
				path="/:language/under-maintenance"
				exact
				component={UnderMaintenancePage}
			/>
			<Redirect to={`/${localStorage.getItem(LANGUAGE_KEY)}/student`} />
			<Route component={NotFoundPage} />
		</Switch>
	) : null;
};

export default MetDepartmentRouet;
