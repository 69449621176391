import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import TranslationService from "../../Services/translationService";

import {
	addButtonSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import {
	ERROR_KEY,
	IMAGE_KEY,
	OBJECT_KEY,
	STRING_KEY,
	STUDENT_USER_TYPE,
	SUCCESS_KEY,
	VALID_FILE_TYPES_KEY,
	VALID_IMAGE_TYPES_KEY,
	WARNING_KEY,
} from "../../Constants/mainKeys";
import { Helmet } from "react-helmet";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import MainService from "../../Services/mainService";
import CloseSvg from "./../../Components/Svg/closeSvg";
import DownloadSvg from "./../../Components/Svg/downloadSvg";
import CopySvg from "./../../Components/Svg/copy";
import filePng from "../../assets/icons/file.png";
import ViewSvg from "./../../Components/Svg/viewSvg";
import RichTextEditor from "../Questions/Components/RichTextEditor";
import UploadButton from "../../Components/UploadButton/uploadButton";
import ProgressBar from "react-bootstrap/ProgressBar";
import ActionButton from "../../Components/ActionButton/actionButton";
import moment from "moment";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

const spinnerId = uuid();

const StudentAssignment = props => {
	const mainService = new MainService();
	const { user } = useSelector(state => state.user);
	const dispatch = useDispatch();
	const { assignmentId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [assignment, setAssignment] = useState(null);
	const [zoomImagePath, setZoomImagePath] = useState(null);
	const [isShowForm, setIsShowForm] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [fileReadPercent, setFileReadPercent] = useState(0);
	const [values, setValues] = useState({
		note: null,
		files: [],
	});
	const [disabled, setDisabled] = useState(false);
	useEffect(() => {
		if (user.userTypeId !== STUDENT_USER_TYPE) {
			window.history.back();
		}
	}, [user]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getStudentAssignment(assignmentId);
	}, [assignmentId]);

	useEffect(() => {
		setValues(values => ({
			...values,
			note: "",
		}));
	}, []);

	const isLastMassageStudent = useMemo(() => {
		if (
			assignment &&
			assignment.studentAssignmentResults &&
			assignment.studentAssignmentResults.length
		) {
			return assignment.studentAssignmentResults[
				assignment.studentAssignmentResults.length - 1
			].isResultFromStudent;
		} else {
			return false;
		}
	}, [assignment]);

	const getStudentAssignment = id => {
		if (!id) return;
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.getStudentAssignment(id)
			.then(response => {
				if (response && response.data) {
					setAssignment(response.data);
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePageSpinner(spinnerId));
			});
	};

	const zoomImage = path => {
		if (!path) {
			return false;
		}
		setZoomImagePath(path);
	};

	const bytesToSize = bytes => {
		var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes == 0) return "0";
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
	};

	const onEditorChange = (event, fieldName) => {
		const newRichText = event.editor.getData();
		setValues(values => ({
			...values,
			[fieldName]: newRichText,
		}));
	};

	const uploadFile = async event => {
		const fileEntities = [];
		if (event.target.files) {
			for (let i in event.target.files) {
				const file = event.target.files[i];
				if (file && typeof file === OBJECT_KEY) {
					const fileName = file.name;
					let allValidFileTypes =
						VALID_IMAGE_TYPES_KEY.concat(VALID_FILE_TYPES_KEY);
					await mainService
						.readFile(file, allValidFileTypes, setFileReadPercent)
						.then(uploadedFile => {
							fileEntities.push({
								fileName,
								file,
								uploadedFile,
							});
						})
						.catch(error => error && AlertService.alert(WARNING_KEY, error));
				}
			}
		}
		setValues(values => ({
			...values,
			files: [...values.files, ...fileEntities],
		}));
	};

	const removeFile = index => {
		let newFiles = values.files.filter((_, _index) => _index !== index);
		setValues(values => ({
			...values,
			files: newFiles,
		}));
	};

	const cancel = () => {
		setZoomImagePath(null);
	};
	const onSubmit = () => {
		if (disabled) return false;
		dispatch(addButtonSpinner(spinnerId));
		setDisabled(true);
		const formData = new FormData();
		Object.entries(values).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach(item => {
					formData.append(key, item.file);
				});
			} else {
				formData.append(key, value);
			}
		});
		formData.append("StudentAssignmentId", assignment.id);
		ApiService.createStudentAssignment(formData)
			.then(response => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				setValues({
					note: "",
					files: [],
				});
				getStudentAssignment(assignmentId);
				setIsShowForm(false);
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeButtonSpinner(spinnerId));
				setDisabled(false);
			});
	};

	return translationService ? (
		<section className="top-section">
			{zoomImagePath ? (
				<div className="zoom-image-container" onClick={cancel}>
					<div className="close-svg">
						<CloseSvg />
					</div>
					<img
						src={zoomImagePath}
						alt="/"
						onClick={event => event.stopPropagation()}
					/>
				</div>
			) : null}

			{assignment && assignment.assignmentResponseModel ? (
				<Auxiliary>
					{
						<Helmet>
							<title>{`${assignment.assignmentResponseModel?.title} | Mindalay`}</title>
						</Helmet>
					}
					<div className="container mt-3">
						<div className="row">
							<div className="col-lg-3">
								<div className="background box-shadow-4 mb-lg-0 mb-3">
									<div className="quiz-fildest-information ">
										<h2 className="section-title">
											{assignment.assignmentResponseModel.entityName}
										</h2>
										{assignment && assignment.isPassed ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_STATUS")}
												</legend>
												<p>
													<span className={`mr-1 text-success`}>
														{translationService.translate("TR_PASSED")}
													</span>
												</p>
											</fieldset>
										) : null}
										{assignment && assignment.isFailed ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_STATUS")}
												</legend>
												<p>
													<span className={`mr-1 text-danger`}>
														{translationService.translate("TR_FAILED")}
													</span>
												</p>
											</fieldset>
										) : null}
										{assignment && assignment.completionDate ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_DATE")}
												</legend>
												<b>
													<p>
														<span className={`mr-1`}>
															{moment(
																MainService.convertUTCDateToLocalDate(
																	new Date(
																		assignment.createDate
																			? assignment.createDate
																			: new Date(),
																	),
																),
															).format("ll (HH:mm)")}
														</span>
													</p>
												</b>
											</fieldset>
										) : null}
										{assignment && assignment.outcomeNote ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_FINALLY_MESSAGE")}{" "}
													{/* {translationService.translate("TR_DATE")} */}
												</legend>
												<div>
													<ParserComponent text={assignment.outcomeNote} />
												</div>
											</fieldset>
										) : null}
										{assignment && assignment.studentGrade ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_GRADE")}{" "}
													{/* {translationService.translate("TR_DATE")} */}
												</legend>
												<b>
													<p>
														<span className={`mr-1`}>
															{assignment.studentGrade}
														</span>
													</p>
												</b>
											</fieldset>
										) : null}
									</div>
								</div>
							</div>
							<div className="col-lg-9 col-12">
								<div className="row">
									<div className="col-12 ">
										<div className="background box-shadow-4 content-body">
											<div className="d-flex">
												{/* <h2 className="content-title word-break-break-word p-0 mb-2">
													<Link
														to="#"
														title={translationService.translate("TR_BACK")}
														onClick={() => {
															window.history.back();
														}}>
														<ArrowBackSvg />
													</Link>
													{assignment.assignmentResponseModel.title}
												</h2> */}
												<h2 className="section-title">
													{assignment.assignmentResponseModel.title}
												</h2>
											</div>
											{assignment.assignmentResponseModel.description ? (
												<div>
													<ParserComponent
														text={
															assignment.assignmentResponseModel.description
														}
													/>
												</div>
											) : null}

											{isShowForm ? (
												<div className="mt-3">
													<div className="scale-up-hor-right">
														<div className="topic-form-container">
															<div>
																<h5 className="p-0">
																	{translationService.translate(
																		"TR_ASSIGNMENT_FORM",
																	)}
																</h5>
																<div
																	className="close-block"
																	style={{ top: "10px" }}>
																	<div
																		className="close-svg"
																		onClick={() => setIsShowForm(false)}>
																		<CloseSvg />
																	</div>
																</div>
															</div>
															{typeof values.note === STRING_KEY ? (
																<div className="my-3">
																	<RichTextEditor
																		value={values.note}
																		config={{
																			toolbar: [
																				[
																					"Bold",
																					"Italic",
																					"Strike",
																					"-",
																					"RemoveFormat",
																				],
																				["Undo", "Redo"],
																				[
																					"NumberedList",
																					"BulletedList",
																					"-",
																					"Outdent",
																					"Indent",
																					"-",
																					"Blockquote",
																				],
																				["Styles", "Format"],
																				// ['Maximize']
																			],
																		}}
																		isInvalidSubmit={isInvalidSubmit}
																		onEditorChange={event =>
																			onEditorChange(event, "note")
																		}
																	/>
																</div>
															) : null}
															{fileReadPercent > 0 && fileReadPercent < 100 ? (
																<ProgressBar now={fileReadPercent} />
															) : null}
															<div className="row mt-3">
																{values.files && values.files.length
																	? values.files.map((item, index) => {
																			const lastDotIndex =
																				item.fileName.lastIndexOf(".");
																			const fileExtention =
																				lastDotIndex !== -1
																					? item.fileName
																							.substring(lastDotIndex + 1)
																							.toLowerCase()
																					: " ";
																			return (
																				<div
																					key={index}
																					className="col-lg-2 col-md-3 col-sm-4 col-6">
																					<div className="course-media-wrapper">
																						{VALID_IMAGE_TYPES_KEY.includes(
																							fileExtention,
																						) ? (
																							<div className="upload-file-content">
																								<div
																									onClick={() =>
																										removeFile(index)
																									}>
																									<CloseSvg />
																								</div>
																								<div
																									className="img"
																									style={{
																										backgroundImage: `url(${item.uploadedFile})`,
																									}}
																									alt="course-image"
																								/>
																							</div>
																						) : (
																							<div className="course-media-wrapper">
																								<div className="upload-file-content">
																									<div
																										className="img"
																										style={{
																											backgroundImage: `url(${filePng})`,
																										}}
																										alt="course-image"
																									/>
																								</div>
																							</div>
																						)}
																					</div>
																				</div>
																			);
																	  })
																	: null}
															</div>

															<div className="row mt-2">
																<div className="col-lg-4 col-md-6 col-12">
																	<div className="form-group position-relative mb-0">
																		<UploadButton
																			textInputClasses="pl-100"
																			isInvalidSubmit={isInvalidSubmit}
																			isMultiple={true}
																			accept={[
																				...VALID_IMAGE_TYPES_KEY,
																				...VALID_FILE_TYPES_KEY,
																			]}
																			text={`${translationService.translate(
																				"TR_UPLOAD_FILE",
																			)}`}
																			clicked={event =>
																				uploadFile(event, "mainLogo")
																			}
																		/>
																	</div>
																</div>
															</div>
															<div className="mt-4">
																<hr />
																<div className="d-flex justify-content-end">
																	<button
																		type="button"
																		className="ml-1 mindalay--btn-default mr-2"
																		onClick={() => setIsShowForm(false)}>
																		{translationService.translate("TR_CLOSE")}
																	</button>
																	<ActionButton
																		spinnerId={spinnerId}
																		type="button"
																		clicked={onSubmit}
																		disabled={!values.note || disabled}
																		className="mindalay--btn-dark px-5"
																		name={translationService.translate(
																			"TR_SEND",
																		)}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											) : null}

											{assignment.assignmentResponseModel.files &&
											assignment.assignmentResponseModel.files.length ? (
												<div className="mindayal-admin-table table-responsive mt-3">
													<table className="table">
														<thead>
															<tr>
																<th>
																	{translationService.translate("TR_FILE")}
																</th>
																<th>
																	{translationService.translate("TR_NAME")}
																</th>
																<th>
																	{translationService.translate("TR_SIZE")}
																</th>
																<th>
																	{translationService.translate("TR_ACTIONS")}
																</th>
															</tr>
														</thead>
														<tbody>
															{assignment.assignmentResponseModel.files.map(
																(file, index) => {
																	const isImage =
																		file.mimeType.includes(IMAGE_KEY);
																	return (
																		<tr key={index} className="cursor-default ">
																			<td>
																				{isImage ? (
																					<div
																						className="file-library-item cursor-pointer"
																						style={{
																							backgroundImage: `url(${file.filePath})`,
																						}}
																						onClick={() =>
																							zoomImage(file.filePath)
																						}
																					/>
																				) : (
																					<div className="file-item">
																						<a
																							href={file.filePath}
																							target="_blank">
																							<div
																								className="file-library-item cursor-pointer"
																								style={{
																									backgroundImage: `url(${filePng})`,
																								}}
																							/>
																						</a>
																					</div>
																				)}
																			</td>
																			<td
																				className="word-break-break-word"
																				title={file.name}>
																				{file.name}
																			</td>
																			<td>
																				<p className="no-wrap">
																					{bytesToSize(file.fileSize)}
																				</p>
																			</td>
																			<td>
																				<div className="table-action-btn-group d-flex align-items-center">
																					<Link
																						to="#"
																						title={translationService.translate(
																							"TR_VIEW",
																						)}
																						className="table-action-btn view-btn"
																						onClick={() => {
																							if (isImage) {
																								zoomImage(file.filePath);
																							} else {
																								window.open(
																									file.filePath,
																									"_blank",
																								);
																							}
																						}}>
																						<ViewSvg />
																					</Link>
																					<a
																						title={translationService.translate(
																							"TR_DOWNLOAD",
																						)}
																						className="table-action-btn add-btn "
																						href={`${MainService.getDownloadUrl(
																							file.filePath,
																						)}`}
																						target="blank">
																						<DownloadSvg />
																					</a>
																					<Link
																						to="#"
																						title={translationService.translate(
																							"TR_COPY_FILE",
																						)}
																						className="table-action-btn edit-btn"
																						onClick={event => {
																							event.preventDefault();
																							file.filePath &&
																								navigator.clipboard.writeText(
																									file.filePath,
																								);
																							file.filePath &&
																								AlertService.alert(
																									SUCCESS_KEY,
																									translationService.translate(
																										"TR_COPIED",
																									),
																								);
																						}}>
																						<CopySvg />
																					</Link>
																				</div>
																			</td>
																		</tr>
																	);
																},
															)}
														</tbody>
													</table>
												</div>
											) : null}

											{Array.isArray(assignment.studentAssignmentResults) &&
											assignment.assignmentResponseModel
												.requiresStudentResponse ? (
												<div className="border rounded p-2 mb-5">
													{assignment?.studentAssignmentResults?.map(
														(item, index) => {
															if (item.isResultFromStudent) {
																return (
																	<div
																		key={index}
																		className="d-flex justify-content-end  mt-2">
																		<div
																			className="border rounded w-100 p-3"
																			style={{ maxWidth: "90%" }}>
																			<div className="d-flex justify-content-between align-items-center">
																				<span>
																					{moment(
																						MainService.convertUTCDateToLocalDate(
																							new Date(item.createDate),
																						),
																					).format("ll (HH:mm)")}
																				</span>
																				<h5 className="mb-2">
																					{item.responderName}
																				</h5>
																			</div>
																			<hr />
																			{item.note && (
																				<div>
																					<ParserComponent text={item.note} />
																				</div>
																			)}
																			{item.files && item.files.length ? (
																				<div className="d-flex">
																					{item.files.map((file, idx) => {
																						if (
																							file.mimeType.includes(IMAGE_KEY)
																						) {
																							return (
																								<div
																									key={idx}
																									className="file-library-item cursor-pointer m-1 border rounded"
																									style={{
																										backgroundImage: `url(${file.filePath})`,
																									}}
																									onClick={() => {
																										zoomImage(file.filePath);
																									}}
																								/>
																							);
																						} else {
																							return (
																								<div
																									key={idx}
																									className="file-library-item cursor-pointer m-1 border rounded"
																									style={{
																										backgroundImage: `url(${filePng})`,
																									}}
																									onClick={() => {
																										window.open(
																											file.filePath,
																											"_blank",
																										);
																									}}
																								/>
																							);
																						}
																					})}
																				</div>
																			) : null}
																		</div>
																	</div>
																);
															} else if (!item.isResultFromStudent) {
																return (
																	<div key={index} className="d-flex mt-2">
																		<div
																			className="border rounded w-100 p-3"
																			style={{ maxWidth: "90%" }}>
																			<div className="d-flex justify-content-between align-items-center">
																				<h5 className="mb-2">
																					{item.responderName}
																				</h5>
																				<span>
																					{moment(
																						MainService.convertUTCDateToLocalDate(
																							new Date(item.createDate),
																						),
																					).format("ll (HH:mm)")}
																				</span>
																			</div>
																			<hr />
																			{item.note && (
																				<div>
																					<ParserComponent text={item.note} />
																				</div>
																			)}
																			{item.files && item.files.length ? (
																				<div className="d-flex">
																					{item.files.map((file, idx) => {
																						if (
																							file.mimeType.includes(IMAGE_KEY)
																						) {
																							return (
																								<div
																									key={idx}
																									className="file-library-item cursor-pointer m-1 border rounded"
																									style={{
																										backgroundImage: `url(${file.filePath})`,
																									}}
																									onClick={() => {
																										zoomImage(file.filePath);
																									}}
																								/>
																							);
																						} else {
																							return (
																								<div
																									key={idx}
																									className="file-library-item cursor-pointer m-1 border rounded"
																									style={{
																										backgroundImage: `url(${filePng})`,
																									}}
																									onClick={() => {
																										window.open(
																											file.filePath,
																											"_blank",
																										);
																									}}
																								/>
																							);
																						}
																					})}
																				</div>
																			) : null}
																		</div>
																	</div>
																);
															}
														},
													)}

													{!assignment.outcomeNote && !isShowForm && (
														<div className="d-flex justify-content-end my-2">
															<button
																type="button"
																className={`mindalay--btn-dark mt-2 mindalay--btn-small ${
																	assignment.isFailed ||
																	assignment.isPassed ||
																	assignment.outcomeNote
																		? "user-select-none disabled"
																		: ""
																}`}
																onClick={() => {
																	setIsShowForm(!isShowForm);
																	window.scrollTo({
																		top: 0,
																		behavior: "smooth",
																	});
																}}>
																{translationService.translate(
																	"TR_SUBMIT_ANSWER",
																)}
															</button>
														</div>
													)}
												</div>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Auxiliary>
			) : (
				<div className="no-data-container mt-5">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</section>
	) : null;
};

export default withRouter(StudentAssignment);
