import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function CustomTitle({ title, children, show = 1, hide = 1 }) {
	return (
		<OverlayTrigger
			placement="bottom"
			delay={{ show, hide }}
			overlay={<Tooltip id="button-tooltip">{title}</Tooltip>}>
			{children}
		</OverlayTrigger>
	);
}

export default CustomTitle;
