import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainService from "../../Services/mainService";
import { setSettings } from "../../Store/Actions/settings";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import uuid from "uuid";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";

function SettingsLayout(props) {
	const dispatch = useDispatch();
	const { settings } = useSelector(state => state.settings);
	const { user } = useSelector(state => state.user);
	const { language } = useSelector(state => state.language);

	const resetStyles = () => {
		document.documentElement.style.removeProperty("--mindalay--brand-color");
		document.documentElement.style.removeProperty(
			"--mindalay--brand-color-darken-35",
		);
		document.documentElement.style.removeProperty(
			"--mindalay--secondary-color",
		);
		document.documentElement.style.removeProperty(
			"--mindalay--secondary-color-lightened-50",
		);
		document.documentElement.style.removeProperty(
			"--mindalay--secondary-color-lightened-minus-5",
		);
		document.documentElement.style.removeProperty("--mindalay--header-color");
		document.documentElement.style.removeProperty("--mindalay--body-color");
		document.documentElement.style.removeProperty("--mindalay--footer-color");
	};

	const getSettings = () => {
		if (!user && !getSubdomain()) {
			dispatch(setSettings(null));
			return false;
		} else if (!getSubdomain() && !user) {
			const spinnerId = uuid();
			dispatch(addPageSpinner(spinnerId));
			ApiService.getSettingByShortName(getSubdomain(window.location.origin))
				.then(res => {
					if (res && res.data) {
						dispatch(setSettings(res.data));
					}
				})
				.catch(error => {
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
				})
				.finally(() => {
					setTimeout(() => {
						dispatch(removePageSpinner(spinnerId));
					}, 1000);
				});
		} else if (user && user.isOrganizationUser) {
			const spinnerId = uuid();
			dispatch(addPageSpinner(spinnerId));
			ApiService.getCurrentOrganizationSettings()
				.then(res => {
					if (res && res.data) {
						dispatch(setSettings(res.data));
					}
				})
				.catch(error => {
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
				})
				.finally(() => {
					setTimeout(() => {
						dispatch(removePageSpinner(spinnerId));
					}, 1000);
				});
		} else {
			resetStyles();
		}
	};

	useEffect(() => {
		getSettings();
	}, [language, user]);

	const getSubdomain = () => {
		// return "harut"
		let url = window.location.origin;
		if (!url.startsWith("http://") && !url.startsWith("https://")) {
			url = "http://" + url;
		}
		const parsedUrl = new URL(url);
		const hostname = parsedUrl.hostname;
		const parts = hostname.split(".");
		if (parts.length > 2) {
			return parts[0] === "www" ? parts[1] : parts[0];
		} else {
			return null;
		}
	};

	useEffect(() => {
		if (settings) {
			if (settings.mainColor) {
				document.documentElement.style.setProperty(
					"--mindalay--brand-color",
					settings.mainColor,
				);
				document.documentElement.style.setProperty(
					"--mindalay--brand-color-darken-35",
					MainService.darkenColor(settings.mainColor, 35),
				);
			}

			if (settings.secondaryColor) {
				document.documentElement.style.setProperty(
					"--mindalay--secondary-color",
					settings.secondaryColor,
				);
				document.documentElement.style.setProperty(
					"--mindalay--secondary-color-lightened-50",
					MainService.lightenColor(settings.secondaryColor, 50),
				);
				document.documentElement.style.setProperty(
					"--mindalay--secondary-color-lightened-minus-5",
					MainService.darkenColor(settings.secondaryColor, 5),
				);
			}

			if (settings.headerBackgroundColor) {
				document.documentElement.style.setProperty(
					"--mindalay--header-color",
					settings.headerBackgroundColor,
				);
			}

			if (settings.bodyBackgroundColor) {
				document.documentElement.style.setProperty(
					"--mindalay--body-color",
					settings.bodyBackgroundColor,
				);
			}

			if (settings.footerBackgroundColor) {
				document.documentElement.style.setProperty(
					"--mindalay--footer-color",
					settings.footerBackgroundColor,
				);
			}
		} else {
			resetStyles();
		}
	}, [settings]);

	return null;
}

export default SettingsLayout;
