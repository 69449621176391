import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "./../../Services/translationService";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import AlertService from "../../Services/alertService";
import {
	ERROR_KEY,
	PAYMENT_RECEIPT_TYPES,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import {
	removePartialViewSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
} from "../../Store/Actions/spinner";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import CloseSvg from "../../Components/Svg/closeSvg";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import Textarea from "./../../Components/Inputs/textArea";
import { addModalSpinner } from "./../../Store/Actions/spinner";
import ReactPaginate from "react-paginate";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import ContractSvg from "../../Components/Svg/contractSvg";
import EditSvg from "../../Components/Svg/editSvg";

const Organizations = () => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const [organizations, setOrganizations] = useState([]);
	const [declineMessage, setDeclineMessage] = useState("");
	const [receiptType, setReceiptType] = useState(0);
	const [organizationData, setOrganizationData] = useState(null);
	const { language } = useSelector(state => state.language);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getOrganizations();
	}, []);

	const getOrganizations = (receiptType, currentPage) => {
		const spinnerId = uuid();
		setOrganizations([]);
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getOrganizations()
			.then(response => {
				if (response && response.data) {
					setOrganizations(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	// const acceptPaymentReceipt = paymentReceipt => {
	// 	if (!paymentReceipt) {
	// 		return;
	// 	}
	// 	const spinnerId = uuid();
	// 	AlertService.alertConfirm(
	// 		`${translationService.translate("TR_ACCEPT_CONFIRM_MESSAGE")} "${
	// 			paymentReceipt.paymentPackageName
	// 		}" ?`,
	// 		"",
	// 		translationService.translate(TR_YES),
	// 		translationService.translate(TR_NO),
	// 	).then(() => {
	// 		dispatch(addPartialViewSpinner(spinnerId));
	// 		ApiService.acceptPaymentReceipt(paymentReceipt.id)
	// 			.then(() => {
	// 				AlertService.alert(
	// 					SUCCESS_KEY,
	// 					translationService.translate("TR_DATA_SAVED"),
	// 				);
	// 				dispatch(removePartialViewSpinner(spinnerId));
	// 				getOrganizations(receiptType, 1);
	// 			})
	// 			.catch(error => getFail(error, spinnerId));
	// 	});
	// };

	const cancel = () => {};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeModalSpinner(spinnerId));
	};

	const onClose = () => {
		setOrganizationData(null);
	};

	return translationService ? (
		<div className="container">
			{!!organizationData && (
				<ModalComponent
					cancel={onClose}
					cancelButtonTitle="TR_CLOSE"
					title={organizationData?.name}>
					{organizationData ? (
						<div className="row">
							<div className="col-12">
								{/* <div className="d-flex justify-content-between  border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ORGANIZATION_NAME",
									)}:`}</b>
									<span>{}</span>
								</div> */}
								<div className="d-flex justify-content-between pb-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ORGANIZATION_EMAIL",
									)}:`}</b>
									<span>{organizationData?.email}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_USER_NAME",
									)}:`}</b>
									<span>{organizationData?.organizationAdminName}</span>
								</div>
							</div>
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<img src={NoDataImage} alt="/" height={150} />
							</div>
						</div>
					)}
				</ModalComponent>
			)}
			<div className="row">
				<div className="col-12">
					<h2 className="content-title p-0">
						{translationService.translate("TR_ORGANIZATIONS")}
					</h2>
					<hr />
				</div>
			</div>
			{organizations && organizations.length ? (
				<div className="row">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table id="table" className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ADMINISTRATOR")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIVE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{organizations.map((item, index) => {
										return (
											<tr key={index} className="cursor-default">
												<td>
													<div>{item.name}</div>
												</td>
												<td>
													<div>{item.organizationAdminName}</div>
												</td>
												<td>
													{item.isActive ? (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-check transaction-successful fs-30"></i>
														</div>
													) : (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-times transaction-unsuccessful fs-30"></i>
														</div>
													)}
												</td>

												<td className="d-flex align-items-center">
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={`/${language}/mindalay-admin/organization-contract-form/${item.id}`}
																title={translationService.translate("TR_EDIT")}
																onClick={event => {
																	event.stopPropagation();
																}}
																className="table-action-btn edit-btn">
																<ContractSvg />
															</Link>
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						{/* {pagination ? (
							<div className="row mt-4">
								<div className="col-12">
									<div>
										<ReactPaginate
											nextLabel={translationService.translate("TR_NEXT")}
											onPageChange={handlePageClick}
											pageRangeDisplayed={3}
											marginPagesDisplayed={2}
											pageCount={pagination.TotalPages}
											previousLabel={translationService.translate(
												"TR_PREVIOUS",
											)}
											pageClassName="page-item"
											pageLinkClassName="page-link"
											previousClassName="page-item"
											previousLinkClassName="page-link"
											nextClassName="page-item"
											nextLinkClassName="page-link"
											breakLabel="..."
											breakClassName="page-item"
											breakLinkClassName="page-link"
											containerClassName="pagination"
											activeClassName="active"
											renderOnZeroPageCount={null}
											forcePage={activePageNumber}
										/>
									</div>
								</div>
							</div>
						) : null} */}
					</div>
				</div>
			) : (
				<div className="no-data-container">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(Organizations);
