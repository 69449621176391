import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Route, withRouter } from "react-router-dom";
import MindalaySvg from "../../../Components/Svg/midalaySvg";
import TranslationService from "../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import KeySvg from "../../../Components/Svg/keySvg";
import { ADMIN_USER_TYPE } from "../../../Constants/mainKeys";
import CoursesSubMenu from "./AdminSubMenus/coursesSubMenu";
import FeedBacksSubMenu from "./AdminSubMenus/feedBacksSubMenu";
import CategoriesSubMenu from "./AdminSubMenus/categoriesSubMenu";
import TicketSvg from "../../../Components/Svg/ticketSvg";
import FeedbackSvg from "../../../Components/Svg/feedbackSvg";
import CourseSvg from "../../../Components/Svg/courseSvg";
import TicketsSubMenu from "./AdminSubMenus/ticketsSubMenu";
import HintWordsSubMenu from "./AdminSubMenus/hintWordsSubMenu";
import Categorysvg from "../../../Components/Svg/categorySvg";
import LogSubMenu from "./AdminSubMenus/logSubMenu";
import LogSvg from "../../../Components/Svg/logSvg";
import PaymentPackageSubMneu from "./AdminSubMenus/paymentPackageSubMneu";

import PaymentPackageSvg from "../../../Components/Svg/paymentPackageSvg";
import OrganizationsSvg from "../../../Components/Svg/organizationsSvg";
import OrganizationsSubMenu from "./AdminSubMenus/organizationsSubMenu";

const AdminLeftMenu = props => {
	const { organizationsId } = props.match.params;
	const dispatch = useDispatch();
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const translations = useSelector(state => state.translation.translations);
	const showOrHideGeneralLeftMenu = useSelector(
		state => state.generalLeftMenu.showOrHideGeneralLeftMenu,
	);
	const { settings } = useSelector(state => state.settings);

	const [translationService, setTranslationService] = useState(null);
	const [hover, setHover] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const toggleHover = isShow => {
		setHover(isShow);
	};

	const showOrHideLeftMenu = bool => {
		!bool && toggleHover(false);
		dispatch(showOrHideGeneralDashboardLeftMenu(bool));
	};

	const adminUserType = user.userTypeId === ADMIN_USER_TYPE ? true : false;

	return translationService ? (
		<section
			className={`side-menu-container ${
				showOrHideGeneralLeftMenu ? "show-general-left-menu" : ""
			}`}>
			<div
				className={showOrHideGeneralLeftMenu ? "layer" : ""}
				onClick={() => showOrHideLeftMenu(false)}
			/>
			<aside
				className={`side-left instructor-header-zindex ${
					hover ? "w-fix-left-menu" : ""
				}`}
				onMouseEnter={() => toggleHover(true)}
				onMouseLeave={() => toggleHover(false)}>
				<div className="side-left-container">
					<div className="side-left-inner-wrapper">
						<ul className="side-left-list-wrapper">
							<li
								title={translationService.translate("TR_HOME")}
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								} ${
									props.location.pathname.includes("home")
										? "active-side-left-menu-item"
										: ""
								}`}>
								<Link
									to={`/${language}`}
									className="p-0 d-flex justify-content-between"
									onClick={() => showOrHideLeftMenu(false)}>
									{settings && settings.mainLogoPath ? (
										<img
											src={settings.mainLogoPath}
											alt="/"
											style={{
												width: "120px",
												maxWidth: "150px",
												maxHeight: "150px",
												display: "block",
												margin: "0 auto",
											}}
										/>
									) : (
										<MindalaySvg />
									)}
								</Link>
							</li>
							{/* <li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_COURSES")}>
								<NavLink
									to={`/${language}/mindalay-admin/courses/verified`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes(`courses/verified`) ||
										props.location.pathname.includes(`courses/unverified`) ||
										props.location.pathname.includes(`courses/discount`) ||
										props.location.pathname.includes(`/course/`)
											? "active-side-left-menu-item"
											: ""
									}>
									<CourseSvg />
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li> */}
							{/* <li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_FEEDBACKS")}>
								<NavLink
									to={`/${language}/mindalay-admin/feedback/courses`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes("feedback")
											? "active-side-left-menu-item"
											: ""
									}>
									<FeedbackSvg />
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li> */}
							{/* <li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_TICKETS")}>
								<NavLink
									to={`/${language}/mindalay-admin/ticket/in-process`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes("ticket")
											? "active-side-left-menu-item"
											: ""
									}>
									<TicketSvg />
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li> */}
							{/* <li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_KEY_WORDS")}>
								<NavLink
									to={`/${language}/mindalay-admin/key-word/speciality`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes("key-word")
											? "active-side-left-menu-item"
											: ""
									}>
									<KeySvg />
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li> */}
							{/* <li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_CATEGORIES")}>
								<NavLink
									to={`/${language}/mindalay-admin/categories/category`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes("categories")
											? "active-side-left-menu-item"
											: ""
									}>
									<Categorysvg />
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li> */}
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_PAYMENT_RECEIPT")}>
								<NavLink
									to={`/${language}/mindalay-admin/payment-package/payment-receipt`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes("payment-package")
											? "active-side-left-menu-item"
											: ""
									}>
									<PaymentPackageSvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_PAYMENT_PACKAGE")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_ORGANIZATIONS")}>
								<NavLink
									to={`/${language}/mindalay-admin/organizations`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes("organizations")
											? "active-side-left-menu-item"
											: ""
									}>
									<OrganizationsSvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_PAYMENT_PACKAGE")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_LOGS")}>
								<NavLink
									to={`/${language}/mindalay-admin/logs`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes("logs")
											? "active-side-left-menu-item"
											: ""
									}>
									<LogSvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_LOGS")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</aside>
			<Route
				path={`/${language}/mindalay-admin/courses`}
				component={CoursesSubMenu}
			/>
			<Route
				path={`/${language}/mindalay-admin/feedback`}
				component={FeedBacksSubMenu}
			/>
			<Route
				path={`/${language}/mindalay-admin/ticket/in-process`}
				component={TicketsSubMenu}
			/>
			<Route
				path={`/${language}/mindalay-admin/mindalay-admin/resolved`}
				component={TicketsSubMenu}
			/>
			<Route
				path={`/${language}/mindalay-admin/key-word`}
				component={HintWordsSubMenu}
			/>
			<Route
				path={`/${language}/mindalay-admin/categories`}
				component={CategoriesSubMenu}
			/>
			<Route path={`/${language}/mindalay-admin/logs`} component={LogSubMenu} />
			<Route
				path={`/${language}/mindalay-admin/payment-logs`}
				component={LogSubMenu}
			/>
			<Route
				path={`/${language}/mindalay-admin/balance-logs`}
				component={LogSubMenu}
			/>
			{/* <Route path={`/${language}/${MINDALAY_ADMIN}/${PAYMENT_PACKAGE_KEY}/${PAYMENT_PACKAGES_KEY}`} component={PaymentPackageSubMneu} /> */}
			<Route
				path={`/${language}/mindalay-admin/payment-package/payment-receipt`}
				component={PaymentPackageSubMneu}
			/>
			<Route
				path={`/${language}/mindalay-admin/payment-package/payment-history`}
				component={PaymentPackageSubMneu}
			/>
			<Route
				path={`/${language}/mindalay-admin/organizations`}
				component={OrganizationsSubMenu}
			/>

			<Route
				path={`/${language}/mindalay-admin/organization-contract-form/${organizationsId}`}
				component={OrganizationsSubMenu}
			/>
			{props.location.pathname.includes(
				"mindalay-admin/organization-contract-form",
			) ? (
				<OrganizationsSubMenu />
			) : null}
			<Route path={`/${language}/not-found/404`} component={LogSubMenu} />
		</section>
	) : null;
};

export default withRouter(AdminLeftMenu);
