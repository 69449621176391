import React, { Component } from "react";
import { connect } from "react-redux";
import TranslationService from "../../Services/translationService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import moment from "moment";
import MainService from "../../Services/mainService";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import AlertService from "../../Services/alertService";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import {
	CONSULTANT_CONSULTATION_CONTRACTS_KEY,
	COURSES_KEY,
	CONSULTANT_KEY,
	STUDENT_CONSULTATION_CONTRACTS_KEY,
} from "../../Constants/urlKeys";
import {
	ERROR_KEY,
	INVALID_DATE_KEY,
	PAYMENT_SISTEM_CANCELED_STATUSES,
	PAYMENT_SISTEM_PENDING_STATUSES,
	PAYMENT_SISTEM_SUCCESS_STATUSES,
	PAYMENT_TYPES_ORG_ADMIN,
	STUDENT_USER_TYPE,
} from "../../Constants/mainKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ReactPaginate from "react-paginate";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

class Overview extends Component {
	paymentTypes = PAYMENT_TYPES_ORG_ADMIN;

	state = {
		translationService: null,
		paymentHistory: [],
		currentPaymentHistory: [],
		defaultValue: { label: "", value: "" },
		paymentType: 8,
		entityType: -1,
		totalBalance: null,
		freezedAmount: null,
		defaultPaymentHistory: [],
		pagination: null,
		activePageNumber: 0,
		pageSize: 10,
	};

	componentDidMount() {
		this.setTranslations();
		this.getPaymentHistory(1);
		// this.handleQueryParams();
	}

	componentDidUpdate(prevProps) {
		this.setTranslations();
		if (this.props.location.search !== prevProps.location.search) {
			// this.handleQueryParams();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
			JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	// handleQueryParams = () => {
	// 	const queryParams = new URLSearchParams(this.props.location.search);
	// 	const payId = queryParams.get("payId");
	// 	const status = queryParams.get("status");

	// 	if (status === "false" && payId) {
	// 		const spinnerId = uuid();
	// 		this.props.addPartialViewSpinner(spinnerId);

	// 		ApiService.newPayCancel(payId)
	// 			.then(response => {
	// 				// Handle response if necessary
	// 			})
	// 			.catch(error => {
	// 				if (error) {
	// 					AlertService.alert(
	// 						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
	// 						error,
	// 					);
	// 				}
	// 			})
	// 			.finally(() => {
	// 				this.props.removePartialViewSpinner(spinnerId);
	// 			});
	// 	}
	// };

	handlePageClick = event => {
		this.getPaymentHistory(event.selected + 1);
		this.setState({ activePageNumber: event.selected });
	};

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	filterPaymentHistory = selectedCategory => {
		var currentPaymentHistory = [...this.state.paymentHistory];
		let filteredPaymentHistory;
		filteredPaymentHistory = currentPaymentHistory.filter(
			payment => payment.type === selectedCategory.value,
		);
		this.setState({
			entityType: -1,
			defaultPaymentHistory: filteredPaymentHistory,
			paymentType: selectedCategory.value,
			currentPaymentHistory: filteredPaymentHistory,
		});
	};

	// getPaymentHistory = currentPage => {
	// 	const spinnerId = uuid();
	// 	const { paymentType, pageSize } = this.state;
	// 	this.props.addPartialViewSpinner(spinnerId);
	// 	ApiService.newPayHistory({}, currentPage, pageSize)
	// 	.then(response => {
	// 			if (response && response.data) {
	// 				const data = { ...response.data };
	// 				data.historyResponseModels &&
	// 					data.historyResponseModels.forEach(element => {
	// 						if (element.createDate) {
	// 							element.createDate = moment(
	// 								MainService.convertUTCDateToLocalDate(
	// 									new Date(element.createDate),
	// 								),
	// 							).format("ll (HH:mm)");
	// 						}
	// 						if (
	// 							element.freezInfo &&
	// 							moment(
	// 								MainService.convertUTCDateToLocalDate(
	// 									new Date(element.freezInfo),
	// 								),
	// 							).format("ll (HH:mm)") !== INVALID_DATE_KEY
	// 						) {
	// 							element.freezInfo = moment(
	// 								MainService.convertUTCDateToLocalDate(
	// 									new Date(element.freezInfo),
	// 								),
	// 							).format("ll (HH:mm)");
	// 						}
	// 					});
	// 				let filteredPaymentHistory = [];
	// 				var currentPaymentHistory = data.historyResponseModels
	// 					? data.historyResponseModels
	// 					: [];
	// 				if (currentPaymentHistory) {
	// 					filteredPaymentHistory = currentPaymentHistory
	// 						? currentPaymentHistory.filter(
	// 								payment => payment.type === paymentType,
	// 						  )
	// 						: [];
	// 				}
	// 				this.setState({
	// 					paymentHistory: response.data?.historyResponseModels
	// 						? [...response.data?.historyResponseModels.reverse()]
	// 						: [],
	// 					currentPaymentHistory: filteredPaymentHistory
	// 						? [...filteredPaymentHistory.reverse()]
	// 						: [],
	// 					totalBalance: response.data.balance?.balance1?.toFixed(2) || null,
	// 					freezedAmount:
	// 						response.data.balance?.freezedAmount?.toFixed(2) || null,
	// 					pagination: response.pagination
	// 						? JSON.parse(response.pagination)
	// 						: null,
	// 				});
	// 			}
	// 			this.props.removePartialViewSpinner(spinnerId);
	// 		})
	// 		.catch(error => this.getFail(error, spinnerId));
	// };


	getPaymentHistory(currentPage) {
		const spinnerId = uuid();
		this.props.addPartialViewSpinner(spinnerId);
		ApiService.newPayHistory({}, currentPage, this.state.pageSize)
			.then(response => {
				if (response && response.pagination)
					this.setState({
						pagination: JSON.parse(response.pagination)
					})
				if (response && response.data) {
					this.setState({
						currentPaymentHistory: response.data
					})
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				this.props.removePartialViewSpinner(spinnerId)
			});
	};


	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
	};

	redirectToCourseView = courseId => {
		this.props.history.push(
			`/${this.props.language}/${COURSES_KEY}/${courseId}`,
		);
	};

	redirectToContractPage = contractId => {
		const { paymentType } = this.state;
		if (paymentType === 2 && contractId) {
			//Purchase Student
			this.props.history.push(
				`/${this.props.language}/${STUDENT_CONSULTATION_CONTRACTS_KEY}/${contractId}`,
			);
		}
		if (paymentType === 3 && contractId)
			//Sale Consultant
			this.props.history.push(
				`/${this.props.language}/${CONSULTANT_KEY}/${CONSULTANT_CONSULTATION_CONTRACTS_KEY}/${contractId}`,
			);
	};

	render() {
		const {
			translationService,
			currentPaymentHistory,
			paymentType,
			pagination,
			activePageNumber,
		} = this.state;
		const { user } = this.props;

		return translationService ? (
			<div className="container mt-4">
				<div
					className={`${user.userTypeId === STUDENT_USER_TYPE ? "m-section" : ""
						}`}>
					<div className="row">
						<div className="col-12">
							<div className="row align-items-end">
								<div className="col-12">
									<h2 className="section-title">
										{translationService.translate("TR_OVERVIEW")}
									</h2>
									<PageInfoBlock
										pageTitle={translationService.translate("TR_OVERVIEW")}
										pageName="admin_payment_history"
										translationService={translationService}
									/>
								</div>
							</div>
							<hr />
						</div>
						{/* <div className="col-lg-4 col-sm-6 col-12">
							<div className="form-group m-0">
								<label htmlFor="#">
									{translationService.translate("TR_TRANSACTION_TYPES")}
								</label>
								<ReactSelectOption
									placeholder={" "}
									value={paymentType}
									selectedValue={(() => {
										const selectedValue = {
											...this.paymentTypes.find(
												data => data.value === paymentType,
											),
										};
										if (selectedValue) {
											selectedValue.label = translationService.translate(
												selectedValue.label,
											);
										}
										return selectedValue;
									})()}
									items={this.paymentTypes.map(data => ({
										label: translationService.translate(data.label),
										value: data.value,
									}))}
									onChange={item => this.filterPaymentHistory(item)}
								/>
							</div>
						</div> */}
						<div className="col-12">
							{currentPaymentHistory && currentPaymentHistory.length ? (
								<div className="row">
									<div className="col-12">
										<div className="mindayal-admin-table mt-3 table-responsive">
											<table className="table">
												<thead>
													<tr>
														<th className="text-nowrap">
															{translationService.translate("TR_PRICE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate(
																"TR_TRANSACTION_DATE",
															)}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_PROVIDER")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_ENTITY_NAME")}
														</th>

														<th className="text-nowrap">
															{translationService.translate("TR_STATUS")}
														</th>
													</tr>
												</thead>
												<tbody>
													{currentPaymentHistory.map((item, index) => {
														return (
															<tr key={index} className="cursor-default">
																<td>{item.amountWithCurrency}</td>
																<td>
																	{moment(
																		MainService.convertUTCDateToLocalDate(
																			new Date(item.createDate),
																		),
																	).format("ll (HH:mm)")}
																</td>
																<td>{item?.paymentSystemName}</td>
																<td>{item?.entityName}</td>

																<td>
																	<div>
																		<span
																			title={item.statusName}
																			className={`payment-status ${PAYMENT_SISTEM_PENDING_STATUSES.includes(
																				item.paymentStatus,
																			)
																				? "orange-border orange-color"
																				: PAYMENT_SISTEM_SUCCESS_STATUSES.includes(
																					item.paymentStatus,
																				)
																					? "green-border green-color"
																					: PAYMENT_SISTEM_CANCELED_STATUSES.includes(
																						item.paymentStatus,
																					)
																						? "red-border red-color"
																						: ""
																				}`}>
																			{translationService.translate(
																				item.statusName,
																			)
																				? translationService.translate(
																					item.statusName,
																				)
																				: item.statusName}
																		</span>
																	</div>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							) : (
								<Auxiliary>
									<div className="no-data-container">
										<div className="no-data-wrapper">
											<p>
												{translationService.translate(
													"TR_THERE_IS_NO_TRANSACTIONS_OF_THIS_TYPE",
												)}
											</p>
											<img src={NoDataImage} alt="/" />
										</div>
									</div>
								</Auxiliary>
							)}
						</div>
						{pagination ? (
							<div className="col-12 mt-4">
								<div className="d-flex justify-content-end w-100">
									<ReactPaginate
										nextLabel={translationService.translate("TR_NEXT")}
										onPageChange={this.handlePageClick}
										pageRangeDisplayed={3}
										marginPagesDisplayed={2}
										pageCount={pagination.TotalPages}
										previousLabel={translationService.translate("TR_PREVIOUS")}
										pageClassName="page-item"
										pageLinkClassName="page-link"
										previousClassName="page-item"
										previousLinkClassName="page-link"
										nextClassName="page-item"
										nextLinkClassName="page-link"
										breakLabel="..."
										breakClassName="page-item"
										breakLinkClassName="page-link"
										containerClassName="pagination"
										activeClassName="active"
										renderOnZeroPageCount={null}
										forcePage={activePageNumber}
									/>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	user: state.user.user,
	translations: state.translation.translations,
	language: state.language.language,
});

const mapDispatchToProps = {
	addPartialViewSpinner,
	removePartialViewSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(Overview);
