import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import TranslationService from "../../Services/translationService";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import uuid from "react-uuid";
import { BOOLEAN_KEY, ERROR_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import ArrowBackSvg from "./../../Components/Svg/arrowBackSvg";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import InputRadio from "../../Components/Inputs/inputRadio";
import Input from "../../Components/Inputs/input";
import MainService from "../../Services/mainService";
import ActionButton from "../../Components/ActionButton/actionButton";

const buttonSpinnerId = uuid();
function OrganizationContarctForm(props) {
	const { organizationsId } = props.match.params;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [data, setData] = useState(null);
	const [values, setValues] = useState({
		accountNumber: "",
		address: "",
		bankName: "",
		currencyId: null,
		endDate: "",
		feeByFixPrice: "",
		feeByPercent: "",
		organizationId: "",
		receiverName: "",
		routingNumber: "",
		swiftCode: "",
	});
	const [currencys, setCurrencys] = useState([]);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState();
	const [isPercent, setIsPercent] = useState(true);
	const [isInvalidDate, setIsInvalidDate] = useState(false);
	const [isDisabledDate, setIsDisabledDate] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getCurrency();
	}, []);

	useEffect(() => {
		if (organizationsId) {
			getContractOrganizationInnerPayment(organizationsId);
		}
	}, [organizationsId]);

	const getContractOrganizationInnerPayment = organizationId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getContractOrganizationInnerPayment(organizationId)
			.then(response => {
				if (response.data && response.data) {
					let data = { ...values };
					Object.entries(response.data).forEach(([key, value]) => {
						if (value) {
							data[key] = value;
						}
					});
					setData(response.data);
					if (response.data.feeByPercent) {
						setIsPercent(true);
					} else {
						setIsPercent(false);
					}
					setValues(data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getCurrency = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getCurrency()
			.then(response => {
				if (response.data && response.data.length) {
					setCurrencys(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (!values.currencyId) {
			setIsInvalidSubmit(true);
			scrollToInvalidFieldPosition();
			return false;
		}
		if (isPercent && values.feeByPercent === 0) {
			scrollToInvalidFieldPosition();
			setIsInvalidSubmit(true);
			return false;
		}
		if (
			(!isPercent && values.feeByFixPrice === 0) ||
			(!isPercent && !values.endDate.trim().length)
		) {
			scrollToInvalidFieldPosition();
			setIsInvalidSubmit(true);
			return false;
		}
		const data = {};
		Object.entries(values).forEach(([key, value]) => {
			if (value) {
				data[key] = value;
			}
		});
		data.organizationId = organizationsId;

		dispatch(addButtonSpinner(buttonSpinnerId));

		(!values?.id
			? ApiService.createOrganizationInnerPaymentContract(data)
			: ApiService.updateOrganizationInnerPaymentContract(data)
		)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				props.history.push(`/${language}/mindalay-admin/organizations`);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	const onSelectOptionChange = (item, key) => {
		if (!item) return null;
		setIsInvalidSubmit(false);
		setValues(values => ({ ...values, [key]: item.value }));
	};

	const onRadioChange = event => {
		if (
			typeof JSON.parse(event.target.value) === BOOLEAN_KEY &&
			JSON.parse(event.target.value) !== isPercent
		) {
			setIsInvalidSubmit(false);
			setIsPercent(JSON.parse(event.target.value));
			setIsDisabledDate(!JSON.parse(event.target.value));
			setValues(values => ({
				...values,
				feeByFixPrice: "",
				feeByPercent: "",
				endDate: "",
			}));
		}
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onNumberChange = (event, fieldname, maxValue = Infinity) => {
		if (
			event.target.value.toString().includes("e") ||
			event.target.value.includes(" ") ||
			event.target.value < 0
		) {
			return false;
		}

		const fieldMaxValue = fieldname === "feeByPercent" ? 100 : maxValue;

		// Проверяем максимальное значение
		if (fieldMaxValue && event.target.value > fieldMaxValue) {
			return false;
		}

		if (maxValue && maxValue < event.target.value.length) {
			return false;
		}
		setIsInvalidSubmit(false);
		setValues(values => ({
			...values,
			[fieldname]: event.target.value ? +event.target.value : "",
		}));
	};

	const onChangeDate = (event, fieldName) => {
		const isInvalidDateFormat = MainService.isValidDateTime(event.target.value);
		const isInvalidDate = MainService.validateOnlyDate(event.target.value);
		if (!isInvalidDateFormat || !isInvalidDate) {
			setIsInvalidDate(true);
		} else {
			setIsInvalidDate(false);
			setValues(values => ({ ...values, endDate: event.target.value }));
		}
		// cb(event.target.value);
	};

	const scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 200);
	};

	return translationService ? (
		<div className="container">
			<div className="row">
				<div className="col-12 content-title">
					<div className="d-flex align-items-center">
						<Link
							to={`/${language}/mindalay-admin/organizations`}
							title={translationService.translate("TR_BACK")}>
							<ArrowBackSvg />
						</Link>
						<h2 className="title">
							{!values.id
								? translationService.translate("TR_CREATE_CONTRACT")
								: translationService.translate("TR_UPDATE_CONTRACT")}
						</h2>
					</div>
				</div>
			</div>
			<hr />
			<form onSubmit={onSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-6 col-12 my-1">
								<div className="form-group">
									<label>{translationService.translate("TR_CURRENCY")}*</label>
									<ReactSelectOption
										defaultValue={values.currencyId}
										value={values.currencyId}
										isDisabled={values?.id}
										isInvalidSubmit={isInvalidSubmit}
										selectedValue={(() => {
											const selectedItem = {
												...currencys.find(
													data => data.id === values.currencyId,
												),
											};
											if (Object.keys(selectedItem).length) {
												selectedItem.label = selectedItem.isocode;
												selectedItem.value = selectedItem.id;
												return selectedItem;
											} else {
												return {
													value: null,
													label: translationService.translate("TR_CHOOSE"),
												};
											}
										})()}
										items={currencys
											.filter(item => item.id !== values.currencyId)
											.map(data => ({
												...data,
												label: data.isocode,
												value: data.id,
											}))}
										onChange={item => onSelectOptionChange(item, "currencyId")}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-6 mb-2">
						<InputRadio
							blockClassName="custom-radio custom-control"
							type="radio"
							id="feeByPercent"
							name="isPercent_yes"
							value="true"
							checked={isPercent}
							radioClassName="mr-1 custom-control-input"
							onChange={event => onRadioChange(event)}
							labelClassName={`custom-control-label mr-4 cursor-pointer`}
							labelValue={translationService.translate("TR_FEE_BY_PERCENT")}
						/>
					</div>
					<div className="col-md-6 col-lg-6 mb-2">
						<InputRadio
							blockClassName="custom-radio custom-control"
							type="radio"
							id="isPercent_no"
							name="correctAnswer"
							value="false"
							checked={!isPercent}
							radioClassName="mr-1 custom-control-input"
							onChange={event => onRadioChange(event)}
							labelClassName={`custom-control-label mr-4 cursor-pointer`}
							labelValue={translationService.translate("TR_FEE_BY_FIX_PRICE")}
						/>
					</div>
					<div className="col-12">
						<div className="row">
							<div className="col-md-12 col-lg-6">
								{isPercent ? (
									<Input
										type="number"
										id="feeByPercent"
										name="decimalCount"
										value={values.feeByPercent}
										max={100}
										isInvalidSubmit={
											isInvalidSubmit && isPercent && !values.feeByPercent
										}
										labelValue={`${translationService.translate(
											"TR_FEE_BY_PERCENT",
										)}*`}
										onChange={event => onNumberChange(event, "feeByPercent")}
									/>
								) : (
									<Input
										type="number"
										id="feeByFixPrice"
										name="feeByFixPrice"
										value={values.feeByFixPrice}
										isInvalidSubmit={
											isInvalidSubmit && !isPercent && !values.feeByFixPrice
										}
										labelValue={`${translationService.translate(
											"TR_FEE_BY_FIX_PRICE",
										)}*`}
										onChange={event => onNumberChange(event, "feeByFixPrice")}
									/>
								)}
							</div>
							{isDisabledDate ? (
								<div className="col-md-12 col-lg-6">
									<Input
										type="date"
										id="endDate"
										name="endDate"
										min={`${new Date().toISOString().split("T")[0]}`}
										blockClassName="mx-1"
										inputClassName={`mr-2 ${
											isInvalidDate ? "is-invalid error-bordered" : ""
										}`}
										isInvalidSubmit={
											isInvalidSubmit &&
											!isPercent &&
											!values.endDate.trim().length
										}
										labelValue={`${translationService.translate(
											"TR_END_DATE",
										)} *`}
										value={values.endDate}
										onChange={event => onChangeDate(event, "")}
									/>
								</div>
							) : null}
						</div>
					</div>

					<div className="col-md-6 col-12">
						<Input
							type="text"
							id="routingNumber"
							name="routingNumber"
							value={values.routingNumber}
							labelValue={`${translationService.translate(
								"TR_ROUTING_NUMBER",
							)}`}
							onChange={event => onChange(event, "routingNumber")}
						/>
					</div>
					<div className="col-md-6 col-12">
						<Input
							type="text"
							id="accountNumber"
							name="accountNumber"
							value={values.accountNumber}
							labelValue={`${translationService.translate(
								"TR_ACCOUNT_NUMBER",
							)}`}
							onChange={event => onChange(event, "accountNumber")}
						/>
					</div>
					<div className="col-md-6 col-12">
						<Input
							type="text"
							id="bankName"
							name="bankName"
							value={values.bankName}
							labelValue={`${translationService.translate("TR_BANK_NAME")}`}
							onChange={event => onChange(event, "bankName")}
						/>
					</div>
					<div className="col-md-6 col-12">
						<Input
							type="text"
							id="swiftCode"
							name="swiftCode"
							value={values.swiftCode}
							labelValue={`${translationService.translate("TR_SWIFT_CODE")}`}
							onChange={event => onChange(event, "swiftCode")}
						/>
					</div>
					<div className="col-md-6 col-12">
						<Input
							type="text"
							id="address"
							name="address"
							value={values.address}
							labelValue={`${translationService.translate("TR_ADDRESS")}`}
							onChange={event => onChange(event, "address")}
						/>
					</div>
					<div className="col-md-6 col-12">
						<Input
							type="text"
							id="receiverName"
							name="receiverName"
							value={values.receiverName}
							labelValue={`${translationService.translate("TR_RECEIVER_NAME")}`}
							onChange={event => onChange(event, "receiverName")}
						/>
					</div>
					<div className="col-12">
						<div className="mt-3">
							<ActionButton
								spinnerId={buttonSpinnerId}
								type="submit"
								className="mindalay--btn-dark position-relative"
								name={translationService.translate(
									!values.id ? "TR_CREATE" : "TR_UPDATE",
								)}
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	) : null;
}

export default withRouter(OrganizationContarctForm);
