import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import Input from "../../../Components/Inputs/input";
import {
	CONSULTANT_USER_TYPE,
	ERROR_KEY,
	FILL_BALANCE_KEY,
	NUMBER_KEY,
	STUDENT_USER_TYPE,
	WEBINAR_DATA_KEY,
} from "../../../Constants/mainKeys";
import AlertService from "../../../Services/alertService";
import MainService from "../../../Services/mainService";
import TranslationService from "../../../Services/translationService";
import {
	addPageSpinner,
	removePageSpinner,
	addModalSpinner,
	removeModalSpinner,
} from "../../../Store/Actions/spinner";
import * as moment from "moment";
import ApiService from "../../../Services/apiService";
import uuid from "react-uuid";
import {
	CONSULTANT_KEY,
	FINANCES_KEY,
	INSTRUCTOR_KEY,
	STUDENT_KEY,
} from "../../../Constants/urlKeys";
import { Link } from "react-router-dom";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import InfoSvg from "../../../Components/Svg/infoSvg";

class ContractInfo extends Component {
	state = {
		dateTime: "",
		translationService: null,
		failedFields: null,
		changeDateTime: false,
		isInvalidDate: false,
		showConfirmModal: false,
	};

	componentDidMount() {
		this.setTranslations();
	}

	componentDidUpdate(prevProps, prevState) {
		this.setTranslations();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	onDateChange = event => {
		const failedFields = this.removeFailedFields(event.target.name);
		this.setState({
			[event.target.name]: event.target.value,
			failedFields,
		});
	};

	checkDateFormatAndIsValid = date => {
		const isInvalidDateFormat = MainService.isValidDateTime(date);
		const isInvalidDate = MainService.validateDate(date);
		if (!isInvalidDateFormat || !isInvalidDate) {
			this.setState({ isInvalidDate: true });
		} else this.setState({ isInvalidDate: false });
	};

	removeFailedFields = name => {
		const failedFields = this.state.failedFields && {
			...this.state.failedFields,
		};
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFields && delete failedFields[fieldName];
		return failedFields;
	};

	disAgreeContract = () => {
		this.setState({
			changeDateTime: !this.state.changeDateTime,
			isInvalidDate: false,
		});
	};

	signContract = contract => {
		if (!contract) return;
		const isValidDate = MainService.checkDates(
			new Date(),
			moment(
				MainService.convertUTCDateToLocalDate(new Date(contract.contractDate)),
			),
		);
		if (isValidDate) {
			this.props.signContract(contract.id);
		} else {
			this.setState({ isInvalidDate: true });
		}
	};

	updateDate = (dateTime, contract) => {
		const { isInvalidDate } = this.state;
		const isValidDate = MainService.checkDates(
			new Date(),
			moment(
				MainService.convertUTCDateToLocalDate(
					new Date(dateTime ? dateTime : contract.contractDate),
				),
			),
		);
		this.setState({ changeDateTime: false });
		isValidDate && !isInvalidDate && this.props.updateDate(dateTime);
		!isValidDate && this.setState({ isInvalidDate: true });
	};

	goToWebinar = () => {
		window.location.href = process.env.REACT_APP_WEBINAR_SCREEN_URL_KEY;
	};

	checkStudentBalance = () => {
		const spinnerId = uuid();
		let webinarId;
		if (localStorage.getItem(WEBINAR_DATA_KEY)) {
			webinarId = JSON.parse(localStorage.getItem(WEBINAR_DATA_KEY)).webinarId;
		}
		if (!webinarId) {
			return;
		}
		this.props.addPageSpinner(spinnerId);
		ApiService.checkStudentBalance(webinarId)
			.then(response => {
				this.props.removePageSpinner(spinnerId);
				if (
					response &&
					(response.data || typeof response.data === NUMBER_KEY)
				) {
					if (response.data === 0) {
						this.goToWebinar();
					} else {
						this.setState({
							showConfirmModal: true,
							sum: Math.ceil(response.data),
						});
					}
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	redirectToBalancePage = () => {
		const { user, language } = this.props;
		const { sum } = this.state;
		if (!user || !language) {
			return;
		}
		localStorage.setItem(FILL_BALANCE_KEY, sum);
		if (user && user.userTypeId === CONSULTANT_USER_TYPE)
			this.props.history.push(`/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}`);
		if (user && user.userTypeId === INSTRUCTOR_KEY)
			this.props.history.push(`/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}`);
		if (user && user.userTypeId === STUDENT_USER_TYPE)
			this.props.history.push(`/${language}/${STUDENT_KEY}/${FINANCES_KEY}`);
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePageSpinner(spinnerId);
	};

	render() {
		const { isInvalidSubmit, contract, isConsultant, user } = this.props;
		const {
			dateTime,
			failedFields,
			translationService,
			changeDateTime,
			isInvalidDate,
			showConfirmModal,
		} = this.state;

		var webinarCalendarId;
		if (localStorage.getItem(WEBINAR_DATA_KEY)) {
			webinarCalendarId = JSON.parse(
				localStorage.getItem(WEBINAR_DATA_KEY),
			).webinarCalendarId;
		}
		return translationService && contract && contract.hasOwnProperty("id") ? (
			<div className="row">
				<div className="col-12 mt-3">
					{showConfirmModal ? (
						<div className="info-alert-container">
							<div className="layer" />
							<Auxiliary>
								<div className="info-alert-block">
									<div className="info-description">
										{`${translationService.translate(
											"TR_INSUFFICIENT_BALANCE_FOR_CONSULTATION_INFO",
										)} ${this.state.sum}$`}
									</div>
									<hr />
									<div className="d-flex justify-content-center">
										<button
											className="mindalay--btn-default mr-2"
											onClick={this.redirectToBalancePage}>
											{translationService.translate("TR_FILL")}
										</button>
										<Link
											to="#"
											onClick={() => this.setState({ showConfirmModal: false })}
											className="mindalay--btn-default ml-2">
											{translationService.translate("TR_CANCEL")}
										</Link>
									</div>
								</div>
							</Auxiliary>
						</div>
					) : null}
					<div className="box-shadow-4 mindalay--consultation-container m-3">
						<div className="consultation-container-left-side">
							<div className="d-flex justify-content-between">
								<div>
									<strong className="consultation-header-name">
										{contract.consultation?.name}
									</strong>
									<small className="d-block">
										{`${
											contract.consultation?.studentUserName
										} | ${translationService.translate("TR_CREATED")}: ${moment(
											MainService.convertUTCDateToLocalDate(
												new Date(contract.consultation?.creationDate),
											),
										).format("ll (HH:mm)")}`}
									</small>
								</div>
								<div className="mindalay--contract-date d-flex align-items-center">
									<div>
										{isConsultant &&
										!contract.consultantAgreedOnDate &&
										contract?.contractDate &&
										!changeDateTime ? (
											<div className="form-group">
												<div className="info-title d-inline-block mr-2">
													<div>
														<InfoSvg />
														<span style={{ display: "none" }}>
															{translationService.translate(
																"TR_AGREE_CONSULTATION_INFO",
															)}
														</span>
													</div>
												</div>
											</div>
										) : null}
										{!isConsultant &&
										contract.consultantAgreedOnDate &&
										!changeDateTime &&
										!contract.signed ? (
											<div className="form-group">
												<div className="info-title d-inline-block mr-2">
													<div>
														<InfoSvg />
														<span style={{ display: "none" }}>
															{translationService.translate(
																"TR_SIGN_CONSULTATION_INFO",
															)}
														</span>
													</div>
												</div>
											</div>
										) : null}
										{!contract.isCompleted &&
										(!contract?.contractDate || changeDateTime) ? (
											<div className="form-group text-left">
												<div className="info-title d-inline-block mr-2">
													<div>
														<InfoSvg />
														<span style={{ display: "none" }}>
															{translationService.translate(
																"TR_PREPARTION_PERIOD_CONSULTATION_INFO",
															)}
														</span>
													</div>
												</div>
											</div>
										) : null}
									</div>
									{!contract.isCompleted &&
									(!contract?.contractDate || changeDateTime) ? (
										<Input
											type="datetime-local"
											id="dateTime"
											name="dateTime"
											value={dateTime}
											onChange={this.onDateChange}
											onBlur={() => this.checkDateFormatAndIsValid(dateTime)}
											failedFields={failedFields}
											isInvalidSubmit={isInvalidSubmit}
										/>
									) : null}
									{isInvalidDate ? (
										<small className="red-color info-local-alert">
											{translationService.translate("TR_INVALID_TIME_MSG")}
										</small>
									) : null}
									{contract?.contractDate && !changeDateTime ? (
										<div className="form-group">
											<span alt="consultation date">
												{moment(
													MainService.convertUTCDateToLocalDate(
														new Date(contract?.contractDate),
													),
												).format("lll")}
											</span>
										</div>
									) : null}
								</div>
							</div>
							<hr />
							<div>
								<p className="full-height">
									<span>
										{contract.coverLetter ? (
											<span>
												<span className="d-block font-weight-bold my-2">
													{translationService.translate("TR_COVER_LETTER")}
												</span>
												{contract.coverLetter}
											</span>
										) : null}
										{contract.consultation?.description ? (
											<span className="flex-column d-block mt-3">
												<span className="d-block font-weight-bold my-2">
													{translationService.translate("TR_DESCRIPTION")}
												</span>
												{contract.consultation.description}
											</span>
										) : null}
									</span>
								</p>
							</div>
						</div>
						{/* <hr/> */}
						<div className="consultation-container-right-side">
							{(isConsultant &&
								!contract.consultantAgreedOnDate &&
								!contract?.contractDate) ||
							(isConsultant &&
								!contract.consultantAgreedOnDate &&
								contract?.contractDate) ||
							(!isConsultant &&
								!contract.consultantAgreedOnDate &&
								!changeDateTime &&
								contract?.contractDate) ||
							(!isConsultant && changeDateTime && contract?.contractDate) ||
							(!isConsultant &&
								!changeDateTime &&
								!contract?.contractDate &&
								contract?.studentAccepted &&
								!contract.isCompleted) ||
							(!isConsultant &&
								contract.consultantAgreedOnDate &&
								!changeDateTime &&
								!contract.signed) ? (
								<hr />
							) : null}
							<div className="d-flex justify-content-end w-100">
								{/* Consultant */}
								{isConsultant &&
								!contract.consultantAgreedOnDate &&
								!contract?.contractDate ? (
									<div className="btn-group mt-2">
										<button
											type="button"
											className={`mindalay--btn-dark mr-1 w-100 ${
												isInvalidDate ? "disabled-input" : ""
											}`}
											onClick={() => this.updateDate(dateTime, contract)}>
											{translationService.translate("TR_SUGGEST_DATE")}
										</button>
									</div>
								) : null}
								{isConsultant &&
								!contract.consultantAgreedOnDate &&
								contract?.contractDate ? (
									<div className="btn-group mt-2">
										<button
											type="button"
											className={`mindalay--btn-dark mr-1 ${
												isInvalidDate ? "disabled-input" : ""
											}`}
											onClick={() => this.updateDate(dateTime, contract)}>
											{translationService.translate(
												!changeDateTime ? "TR_AGREE" : "TR_SUGGEST_DATE",
											)}
										</button>
										<button
											type="button"
											className="mindalay--btn-secondary-outline ml-1"
											onClick={this.disAgreeContract}>
											{translationService.translate(
												!changeDateTime ? "TR_DISAGREE" : "TR_CANCEL",
											)}
										</button>
									</div>
								) : null}
								{/* Student part */}
								{!isConsultant &&
								!contract.consultantAgreedOnDate &&
								!changeDateTime &&
								contract?.contractDate ? (
									<div className="btn-group mt-2">
										<button
											type="button"
											className="mindalay--btn-secondary-outline ml-1"
											onClick={this.disAgreeContract}>
											{translationService.translate("TR_CANCEL")}
										</button>
									</div>
								) : null}
								{!isConsultant && changeDateTime && contract?.contractDate ? (
									<div className="btn-group mt-2">
										<button
											type="button"
											className={`mindalay--btn-dark mr-1 ${
												isInvalidDate ? "disabled-input" : ""
											}`}
											onClick={() => this.updateDate(dateTime, contract)}>
											{translationService.translate("TR_SUGGEST_DATE")}
										</button>
										<button
											type="button"
											className="mindalay--btn-secondary-outline ml-1"
											onClick={this.disAgreeContract}>
											{translationService.translate("TR_CANCEL")}
										</button>
									</div>
								) : null}
								{!isConsultant &&
								!changeDateTime &&
								!contract?.contractDate &&
								contract?.studentAccepted &&
								!contract.isCompleted ? (
									<div className="btn-group mt-2">
										<button
											type="button"
											className={`mindalay--btn-dark mr-1 ${
												isInvalidDate ? "disabled-input" : ""
											}`}
											onClick={() => this.updateDate(dateTime, contract)}>
											{translationService.translate("TR_SUGGEST_DATE")}
										</button>
									</div>
								) : null}
								{!isConsultant &&
								contract.consultantAgreedOnDate &&
								!changeDateTime &&
								!contract.signed ? (
									<div className="btn-group mt-2">
										<button
											type="button"
											className="mindalay--btn-dark mr-1"
											onClick={() => this.signContract(contract)}>
											{translationService.translate("TR_SIGN")}
										</button>
										<button
											type="button"
											className="mindalay--btn-secondary-outline ml-1"
											onClick={this.disAgreeContract}>
											{translationService.translate("TR_CANCEL")}
										</button>
									</div>
								) : null}
								{contract.isCompleted &&
								(contract.totalConsultationCost || contract.approximateCost) ? (
									<div className="mt-2 text-right">
										<b className="complited-consultation-price">
											{contract.totalConsultationCost
												? contract.totalConsultationCost
												: contract.approximateCost
												? contract.approximateCost
												: 0}
										</b>
										<small className="d-block">
											{translationService.translate(
												"TR_COST_FOR_THE_CONSULTATION",
											)}
										</small>
									</div>
								) : null}
								{webinarCalendarId &&
								contract.webinarCalendarId &&
								+webinarCalendarId === +contract.webinarCalendarId ? (
									<button
										type="button"
										className="mindalay--btn-dark mr-1 w-100 my-4"
										// onClick={this.goToWebinar}>
										onClick={
											contract.consultation.studentUserId === user.id
												? () => this.checkStudentBalance()
												: () => this.goToWebinar()
										}>
										{contract.consultation.studentUserId !== user.id
											? translationService.translate("TR_START_CONSULTATION")
											: translationService.translate("TR_JOIN_TO_CONSULTATION")}
									</button>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	languages: state.language.languages,
	language: state.language.language,
	user: state.user.user,
});

const mapDispatchToProps = {
	addPageSpinner,
	removePageSpinner,
	addModalSpinner,
	removeModalSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(ContractInfo);
