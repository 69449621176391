import React from "react";

function TestsSvg({ className = "", style = {} }) {
	return (
		<svg
			fill="#000000"
			className={className}
			style={style}
			height="800px"
			width="800px"
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 512 512"
			xmlSpace="preserve">
			<g>
				<g>
					<path
						d="M478.33,0h-85.511c-18.566,0-33.67,15.105-33.67,33.67v85.511c0,18.566,15.105,33.67,33.67,33.67h85.511
			c18.566,0,33.67-15.105,33.67-33.67V33.67C512,15.105,496.895,0,478.33,0z M495.967,119.182c0,9.725-7.912,17.637-17.637,17.637
			h-85.511c-9.725,0-17.637-7.912-17.637-17.637V33.67c0-9.725,7.912-17.637,17.637-17.637h85.511
			c9.725,0,17.637,7.912,17.637,17.637V119.182z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M483.998,45.104c-3.13-3.131-8.207-3.131-11.337,0l-45.639,45.639l-19.985-19.985c-3.131-3.131-8.207-3.131-11.337,0
			c-3.131,3.131-3.131,8.207,0,11.337l25.653,25.653c1.565,1.565,3.617,2.348,5.668,2.348s4.103-0.782,5.668-2.348l51.307-51.307
			C487.129,53.31,487.129,48.234,483.998,45.104z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M478.33,179.574h-85.511c-18.566,0-33.67,15.105-33.67,33.67v85.511c0,18.566,15.105,33.67,33.67,33.67h85.511
			c18.566,0,33.67-15.105,33.67-33.67v-85.511C512,194.679,496.895,179.574,478.33,179.574z M495.967,298.756
			c0,9.725-7.912,17.637-17.637,17.637h-85.511c-9.725,0-17.637-7.912-17.637-17.637v-85.511c0-9.725,7.912-17.637,17.637-17.637
			h85.511c9.725,0,17.637,7.912,17.637,17.637V298.756z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M478.33,359.148h-85.511c-18.566,0-33.67,15.105-33.67,33.67v85.511c0,18.566,15.105,33.67,33.67,33.67h85.511
			c18.566,0,33.67-15.105,33.67-33.67v-85.511C512,374.253,496.895,359.148,478.33,359.148z M495.967,478.33
			c0,9.725-7.912,17.637-17.637,17.637h-85.511c-9.725,0-17.637-7.912-17.637-17.637v-85.511c0-9.725,7.912-17.637,17.637-17.637
			h85.511c9.725,0,17.637,7.912,17.637,17.637V478.33z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M483.998,404.252c-3.13-3.131-8.207-3.131-11.337,0l-45.639,45.639l-19.985-19.985c-3.131-3.131-8.207-3.131-11.337,0
			c-3.131,3.131-3.131,8.207,0,11.337l25.653,25.653c1.565,1.565,3.617,2.348,5.668,2.348s4.103-0.782,5.668-2.348l51.307-51.307
			C487.129,412.458,487.129,407.382,483.998,404.252z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M179.04,42.756H8.017C3.589,42.756,0,46.345,0,50.772s3.589,8.017,8.017,8.017H179.04c4.427,0,8.017-3.589,8.017-8.017
			S183.467,42.756,179.04,42.756z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M307.307,42.756h-94.063c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h94.063
			c4.427,0,8.017-3.589,8.017-8.017S311.734,42.756,307.307,42.756z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M307.307,94.063h-34.205c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.205
			c4.427,0,8.017-3.589,8.017-8.017S311.734,94.063,307.307,94.063z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M76.426,94.063H8.017C3.589,94.063,0,97.652,0,102.079s3.589,8.017,8.017,8.017h68.409c4.427,0,8.017-3.589,8.017-8.017
			S80.853,94.063,76.426,94.063z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M238.898,94.063H110.63c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h128.267
			c4.427,0,8.017-3.589,8.017-8.017S243.325,94.063,238.898,94.063z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M179.04,222.33H8.017c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017H179.04c4.427,0,8.017-3.589,8.017-8.017
			S183.467,222.33,179.04,222.33z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M307.307,222.33h-94.063c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h94.063
			c4.427,0,8.017-3.589,8.017-8.017S311.734,222.33,307.307,222.33z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M307.307,273.637h-34.205c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.205
			c4.427,0,8.017-3.589,8.017-8.017S311.734,273.637,307.307,273.637z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M76.426,273.637H8.017c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h68.409c4.427,0,8.017-3.589,8.017-8.017
			S80.853,273.637,76.426,273.637z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M238.898,273.637H110.63c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h128.267
			c4.427,0,8.017-3.589,8.017-8.017S243.325,273.637,238.898,273.637z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M179.04,401.904H8.017c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017H179.04
			c4.427,0,8.017-3.589,8.017-8.017C187.056,405.493,183.467,401.904,179.04,401.904z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M307.307,401.904h-94.063c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h94.063
			c4.427,0,8.017-3.589,8.017-8.017C315.324,405.493,311.734,401.904,307.307,401.904z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M307.307,453.211h-34.205c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h34.205
			c4.427,0,8.017-3.589,8.017-8.017C315.324,456.8,311.734,453.211,307.307,453.211z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M76.426,453.211H8.017C3.589,453.211,0,456.8,0,461.228c0,4.427,3.589,8.017,8.017,8.017h68.409
			c4.427,0,8.017-3.589,8.017-8.017C84.443,456.8,80.853,453.211,76.426,453.211z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M238.898,453.211H110.63c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h128.267
			c4.427,0,8.017-3.589,8.017-8.017C246.914,456.8,243.325,453.211,238.898,453.211z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M466.896,275.985L446.911,256l19.985-19.985c3.131-3.13,3.131-8.207,0-11.337c-3.131-3.131-8.207-3.131-11.337,0
			l-19.985,19.985l-19.985-19.985c-3.131-3.131-8.207-3.131-11.337,0c-3.131,3.131-3.131,8.207,0,11.337L424.237,256l-19.985,19.985
			c-3.131,3.131-3.131,8.207,0,11.337c1.565,1.565,3.617,2.348,5.668,2.348s4.103-0.782,5.668-2.348l19.985-19.985l19.985,19.985
			c1.565,1.565,3.617,2.348,5.668,2.348s4.103-0.782,5.668-2.348C470.027,284.191,470.027,279.115,466.896,275.985z"
					/>
				</g>
			</g>
		</svg>
	);
}

export default TestsSvg;
