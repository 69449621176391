import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "./../../Services/translationService";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import AlertService from "../../Services/alertService";
import {
	ERROR_KEY,
	PAYMENT_RECEIPT_TYPES,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import {
	removePartialViewSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
} from "../../Store/Actions/spinner";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import CloseSvg from "../../Components/Svg/closeSvg";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import Textarea from "./../../Components/Inputs/textArea";
import { addModalSpinner } from "./../../Store/Actions/spinner";
import ReactPaginate from "react-paginate";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";

const PaymentReceipt = () => {
	const declineMessageMaxLength = 1000;
	const pageSize = 10;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const [paymentReceipts, setPaymentReceipts] = useState([]);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [zoomImagePath, setZoomImagePath] = useState("");
	const [currentPaymentReceipt, setCurrentPaymentReceipt] = useState(null);
	const [isShowDeclineModal, setIsShowDeclineModal] = useState(false);
	const [declineMessage, setDeclineMessage] = useState("");
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [addButtonIsDisabled, setAddButtonIsDisabled] = useState(false);
	const [pagination, setPagination] = useState(null);
	const [receiptType, setReceiptType] = useState(0);
	const [organizationData, setOrganizationData] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getPaymentReceipts(receiptType, 1);
		window.addEventListener("keydown", zoomOut);
		return () => {
			window.removeEventListener("keydown", zoomOut);
		};
	}, []);

	const handlePageClick = event => {
		getPaymentReceipts(receiptType, event.selected + 1);
		setActivePageNumber(event.selected);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const getPaymentReceipts = (receiptType, currentPage) => {
		const spinnerId = uuid();
		setPaymentReceipts([]);
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPaymentReceipts(receiptType, currentPage, pageSize)
			.then(response => {
				if (response && response.data) {
					setPaymentReceipts(response.data);
				}
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const acceptPaymentReceipt = paymentReceipt => {
		if (!paymentReceipt) {
			return;
		}
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ACCEPT_CONFIRM_MESSAGE")} "${
				paymentReceipt.paymentPackageName
			}" ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.acceptPaymentReceipt(paymentReceipt.id)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					dispatch(removePartialViewSpinner(spinnerId));
					getPaymentReceipts(receiptType, 1);
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const showOrHideDeclineModal = currentPaymentReceipts => {
		if (!currentPaymentReceipts) {
			return false;
		}
		setCurrentPaymentReceipt(currentPaymentReceipts);
		setIsShowDeclineModal(true);
	};

	const cancel = () => {
		setIsShowDeclineModal(false);
		setCurrentPaymentReceipt(null);
		setAddButtonIsDisabled(false);
		setIsInvalidSubmit(false);
		setDeclineMessage("");
	};

	const declinePaymentReceipt = paymentReceipt => {
		if (!paymentReceipt) {
			return;
		}
		if (!declineMessage || !declineMessage.trim()) {
			setIsInvalidSubmit(true);
		} else {
			const spinnerId = uuid();
			setAddButtonIsDisabled(true);
			dispatch(addModalSpinner(spinnerId));
			const data = {
				rejectionMessage: declineMessage,
				id: paymentReceipt.id,
			};
			ApiService.declinePaymentReceipt(data)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					dispatch(removeModalSpinner(spinnerId));
					setAddButtonIsDisabled(false);
					cancel();
					getPaymentReceipts(receiptType, 1);
				})
				.catch(error => getFail(error, spinnerId));
		}
	};

	const zoomImage = imagePath => {
		if (!imagePath) {
			return false;
		}
		setZoomImagePath(imagePath);
	};

	const zoomOut = event => {
		if (event.key === "Escape") {
			hideZoomImage();
		}
	};

	const hideZoomImage = () => {
		setZoomImagePath(null);
	};

	const filterByReceiptType = selectedCategory => {
		if (!selectedCategory) {
			return;
		}
		setReceiptType(selectedCategory.value);
		getPaymentReceipts(selectedCategory.value, 1);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeModalSpinner(spinnerId));
		setAddButtonIsDisabled(false);
	};

	const onClose = () => {
		setOrganizationData(null);
	};

	return translationService ? (
		<div className="container">
			{!!organizationData && (
				<ModalComponent
					cancel={onClose}
					cancelButtonTitle="TR_CLOSE"
					title={organizationData?.name}>
					{organizationData ? (
						<div className="row">
							<div className="col-12">
								{/* <div className="d-flex justify-content-between  border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ORGANIZATION_NAME",
									)}:`}</b>
									<span>{}</span>
								</div> */}
								<div className="d-flex justify-content-between pb-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ORGANIZATION_EMAIL",
									)}:`}</b>
									<span>{organizationData?.email}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_USER_NAME",
									)}:`}</b>
									<span>{organizationData?.organizationAdminName}</span>
								</div>
							</div>
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<img src={NoDataImage} alt="/" height={150} />
							</div>
						</div>
					)}
				</ModalComponent>
			)}
			<div className="row">
				<div className="col-12">
					<h2 className="content-title p-0">
						{" "}
						{translationService.translate("TR_PAYMENT_RECEIPT")}
					</h2>
					<hr />
				</div>
			</div>
			{zoomImagePath ? (
				<div className="zoom-image-container" onClick={hideZoomImage}>
					<div className="close-svg">
						<CloseSvg />
					</div>
					<img src={zoomImagePath} alt="/" />
				</div>
			) : null}
			{isShowDeclineModal && currentPaymentReceipt ? (
				<ModalComponent
					title={
						currentPaymentReceipt.paymentPackageName
							? currentPaymentReceipt.paymentPackageName
							: ""
					}
					contentClassName="resource-modal"
					isLarge={true}
					addButtonIsDisabled={addButtonIsDisabled}
					actionButtonTitle={translationService.translate("TR_SAVE")}
					cancel={cancel}
					onSubmit={() => declinePaymentReceipt(currentPaymentReceipt)}>
					<div className="row">
						<div className="col-12">
							<div className="mx-4">
								<Textarea
									id="declineMessage"
									name="declineMessage"
									rows="6"
									labelClassName="big-sub-title mt-2"
									textAreaClassName="pr--5"
									value={declineMessage}
									max={declineMessageMaxLength}
									labelValue={`${translationService.translate(
										"TR_DECLINE_MESSAGE",
									)} *`}
									isInvalidSubmit={isInvalidSubmit}
									isInvalidField={
										isInvalidSubmit &&
										(!declineMessage || !declineMessage.trim())
											? true
											: false
									}
									onChange={event => {
										if (declineMessageMaxLength >= event.target.value.length) {
											setDeclineMessage(event.target.value);
										} else {
											return false;
										}
									}}
								/>
							</div>
						</div>
					</div>
				</ModalComponent>
			) : null}
			<div className="row">
				<div className="col-md-6 col-12 mb-4">
					<label>{translationService.translate("TR_FILTER_BY_TYPE")}</label>
					<ReactSelectOption
						value={receiptType}
						selectedValue={(() => {
							const selectedValue = {
								...PAYMENT_RECEIPT_TYPES.find(
									data => data.value === receiptType,
								),
							};
							if (selectedValue) {
								selectedValue.label = translationService.translate(
									selectedValue.label,
								);
								selectedValue.value = selectedValue.value;
							}
							return selectedValue;
						})()}
						items={PAYMENT_RECEIPT_TYPES.map(data => ({
							label: translationService.translate(data.label),
							value: data.value,
						}))}
						onChange={item => filterByReceiptType(item)}
					/>
				</div>
			</div>
			{paymentReceipts && paymentReceipts.length ? (
				<div className="row">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table id="table" className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_IMAGE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ORGANIZATION_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_MONTH_COUNT")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_PACKAGE_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_TOTAL_PRICE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{paymentReceipts.map((paymentReceipt, index) => {
										return (
											<tr key={index} className="cursor-default">
												<td>
													<div
														className="file-library-item cursor-pointer"
														style={{
															backgroundImage: `url(${paymentReceipt.chequePath})`,
														}}
														onClick={() => zoomImage(paymentReceipt.chequePath)}
													/>
												</td>
												<td>
													<div>{paymentReceipt?.organization?.name}</div>
												</td>
												<td>
													<div>{paymentReceipt.monthCount}</div>
												</td>
												<td>
													<div>{paymentReceipt.paymentPackageName}</div>
												</td>
												<td>
													<div>{paymentReceipt.totalPrice}</div>
												</td>
												<td>
													<div className="d-flex align-items-center">
														{paymentReceipt.isPaid ? (
															<div key={paymentReceipt.id}>
																{translationService.translate("TR_PAID")}
															</div>
														) : !paymentReceipt.isPaid &&
														  !paymentReceipt.rejectionMessage ? (
															<React.Fragment
																key={paymentReceipt.id}
																className="">
																<button
																	className="mindalay--btn mindalay--btn-small  mindalay--btn-default mr-1"
																	onClick={() =>
																		acceptPaymentReceipt(paymentReceipt)
																	}>
																	{translationService.translate("TR_ACCEPT")}
																</button>
																<button
																	className="mindalay--btn mindalay--btn-small mindalay--btn-default ml-1"
																	onClick={() =>
																		showOrHideDeclineModal(paymentReceipt)
																	}>
																	{translationService.translate("TR_DECLINE")}
																</button>
															</React.Fragment>
														) : paymentReceipt.rejectionMessage ? (
															<div
																key={paymentReceipt.id}
																title={paymentReceipt.rejectionMessage}
																className="red-color">
																{translationService.translate("TR_DECLINED")}
															</div>
														) : null}
														<button
															className="mindalay--btn mindalay--btn-small mindalay--btn-default ml-1 text-nowrap"
															onClick={
																() => {
																	setOrganizationData(
																		paymentReceipt.organization,
																	);
																}
																// showOrHideDeclineModal(paymentReceipt)
															}>
															{translationService.translate("TR_VIEW_DETAILS")}
														</button>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						{pagination ? (
							<div className="row mt-4">
								<div className="col-12">
									<div>
										<ReactPaginate
											nextLabel={translationService.translate("TR_NEXT")}
											onPageChange={handlePageClick}
											pageRangeDisplayed={3}
											marginPagesDisplayed={2}
											pageCount={pagination.TotalPages}
											previousLabel={translationService.translate(
												"TR_PREVIOUS",
											)}
											pageClassName="page-item"
											pageLinkClassName="page-link"
											previousClassName="page-item"
											previousLinkClassName="page-link"
											nextClassName="page-item"
											nextLinkClassName="page-link"
											breakLabel="..."
											breakClassName="page-item"
											breakLinkClassName="page-link"
											containerClassName="pagination"
											activeClassName="active"
											renderOnZeroPageCount={null}
											forcePage={activePageNumber}
										/>
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			) : (
				<div className="no-data-container">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default PaymentReceipt;
