import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import { ERROR_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import {
	addButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	removeButtonSpinner,
} from "./../../Store/Actions/spinner";
import Input from "../../Components/Inputs/input";
import ActionButton from "../../Components/ActionButton/actionButton";
import { Link } from "react-router-dom";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";

const ThemeForm = props => {
	const defaultValue = { label: "", value: "" };
	const nameMaxLength = 120;
	const mainSpinnerId = uuid();
	const themeId = props.match.params.themeId;
	const categoryType = 2;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [mainCategoryId, setMainCategoryId] = useState(null);
	const [translationService, setTranslationService] = useState(null);
	var [secondLevelCategoryIds, setSecondLevelCategoryIds] = useState([]);
	const [name, setName] = useState("");
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getMainCategories(categoryType);
	}, []);

	const getMainCategories = categoryType => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.getMainCategories(categoryType)
			.then(response => {
				if (response && response.data) {
					const data = [...response.data];
					setCategories(data);
					themeId && getThemeById(+themeId, data);
				}
				dispatch(removePageSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getThemeById = (themeId, categories) => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.getThemeById(themeId)
			.then(response => {
				dispatch(removePageSpinner(spinnerId));
				if (response && response.data) {
					const data = { ...response.data };
					setName(data.name);
					setSecondLevelCategoryIds(data.categories ? data.categories : []);
					updatePageContent(data, categories);
				}
			})
			.catch(error => getFail(error, spinnerId));
	};

	const updatePageContent = (currentTheme, categories) => {
		if (!categories || !currentTheme) {
			return;
		}
		categories &&
			categories.forEach(category => {
				if (category.id === currentTheme.categoryId) {
					setMainCategoryId(currentTheme.categoryId);
					getSubCategories(currentTheme.categoryId);
				}
			});
	};

	const setFirstLevelCategory = selectedCategory => {
		if (!selectedCategory) {
			return;
		}
		setMainCategoryId(selectedCategory.id);
		setSubCategories([]);
		getSubCategories(selectedCategory.id);
	};

	const getSubCategories = mainCategoryId => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.getSubCategories(mainCategoryId)
			.then(response => {
				dispatch(removePageSpinner(spinnerId));
				setSubCategories(response.data);
			})
			.catch(error => getFail(error, null));
	};

	const onChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setName(event.target.value);
		setIsInvalidSubmit(false);
	};

	const changeCategoryItem = (event, subCategoryId) => {
		if (event.target.checked) {
			let newSecondLevelCategoryIds = [
				...secondLevelCategoryIds,
				subCategoryId,
			];
			setSecondLevelCategoryIds(newSecondLevelCategoryIds);
		} else {
			const index = secondLevelCategoryIds.indexOf(subCategoryId);
			if (index > -1) {
				secondLevelCategoryIds.splice(index, 1);
				setSecondLevelCategoryIds(
					secondLevelCategoryIds && secondLevelCategoryIds.length
						? secondLevelCategoryIds
						: [],
				);
			}
		}
	};

	const onSubmit = event => {
		event.preventDefault();
		if (!name || (name && !name.trim())) {
			setIsInvalidSubmit(true);
			return;
		}
		if (themeId) {
			const data = {
				id: +themeId,
				categories: secondLevelCategoryIds,
			};
			ApiService.updateTheme(data)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_UPDATE_THEME_INFO"),
					);
					dispatch(removeButtonSpinner(mainSpinnerId));
				})
				.catch(error => getFail(error, mainSpinnerId));
		} else {
			const data = {
				name,
				categories: secondLevelCategoryIds,
			};
			if (!secondLevelCategoryIds.length) {
				setIsInvalidSubmit(true);
				return;
			}
			dispatch(addButtonSpinner(mainSpinnerId));
			ApiService.createTheme(data)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_CREATE_THEME_INFO"),
					);
					setName("");
					dispatch(removeButtonSpinner(mainSpinnerId));
				})
				.catch(error => getFail(error, mainSpinnerId));
		}
	};

	const cancel = () => {
		setSubCategories([]);
	};

	const goBack = () => {
		window.history.back();
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePageSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
	};

	return translationService && categories && categories.length ? (
		<div className="container">
			<div className="row">
				<div className="col-12 content-title">
					<div className="d-flex align-items-center">
						<Link
							to="#"
							title={translationService.translate("TR_BACK")}
							onClick={goBack}>
							<ArrowBackSvg />
						</Link>
						{!themeId ? (
							<h2 className="content-title p-0">
								{translationService.translate("TR_CREATE_THEME")}
							</h2>
						) : (
							<h2 className="content-title p-0">
								{translationService.translate("TR_UPDATE_THEME")}
							</h2>
						)}
					</div>
				</div>
			</div>
			<hr />
			<div className="content-body pt-0">
				<div className="row">
					<div className="col-lg-4 col-12">
						<div className="form-group">
							<label>{`${translationService.translate(
								"TR_CATEGORY",
							)} *`}</label>
							<ReactSelectOption
								defaultValue={defaultValue}
								isInvalidField={isInvalidSubmit && !mainCategoryId}
								// isDisabled={themeId ? true : false}
								value={mainCategoryId}
								selectedValue={(() => {
									const selectedValue = {
										...categories.find(data => data.id === mainCategoryId),
									};
									if (selectedValue) {
										selectedValue.label = selectedValue.name;
										selectedValue.value = selectedValue.id;
									}
									return selectedValue;
								})()}
								items={categories.map(data => ({
									...data,
									label: data.name,
									value: data.id,
								}))}
								onChange={item => setFirstLevelCategory(item)}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-lg-4 col-sm-6 col-12">
									<Input
										type="text"
										name="name"
										inputClassName={`pr--5 ${
											themeId ? "form-control disabled-input" : ""
										}`}
										blockClassName="w-100"
										fieldLength={!themeId ? nameMaxLength : null}
										value={name}
										labelValue={`${translationService.translate("TR_NAME")} *`}
										isInvalidSubmit={isInvalidSubmit}
										isInvalidField={isInvalidSubmit && !name.trim()}
										onChange={event => onChange(event, nameMaxLength)}
									/>
								</div>
								<div className="col-lg-4 col-sm-6 col-12">
									<label htmlFor="d-none d-sm-block ">{"\u00A0"}</label>
									<div className="d-flex">
										<ActionButton
											type="submit"
											spinnerId={mainSpinnerId}
											className="mindalay--btn-default position-relative"
											name={translationService.translate(
												!themeId ? "TR_CREATE" : "TR_UPDATE",
											)}
										/>
										<button
											type="button"
											className="ml-1 mindalay--btn-default"
											onClick={cancel}>
											{translationService.translate("TR_CANCEL")}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				{subCategories && subCategories.length ? (
					<div className="col-12">
						<label>{`${translationService.translate("TR_SUBCATEGORY")}`}</label>
						{subCategories.map(subCategory => {
							const filteredCategoryIds = secondLevelCategoryIds.filter(
								secondLevelCategoryId =>
									secondLevelCategoryId === subCategory.id,
							);
							return (
								<div key={subCategory.id} className="d-block">
									<InputCheckBox
										id={`${subCategory.name}`}
										labelValue={subCategory.name}
										defaultChecked={
											filteredCategoryIds && filteredCategoryIds.length
												? true
												: false
										}
										onChange={event =>
											changeCategoryItem(event, subCategory.id)
										}
									/>
								</div>
							);
						})}
					</div>
				) : null}
			</div>
		</div>
	) : null;
};

export default ThemeForm;
