import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import ActionButton from "../../Components/ActionButton/actionButton";
import DiaryDocument from "../../Components/Documents/DiaryDocument";
import ReportDocument from "../../Components/Documents/ReportDocument";
import Input from "../../Components/Inputs/input";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
} from "../../Constants/mainKeys";
import { DOCUMENT_KEY } from "../../Constants/urlKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePageSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ArrowBackSvg from "./../../Components/Svg/arrowBackSvg";
import CloseSvg from "./../../Components/Svg/closeSvg";
import UploadButton from "./../../Components/UploadButton/uploadButton";
import MainService from "../../Services/mainService";
import Textarea from "./../../Components/Inputs/textArea";

const DocumentForm = props => {
	const documentType = [
		{
			value: 2,
			documentTemplateId: 8,
			label: "Отчет",
		},
		{
			value: 4,
			documentTemplateId: 11,
			label: "Дневники",
		},
	];
	const { documentId } = props.match.params;

	const nameMaxLength = 120;
	const descriptionMaxLength = 250;
	const mainService = new MainService();
	const dispatch = useDispatch();

	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);

	const [documentTypeId, setdocumentTypeId] = useState(2);
	const [documentTemplateId, setdocumentTemplateId] = useState(8);
	const [documentCategoryId, setdocumentCategoryId] = useState(2);
	const [translationService, setTranslationService] = useState(null);
	const buttonSpinnerId = uuid();
	const [documentName, setDocumentName] = useState("");
	const [tableDataCopy, setTableDataCopy] = useState([]);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(null);
	const [description, setDescription] = useState("");

	const [uploadedFilePath, setUploadedFilePath] = useState(null);

	const [uploadedFile, setUploadedFile] = useState(null);
	const [file, setFile] = useState(null);

	const [groupName, setGroupName] = useState("");
	const [organizationName, setOrganizationName] = useState("");

	const [state, setState] = useState({
		a1: "",
		a2: "",
		a3: "",
		a4: "",
		a5: "",
		a6: "",
		a7: "",
		a8: "",
		a9: "",
		a10: "",
		a11: "",
		a12: "",
		a13: "",
		a14: "",
		a15: "",
		a16: "",
		a17: "",
		a18: "",
		a19: "",
		a20: "",
		a21: "",
		a22: "",
		a23: "",
		a24: "",
		a25: "",
		a26: "",
		a27: "",
		a28: "",
		a29: "",
		a30: "",
		a31: "",
		a32: "",
		a33: "",
		tableData: [],
	});

	useEffect(() => {
		if (documentId) {
			getDocumentById(documentId);
		}
		getOrgAndGroupname();
	}, []);

	const getOrgAndGroupname = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getAllStudentDivisions()
			.then(response => {
				if (response.data) {
					response.data.groupName && setGroupName(response.data.groupName);
					response.data.organizationName &&
						setOrganizationName(response.data.organizationName);
					dispatch(removePartialViewSpinner(spinnerId));
				}
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getDocumentById = documentId => {
		const spinnerId = uuid();
		if (!documentId) {
			return;
		}
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentByIdAsStudent(documentId)
			.then(response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.signFilePath) {
						props.history.push(`/${language}/${DOCUMENT_KEY}`);
					}
					dispatch(removePartialViewSpinner(spinnerId));
					data.documentCategoryId &&
						setdocumentCategoryId(data.documentCategoryId);
					data.documentCategoryId && setdocumentTypeId(data.documentCategoryId);
					data.documentTemplateId &&
						setdocumentTemplateId(data.documentTemplateId);
					data.description && setDescription(data.description);
					data.name && setDocumentName(data.name);
					data.filePath && setUploadedFilePath(data.filePath);
					if (data.data) {
						setState(JSON.parse(data.data));
						const tableDataFromResponse = JSON.parse(data.data);
						let tableDataCopy = [...tableDataFromResponse.tableData];
						tableDataCopy.forEach(tableDataItem => {
							tableDataItem.number = parseInt(tableDataItem.number);
							tableDataItem.columnNumber = parseInt(tableDataItem.columnNumber);
						});
						setTableDataCopy(tableDataCopy);
					}
				}
			})
			.catch(error => getFail(error, spinnerId));
	};

	const uploadFile = event => {
		const file = event.target.files[0];
		if (file) {
			mainService
				.readFile(file, VALID_IMAGE_TYPES_KEY)
				.then(uploadedFile => {
					setUploadedFile(uploadedFile);
					setFile(file);
				})
				.catch(
					error =>
						error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							translationService.translate("TR_INVALID_FILE_FORMAT_EXC"),
						),
				);
		}
	};

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const onSelectOptionChange = selectedItem => {
		setdocumentTypeId(selectedItem.value);
		setdocumentCategoryId(selectedItem.value);
		setdocumentTemplateId(selectedItem.documentTemplateId);
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setState(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	const removeImg = () => {
		setUploadedFile(null);
		setFile(null);
	};

	const onChange = (event, cb, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setIsInvalidSubmit(false);
		cb(event.target.value);
	};

	const onSubmit = () => {
		var stateCopy = { ...state };
		if (!documentName || (documentName && !documentName.trim().length)) {
			setIsInvalidSubmit(true);
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}
		if (documentTypeId === 2) {
			stateCopy.tableData = [];
		} else {
			stateCopy.tableData.forEach(tableDataItem => {
				tableDataItem.number = tableDataItem.number.toString();
				tableDataItem.columnNumber = tableDataItem.columnNumber.toString();
			});
		}
		const formData = new FormData();
		dispatch(addButtonSpinner(buttonSpinnerId));

		const form = {
			file,
			documentTemplateId,
			documentCategoryId,
			description: description.trim(),
			documentName: documentName.trim(),
		};

		if (file) {
			formData.append("documentFile", form.file);
		} else {
			formData.append("documentTemplateId", documentTemplateId);
			!uploadedFilePath && formData.append("data", JSON.stringify(stateCopy));
		}

		// !uploadedFilePath && formData.append("documentCategoryId", documentCategoryId);
		formData.append("documentCategoryId", documentCategoryId);
		form.documentName && formData.append("name", form.documentName);
		form.description && formData.append("description", form.description);

		documentId && formData.append("id", documentId);

		(documentId
			? ApiService.updateDocument(formData)
			: ApiService.createDocument(formData)
		)
			.then(response => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate(
						documentId
							? "TR_DOCUMENT_SUCCESSFULLY_UPDATED"
							: "TR_DOCUMENT_SUCCESSFULLY_CREATED",
					),
				);
				props.history.push(`/${language}/${DOCUMENT_KEY}`);
			})
			.catch(error => getFail(error, buttonSpinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		props.history.push(`/${language}/${DOCUMENT_KEY}`);
	};

	return translationService ? (
		<div className="m-section">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2 className="content-title p-0">
							<Link
								to={`/${language}/${DOCUMENT_KEY}`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							{props.match.params.documentId
								? translationService.translate("TR_UPDATE_DOCUMENT")
								: translationService.translate("TR_CREATE_DOCUMENT")}
						</h2>
						<hr />
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-lg-4 col-md-6">
						<div className="form-group">
							<label htmlFor="">
								{translationService.translate("TR_DOCUMENT_TYPE")}
							</label>
							<ReactSelectOption
								value={documentTypeId}
								selectedValue={(() => {
									const selectedItem = {
										...documentType.find(data => data.value === documentTypeId),
									};
									if (selectedItem) {
										selectedItem.value = selectedItem.value;
										selectedItem.label = selectedItem.label;
									}
									return selectedItem;
								})()}
								items={documentType.map(data => ({
									...data,
									label: data.label,
									value: data.value,
								}))}
								onChange={item => onSelectOptionChange(item)}
							/>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-lg-4 col-md-6">
						<div className="course-media-wrapper">
							<p className="my-2">
								{translationService.translate("TR_UPLOAD_FILE")}
							</p>
							{uploadedFile ? (
								<div className="upload-file-content">
									<div onClick={removeImg}>
										<CloseSvg />
									</div>
									<div
										className="img"
										style={{ backgroundImage: `url(${uploadedFile})` }}
										alt="course-image"></div>
								</div>
							) : null}
							{uploadedFilePath && !file ? (
								<div className="upload-file-content">
									<div
										className="img"
										style={{ backgroundImage: `url(${uploadedFilePath})` }}
										alt="course-image"></div>
								</div>
							) : null}
						</div>
						<div className="course-media-wrapper">
							<div className="form-group position-relative">
								<UploadButton
									textInputClasses="pl-100"
									text={translationService.translate("TR_UPLOAD_FILE")}
									clicked={uploadFile}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-lg-4 col-md-6">
						<Input
							type="text"
							id="name"
							name="name"
							inputClassName="pr--5"
							fieldLength={nameMaxLength}
							value={documentName}
							isInvalidSubmit={isInvalidSubmit}
							isInvalidField={isInvalidSubmit && !documentName.trim()}
							labelValue={`${translationService.translate(
								"TR_DOCUMENT_NAME",
							)} *`}
							onChange={event =>
								onChange(event, setDocumentName, nameMaxLength)
							}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<Textarea
							rows="2"
							id="description"
							name="description"
							value={description}
							max={descriptionMaxLength}
							labelValue={`${translationService.translate(
								"TR_DESCRIPTION_OR_NUMBER",
							)}`}
							onChange={event => {
								if (event.target.value.length <= descriptionMaxLength) {
									setDescription(event.target.value);
								} else {
									return;
								}
							}}
						/>
					</div>
				</div>

				{documentTypeId === 2 ? (
					<Auxiliary>
						{!file ? (
							uploadedFilePath ? null : (
								<ReportDocument
									organizationName={organizationName}
									groupName={groupName}
									state={state}
									handleChange={handleChange}
									setdocumentTemplateId={setdocumentTemplateId}
									setdocumentCategoryId={setdocumentCategoryId}
									setState={setState}
								/>
							)
						) : null}
						<div className="row">
							<div className="col-12">
								<ActionButton
									type="button"
									spinnerId={buttonSpinnerId}
									className="mindalay--btn-dark mb-4"
									name={
										documentId
											? translationService.translate("TR_UPDATE")
											: translationService.translate("TR_CREATE")
									}
									clicked={onSubmit}
								/>
							</div>
						</div>
					</Auxiliary>
				) : null}

				{documentTypeId === 4 ? (
					<Auxiliary>
						{!file ? (
							uploadedFilePath ? null : (
								<DiaryDocument
									organizationName={organizationName}
									groupName={groupName}
									state={state}
									tableDataCopy={tableDataCopy}
									handleChange={handleChange}
									setdocumentTemplateId={setdocumentTemplateId}
									setdocumentCategoryId={setdocumentCategoryId}
									setState={setState}
									setTableDataCopy={setTableDataCopy}
								/>
							)
						) : null}
						<div className="row">
							<div className="col-12">
								<ActionButton
									type="button"
									spinnerId={buttonSpinnerId}
									className="mindalay--btn-dark mb-4"
									name={
										documentId
											? translationService.translate("TR_UPDATE")
											: translationService.translate("TR_CREATE")
									}
									clicked={onSubmit}
								/>
							</div>
						</div>
					</Auxiliary>
				) : null}
			</div>
		</div>
	) : null;
};

export default withRouter(DocumentForm);
