import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";
import MainService from "../../../Services/mainService";
import AlertService from "../../../Services/alertService";
import ApiService from "../../../Services/apiService";
import TranslationService from "../../../Services/translationService";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import Input from "../../../Components/Inputs/input";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import ReactSelectOption from "../../../Components/SelectOption/reactSelectOption";
import CloseSvg from "../../../Components/Svg/closeSvg";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import { setCurrentCourse } from "../../../Store/Actions/course";
import { getCurrentQuestionMarkData } from "../../../Store/Actions/main";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import { TR_DATA_SAVED_KEY } from "../../../Constants/translationKeys";
import {
	COURSE_DASHBOARD_KEY,
	ORGANIZATION_KEY,
} from "../../../Constants/urlKeys";
import {
	BOOLEAN_KEY,
	ERROR_KEY,
	NUMBER_KEY,
	SUCCESS_KEY,
	COURSE_LEVELS,
	COMPLETION_DATA,
	RETURN_URL_KEY,
	WARNING_KEY,
} from "../../../Constants/mainKeys";
import ActionButton from "../../../Components/ActionButton/actionButton";
import SmallQuestionMark from "../../../Components/SmallQuestionMark/smallQuestionMark";
import HelpService from "../../../Services/helpService";
import RichTextEditor from "../../../Pages/Questions/Components/RichTextEditor";
import AddFromLibraryModal from "../../../Pages/CourseTopics/Modals/addFromLibraryModal";
import AddFileLibraryModal from "../../../Pages/CourseTopics/Modals/addFileLibraryModal";
import ReactPlayer from "react-player";

class CourseForm extends Component {
	_isMounted = false;
	mainService = new MainService();
	levels = COURSE_LEVELS;

	constructor(props) {
		super(props);
		this.playerRef = React.createRef();
		this.video_link = React.createRef();
		this.state = {
			form: {
				id: +this.props.match.params.courseId,
				name: "",
				description: null,
				image: null,
				presentationFile: null,
				period: "",
				isLifeTime: "",
				passRule:
					this.props.courseData && this.props.courseData.passRule
						? this.props.courseData.passRule
						: 0,
				toBeCompletedQuizId:
					this.props.courseData && this.props.courseData.toBeCompletedQuizId,
				toBeCompletedAssignmentId:
					this.props.courseData &&
					this.props.courseData.toBeCompletedAssignmentId,
				videoDuration: null,
				subjects:
					this.props.courseData && this.props.courseData.subjects
						? this.props.courseData.subjects
						: [],
				removedSubjects: [],
				youtubeVideo: "",
				imageFileId: null,
				videoLink: "",
			},
			courseId: +this.props.match.params.courseId || null,
			selectedCategories: [],
			firstLevelCategoryId: null,
			secondLevelCategoryId: null,
			secondLevelCategories: [],
			thirdLevelCategoryId: null,
			thirdLevelCategories: [],
			courseData: this.props.courseData,
			isInvalidSubmit: false,
			selectedDivision: null,
			// uploadedImage: null,
			// uploadedVideo: null,
			spinnerId: uuid(),
			nameLength: 200,
			translationService: null,
			failedFields: [],
			languages: [],
			courseCategories: [],
			courseQuizzes: [...this.props.courseQuizzes],
			assignmentCourse: [...this.props.assignmentCourse],
			// currentUploadedImage: null,
			// currentUploadedVideo: null,
			// receivedImage: null,
			// receivedVideo: null,
			isInvalidStartDate: false,
			isInvalidEndDate: false,
			changes: false,
			isInvalidCompletion: false,
			programs: [],
			programId: null,
			subjectsArr: [],
			subjectId: null,
			selectedSubjects: [],
			editor: "",
			ongoingVideoId: null,
			maxPeriodDays: 1000000,
			selectedCategories: [],
			courseCategories: [],
			showSelectFromFileLibraryModal: false,
			showAddNewFileLibraryModal: false,
			fileType: null,
			selectedImageFromLibrary: null,
			selectedVideoFromLibrary: null,
			courseVideo: null,
			courseImage: null,
			isYouTubeLink: false,
			programsOfLecturer: null,
			groups: [],
			mainCategoryid: null,
			isInvalidVideoLink: false,
			zoomImagePath: null,
			zoomAudioPath: null,
			zoomVideoPath: null,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this.props.location.pathname.includes(RETURN_URL_KEY)) {
			this.props.history.push(
				this.props.location.pathname.slice(
					0,
					this.props.location.pathname.length - 10,
				),
			);
		}
		// this.getProgramsOfLecturer();
		this.setTranslations();
		this.getEducationalUnitsOfLecturer();
		if (this.state.courseId) this.setCourseData();
		// window.addEventListener("message", this.handleMessage);
	}

	componentDidUpdate(prevProps, prevState) {
		this.setTranslations();
		if (this.state.courseQuizzes !== this.props.courseQuizzes) {
			this.setState({ courseQuizzes: this.props.courseQuizzes });
		}
		if (this.state.assignmentCourse !== this.props.assignmentCourse) {
			this.setState({ assignmentCourse: this.props.assignmentCourse });
		}
	}

	componentWillUnmount() {
		const { changes, translationService } = this.state;
		if (
			changes &&
			translationService &&
			window.confirm(
				`${translationService.translate("TR_CONFIRM_SAVE_CHANGES")} ?`,
			)
		) {
			this.onSubmit();
		}
		this._isMounted = false;
		// window.removeEventListener("message", this.handleMessage);
	}

	// handleMessage = event => {
	// 	const player = this.playerRef.current;

	// 	if (event.data) {
	// 		try {
	// 			const data = JSON.parse(event.data);
	// 			const ev_type = data.type;
	// 			const ev_data = data.data;

	// 			switch (ev_type) {
	// 				case "player:playComplete":
	// 					console.log("Event: playComplete");
	// 					player.contentWindow.postMessage(
	// 						JSON.stringify({
	// 							type: "player:setCurrentTime",
	// 							data: { time: 120 },
	// 						}),
	// 						"*",
	// 					);
	// 					player.contentWindow.postMessage(
	// 						JSON.stringify({ type: "player:pause", data: {} }),
	// 						"*",
	// 					);
	// 					break;

	// 				case "player:currentTime":
	// 					console.log("Event: currentTime");
	// 					if (Number(ev_data.time) > 240 && Number(ev_data.time) < 250) {
	// 						player.contentWindow.postMessage(
	// 							JSON.stringify({
	// 								type: "player:setCurrentTime",
	// 								data: { time: 120 },
	// 							}),
	// 							"*",
	// 						);
	// 						player.contentWindow.postMessage(
	// 							JSON.stringify({ type: "player:play", data: {} }),
	// 							"*",
	// 						);
	// 					}
	// 					break;

	// 				case "player:playOptionsLoaded":
	// 					console.log("Event: playOptionsLoaded");
	// 					const pg_rating__age = ev_data.pg_rating.age;
	// 					const is_licensed = ev_data.is_licensed ? "Да" : "Нет";
	// 					const duration = new Date(
	// 						0,
	// 						0,
	// 						0,
	// 						0,
	// 						0,
	// 						0,
	// 						Number(ev_data.duration),
	// 					);

	// 					// Update DOM or state with received data (example with innerHTML)
	// 					const markOptionsDiv = document.getElementById("markOptions");
	// 					markOptionsDiv.innerHTML = `
	//           Возрастное ограничение: ${pg_rating__age}<br>
	//           Лицензионный контент: ${is_licensed}<br>
	//           Длительность: ${duration.toLocaleTimeString()}<br>
	//           <img src="${ev_data.thumbnail_url}" alt="${ev_data.title}"><br>
	//           <img src="https://preview.rutube.ru/preview/c9e66786365d5ccd35b103d531501add.webp" alt="${
	// 							ev_data.title
	// 						}"><br>
	//           ${ev_data.title}
	//         `;
	// 					break;

	// 				default:
	// 					console.log("Unknown event type:", ev_type);
	// 					break;
	// 			}
	// 		} catch (err) {
	// 			console.error("Error parsing or handling message:", err);
	// 		}
	// 	}
	// };

	getEducationalUnitsOfLecturer = (educationalUnitId = null) => {
		const spinnerId = uuid();
		this.props.addPartialViewSpinner(spinnerId);
		ApiService.getEducationalUnitsOfLecturer(educationalUnitId)
			.then(response => {
				if (response && response.data) {
					if (response.data.length) {
						this.setState({
							groups: response.data,
						});
						if (!this.state.form.id) {
							this.selecteDivision(response.data[0], false);
						}
					} else {
						educationalUnitId &&
							AlertService.alert(
								WARNING_KEY,
								this.translationService.translate(
									"TR_CURRENT_DIVISION_HASNO_CHILD_DIVISION",
								),
							);
					}
				}
				this.props.removePartialViewSpinner(spinnerId);
			})
			.catch(error => this.getFail(error, spinnerId));
	};
	getProgramsOfLecturer = () => {
		const spinnerId = uuid();
		this.props.addPartialViewSpinner(spinnerId);
		ApiService.getProgramsOfLecturer()
			.then(response => {
				response &&
					response.data &&
					this.setState({
						programsOfLecturer: response.data,
					});
				this.props.removePartialViewSpinner(spinnerId);
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this._isMounted &&
				this.setState({
					translationService: new TranslationService(this.props.translations),
				});
		}
	};

	setCourseData = () => {
		const courseData = { ...this.state.courseData };
		const subjects = courseData.subjects ? courseData.subjects : [];
		const selectedCategories = [];
		this.setState(prevState => ({
			...prevState,

			courseImage: courseData.imagePath,
			courseVideo: courseData.presentationFilePath,

			selectedCategories,
			form: {
				...prevState.form,
				name: courseData.name || "",
				description: courseData.description ? courseData.description : "",
				period: courseData.period || "",
				isLifeTime: courseData.isLifeTime || false,
				subjects,
				videoLink: courseData.videoLink || "",
			},
		}));
	};

	onChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		const failedFields = this.removeFailedFields(event.target.name);
		this.setState(prevState => ({
			...prevState,
			failedFields,
			form: {
				...prevState.form,
				isInvalidSubmit: false,
				[event.target.name]: event.target.value,
			},
		}));
	};

	onEditorChange = event => {
		const newRichText = event.editor.getData();
		this.setState(prevState => ({
			...prevState,
			form: {
				...prevState.form,
				description: newRichText,
			},
		}));
	};

	onNumberChange = event => {
		const failedFields = this.removeFailedFields(event.target.name);
		if (+event.target.value.charAt(0) === 0) {
			event.target.value = event.target.value.substring(1);
		}
		if (
			event.target.value.includes("e") ||
			event.target.value.includes(".") ||
			+event.target.value > this.state.maxPeriodDays
		) {
			return false;
		}
		if (
			event.target.value === "" ||
			(typeof +event.target.value === NUMBER_KEY &&
				Number(event.target.value) >= 0 &&
				event.target.name !== "discount")
		) {
			this.setState(prevState => ({
				...prevState,
				failedFields,
				form: { ...prevState.form, [event.target.name]: +event.target.value },
			}));
		}
	};

	onReactSelectChange = (selectedItem, fieldName) => {
		const { courseQuizzes, assignmentCourse } = this.state;
		if (selectedItem) {
			const failedFields = this.removeFailedFields(fieldName);
			if (fieldName === "toBeCompletedQuizId") {
				this.setState(prevState => ({
					...prevState,
					changes: true,
					failedFields,
					isInvalidCompletion: false,
					form: {
						...prevState.form,
						toBeCompletedAssignmentId: "",
						[fieldName]: selectedItem.value,
					},
				}));
			} else if (fieldName === "toBeCompletedAssignmentId") {
				this.setState(prevState => ({
					...prevState,
					changes: true,
					failedFields,
					isInvalidCompletion: false,
					form: {
						...prevState.form,
						toBeCompletedQuizId: "",
						[fieldName]: selectedItem.value,
					},
				}));
			} else {
				this.setState(prevState => ({
					...prevState,
					changes: true,
					failedFields,
					isInvalidCompletion: false,
					form: {
						...prevState.form,
						[fieldName]: selectedItem.value,
					},
				}));
			}
		}
		if (
			fieldName === "passRule" &&
			selectedItem.value === 1 &&
			(!courseQuizzes || !courseQuizzes.length)
		) {
			this.setState({ isInvalidCompletion: true });
		}
		if (
			fieldName === "passRule" &&
			selectedItem.value === 3 &&
			(!assignmentCourse || !assignmentCourse.length)
		) {
			this.setState({ isInvalidCompletion: true });
		}
		if (
			fieldName === "passRule" &&
			+selectedItem.value === 2 &&
			!this.state.selectedVideoFromLibrary &&
			!this.state.courseImage
		) {
			//Pass video
			this.setState({ isInvalidCompletion: true, isDisabledAddButton: true });
		}
		if (
			fieldName === "passRule" &&
			+selectedItem.value === 2 &&
			(this.state.selectedVideoFromLibrary || this.state.courseVideo)
		) {
			//Pass video
			this.setState({ isInvalidCompletion: false, isDisabledAddButton: false });
		}
		if (
			courseQuizzes &&
			courseQuizzes.length &&
			fieldName === "passRule" &&
			selectedItem.value === 1
		) {
			this.setState(prevState => ({
				...prevState,
				form: {
					...prevState.form,
					toBeCompletedQuizId: courseQuizzes[0]?.id,
				},
			}));
		}
		if (
			assignmentCourse &&
			assignmentCourse.length &&
			fieldName === "passRule" &&
			selectedItem.value === 3
		) {
			this.setState(prevState => ({
				...prevState,
				form: {
					...prevState.form,
					toBeCompletedAssignmentId: assignmentCourse[0]?.id,
				},
			}));
		}
	};

	onCheckboxChange = event => {
		if (typeof event.target.checked === BOOLEAN_KEY) {
			const failedFields = this.removeFailedFields(event.target.name);
			this.setState(prevState => ({
				...prevState,
				failedFields,
				isInvalidSubmit: false,
				form: {
					...prevState.form,
					period:
						event.target.name === "isLifeTime" && event.target.checked
							? ""
							: this.state.period,
					[event.target.name]: event.target.checked,
				},
			}));
		}
	};

	onProgramChange = selectedCategory => {
		if (selectedCategory) {
			this.setState({
				changes: true,
				programId: selectedCategory.id,
				isInvalidSubmit: false,
			});
			this.getSubjectsByProgramId(selectedCategory.id);
		}
	};
	onChangeVideoLink = event => {
		const regex =
			/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?[\w\?‌​=]*)?/;
		if (event.target.value.trim().length && !regex.test(event.target.value)) {
			this.setState({ isInvalidVideoLink: true });
		} else {
			this.setState({ isInvalidVideoLink: false });
		}
		this.setState(prevState => ({
			...prevState,
			isInvalidSubmit: false,
			selectedVideoFromLibrary: null,
			form: {
				...prevState.form,
				videoFileId: null,
				[event.target.name]: event.target.value,
			},
		}));
	};

	getSubjectsByProgramId = programId => {
		this.setState({ subjectsArr: [] }, () => {
			ApiService.getSubjectsByProgramId(programId)
				.then(response => {
					this.setState({
						subjectsArr: response.data,
					});
				})
				.catch(error => this.getFail(error, null));
		});
	};

	onSubjectChange = selectedCategory => {
		if (!selectedCategory) {
			return;
		}

		const { subjects } = this.state.form;
		if (subjects.find(item => item.id === selectedCategory.value)) {
		} else {
			subjects.push({
				id: selectedCategory.id,
				name: selectedCategory.name,
			});
		}
		this.setState(prevState => ({
			...prevState,
			subjectId: selectedCategory.id,
			isInvalidSubmit: false,
			form: {
				...prevState.form,
				subjects,
			},
		}));
	};

	removeSubject = subjectId => {
		if (!subjectId) {
			return;
		}
		var { subjects } = this.state.form;
		subjects = subjects.filter(subject => subject.id !== subjectId);
		this.setState(prevState => ({
			...prevState,
			form: {
				...prevState.form,
				subjects,
			},
		}));
	};

	attachFileLibrary = file => {
		const { fileType } = this.state;
		if (!fileType || !file) {
			return false;
		}
		if (fileType === "image") {
			this.setState(prevState => ({
				...prevState,
				showAddNewFileLibraryModal: false,
				showSelectFromFileLibraryModal: false,
				selectedImageFromLibrary: file,
				isInvalidSubmit: false,
				form: {
					...prevState.form,
					imageFileId: file.id,
				},
			}));
		}
		if (fileType === "video") {
			this.setState(
				prevState => ({
					...prevState,
					showAddNewFileLibraryModal: false,
					showSelectFromFileLibraryModal: false,
					selectedVideoFromLibrary: file,
					currentUploadedVideo: file,
					courseVideo: file.filePath,
					form: {
						...prevState.form,
						videoFileId: file.id,
						videoLink: "",
					},
				}),
				() => {
					this.getVideoDuration();
				},
			);
			if (this.state.form.passRule === 2) {
				this.setState({
					isInvalidCompletion: false,
					isDisabledAddButton: false,
				});
			}
		}
	};

	getVideoDuration = () => {
		var video = document.getElementById("course-video");
		video.onloadedmetadata = function () {
			getDuration(Math.floor(this.duration));
		};
		var getDuration = duration => {
			this.setState(prevState => ({
				...prevState,
				form: {
					...prevState.form,
					videoDuration: duration,
				},
			}));
		};
	};

	scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 200);
	};

	onSubmit = event => {
		event && event.preventDefault();
		const {
			translationService,
			spinnerId,
			isInvalidStartDate,
			isInvalidEndDate,
			isInvalidCompletion,
			isInvalidVideoLink,
		} = this.state;
		const form = { ...this.state.form };
		this.setState({ changes: false });
		if (
			form.name.trim().length < 3 ||
			!form.subjects.length ||
			!(form.period || form.isLifeTime) ||
			(!form.imageFileId &&
				!this.state.courseImage &&
				!form.videoLink &&
				!form.videoFileId) ||
			isInvalidStartDate ||
			isInvalidEndDate ||
			isInvalidCompletion ||
			isInvalidVideoLink
			// || (form.passRule === 1 && !form.toBeCompletedQuizId) ||
			// (form.passRule === 2 && !this.state.selectedVideoFromLibrary && !this.state.courseVideo)
		) {
			this.setState({ isInvalidSubmit: true });
			this.scrollToInvalidFieldPosition();
		} else {
			form.name = form.name.trim();
			form.description = form.description ? form.description.trim() : "";
			// form.subjects = JSON.stringify(form.subjects);
			form.videoDuration =
				form.videoDuration && form.videoDuration ? form.videoDuration : null;

			!form.toBeCompletedQuizId && delete form.toBeCompletedQuizId;
			!form.presentationFile && delete form.presentationFile;
			!form.id && delete form.id;
			!form.removedSubjects.length && delete form.removedSubjects;

			if (form.isLifeTime) delete form.period;
			if (form.period) delete form.isLifeTime;
			if (!form.videoDuration) delete form.videoDuration;
			for (let i in form) {
				if (!form[i]) delete form[i];
			}
			const subjectIds = form.subjects.map(el => el.id);
			delete form.subjects;

			const values = { ...form, subjectIds };
			this.props.addButtonSpinner(spinnerId);
			(this.state.form.id
				? ApiService.updateCourseByOrgUser(values)
				: ApiService.createCourseByOrgUser(values)
			)
				.then(response => {
					if (response.data) {
						const data = { ...response.data };
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(TR_DATA_SAVED_KEY),
						);
						this.props.setCurrentCourse(data);
						// this.setState({ courseData: data }, () => this.setCourseData());
						this.props.history.push(
							`/${this.props.language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${data.id}`,
						);
						this.props.setKey("curriculum");
						this.props.getCourseByIdWithOrgUser(data.id);
						this.setState(prevState => ({
							...prevState,
							courseData: data,
							form: {
								...prevState.form,
								image: null,
							},
						}));
					}
					this.props.removeButtonSpinner(spinnerId);
				})
				.catch(errors => this.submitFail(spinnerId, errors));
		}
	};

	getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(this.props.location.pathname)) {
			this.props.getCurrentQuestionMarkData(
				HelpService.setPageName(this.props.location.pathname),
				key,
			);
		}
	};

	submitFail = (spinnerId, errors) => {
		this.props.removeButtonSpinner(spinnerId);
		const failedFields = errors;
		this.setState({ failedFields });
		errors &&
			AlertService.alert(
				AlertService.checkMessageType(errors.respcode) || ERROR_KEY,
				errors,
			);
	};

	getFail = (error, spinnerId) => {
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	removeFailedFields = name => {
		const failedFields = this.state.failedFields && {
			...this.state.failedFields,
		};
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFields && delete failedFields[fieldName];
		return failedFields;
	};

	setEditorRef = ed => {
		this.setState({ editor: ed });
	};

	closeModal = () => {
		this.setState(prevState => ({
			...prevState,
			showAddNewFileLibraryModal: false,
			showSelectFromFileLibraryModal: false,
		}));
	};

	// programname gets

	removeImg = () => {
		this.setState(prevState => ({
			...prevState,
			selectedImageFromLibrary: null,

			form: {
				...prevState.form,
				imageFileId: null,
			},
		}));
	};

	removeVideo = () => {
		this.setState(prevState => ({
			...prevState,
			selectedVideoFromLibrary: null,
			isYouTubeLink: false,
			isInvalidVideoLink: false,
			form: {
				...prevState.form,
				videoFileId: null,
				videoLink: "",
			},
		}));
	};
	selecteDivision = (item, ischange = true) => {
		if (ischange) {
			this.setState(prevState => ({
				...prevState,
				selectedDivision: item.id,
				subjectsArr: item.subjects,
				subjectId: null,
				form: {
					...prevState.form,
					// subjects: [],
				},
			}));
		} else {
			this.setState(prevState => ({
				...prevState,
				selectedDivision: item.id,
				subjectsArr: item.subjects,
				subjectId: null,
			}));
		}
	};

	isShowSelectFromFileLibraryModal = () => {
		this.setState({ showAddNewFileLibraryModal: true });
	};

	zoomImage = imagePath => {
		if (!imagePath) {
			return;
		}
		this.setState({ zoomImagePath: imagePath });
	};

	zoomVideo = videoPath => {
		if (!videoPath) {
			return;
		}
		this.setState({ zoomVideoPath: videoPath });
	};

	zoomAudio = audioPath => {
		if (!audioPath) {
			return;
		}
		this.setState({ zoomAudioPath: audioPath });
	};

	hideZoomImageVideo = () => {
		this.setState({
			zoomImagePath: null,
			zoomVideoPath: null,
			zoomAudioPath: null,
		});
	};

	render() {
		const {
			isInvalidSubmit,
			isInvalidVideoLink,
			spinnerId,
			nameLength,
			translationService,
			failedFields,
			subjectsArr,
			courseQuizzes,
			assignmentCourse,
			isInvalidCompletion,
			programs,
			programId,
			subjectId,
			showSelectFromFileLibraryModal,
			showAddNewFileLibraryModal,
			fileType,
			selectedImageFromLibrary,
			selectedVideoFromLibrary,
			courseVideo,
			courseImage,
			isYouTubeLink,
			groups,
			selectedCategories,
			secondLevelCategories,
			secondLevelCategoryId,
			selectedDivision,
			zoomImagePath,
			zoomVideoPath,
			zoomAudioPath,
		} = this.state;

		const {
			name,
			description,
			period,
			isLifeTime,
			passRule,
			toBeCompletedQuizId,
			toBeCompletedAssignmentId,
			subjects,
			videoLink,
		} = this.state.form;
		return translationService ? (
			groups && groups.length ? (
				<div>
					{zoomImagePath ? (
						<div
							className="zoom-image-container"
							onClick={this.hideZoomImageVideo}>
							<div className="close-svg">
								<CloseSvg />
							</div>
							<img
								src={zoomImagePath}
								alt="/"
								onClick={event => event.stopPropagation()}
							/>
						</div>
					) : null}
					{zoomVideoPath ? (
						<div
							className="zoom-image-container"
							onClick={this.hideZoomImageVideo}>
							<div className="zoom-image-block">
								<div className="close-svg">
									<CloseSvg />
								</div>
								<video
									controls={true}
									onClick={event => event.stopPropagation()}>
									<source src={zoomVideoPath} />
								</video>
							</div>
						</div>
					) : null}
					<div className="content-body pt-0 mt-4">
						<form
							onSubmit={this.onSubmit}
							onChange={() => this.setState({ changes: true })}>
							<div className="row">
								<div className="col-lg-6 col-12">
									<div className="form-group">
										<div>
											<label>{`${translationService.translate(
												"TR_PROGRAMS",
											)} *`}</label>
											{/* <SmallQuestionMark
												id="discipline"
												onMouseEnter={() =>
													this.getQuestionMarkData("discipline")
												}
											/> */}
										</div>
										<ReactSelectOption
											value={selectedDivision}
											isInvalidSubmit={isInvalidSubmit && !subjects.length}
											isSearchable={true}
											selectedValue={(() => {
												const selectedItem = {
													...groups.find(data => data.id === selectedDivision),
												};
												if (Object.keys(selectedItem).length) {
													selectedItem.label = selectedItem.name;
													selectedItem.value = selectedItem.id;
													return selectedItem;
												} else {
													return {
														value: null,
														label: translationService.translate("TR_CHOOSE"),
													};
												}
											})()}
											items={groups
												.filter(item => item.id !== +selectedDivision)
												.map(data => ({
													...data,
													label: data.name,
													value: data.id,
												}))}
											onChange={item => this.selecteDivision(item)}
										/>
									</div>
								</div>
								<div className="col-lg-6 col-12">
									<div className="form-group">
										<div>
											<label>{`${translationService.translate(
												"TR_DISCIPLINE",
											)} *`}</label>
											<SmallQuestionMark
												id="discipline"
												onMouseEnter={() =>
													this.getQuestionMarkData("discipline")
												}
											/>
										</div>

										<ReactSelectOption
											placeholder=" "
											isInvalidSubmit={
												subjects && !subjects.length ? isInvalidSubmit : null
											}
											isSearchable={true}
											items={subjectsArr.map(data => ({
												...data,
												label: data.name,
												value: data.id,
											}))}
											selectedValue={(() => {
												const selectedItem = {
													...subjectsArr.find(data => data.id === subjectId),
												};
												if (Object.keys(selectedItem).length) {
													selectedItem.label = selectedItem.name;
													selectedItem.value = selectedItem.id;
													return selectedItem;
												} else {
													return {
														value: null,
														label: translationService.translate("TR_CHOOSE"),
													};
												}
											})()}
											onChange={this.onSubjectChange}
											failedFields={failedFields}
										/>
									</div>
								</div>
								<div className="col-12 mb-2">
									{isInvalidSubmit && !subjects.length ? (
										<small className="red-color">
											{translationService.translate("TR_REQUIRED_FIELDS")}
										</small>
									) : null}
									<div className="categories-wrapper">
										{subjects &&
											subjects.map(subject => (
												<div key={subject.id} className="selected-category">
													{subject.name}
													<CloseSvg
														onClick={() => this.removeSubject(subject.id)}
													/>
												</div>
											))}
									</div>
								</div>
								<div className="col-12">
									{failedFields &&
									failedFields.hasOwnProperty("CategoryIds") ? (
										<small className="small-error-msg">
											{failedFields["CategoryIds"]}
										</small>
									) : null}
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<hr />
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<Input
										type="text"
										id="name"
										name="name"
										value={name}
										inputClassName="pr--5"
										isInvalidSubmit={
											isInvalidSubmit && name.trim().length < 3 ? true : false
										}
										isInvalidField={
											isInvalidSubmit && name.trim().length < 3 ? true : false
										}
										labelValue={`${translationService.translate(
											"TR_COURSE_TITLE",
										)} *`}
										fieldLength={nameLength}
										onChange={event => this.onChange(event, nameLength)}
										failedFields={failedFields}
									/>
									{name.length < 3 && isInvalidSubmit ? (
										<small className="red-color">
											{translationService.translate(
												"TR_AT_LASTED_THREE_CHARACTERS",
											)}
										</small>
									) : null}
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									{
										<Auxiliary>
											<div>
												<label>
													{translationService.translate("TR_DESCRIPTION")}
												</label>
												<SmallQuestionMark
													id="description"
													onMouseEnter={() =>
														this.getQuestionMarkData("description")
													}
												/>
											</div>
											<RichTextEditor
												value={description}
												config={{
													toolbar: [
														["Bold", "Italic", "Strike", "-", "RemoveFormat"],
														["Undo", "Redo"],
														[
															"NumberedList",
															"BulletedList",
															"-",
															"Outdent",
															"Indent",
															"-",
															"Blockquote",
														],
														["Styles", "Format"],
														// ['Maximize']
													],
												}}
												onEditorChange={event => this.onEditorChange(event)}
											/>
										</Auxiliary>
									}
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<hr />
								</div>
							</div>
							<div className="row">
								{!isLifeTime ? (
									<div className="col-md-6 col-12">
										<div>
											<label>
												{translationService.translate("TR_DURATION_DAYS")} *
											</label>
											<SmallQuestionMark
												id="duration_days"
												onMouseEnter={() =>
													this.getQuestionMarkData("duration_days")
												}
											/>
										</div>
										<Input
											type="number"
											id="period"
											name="period"
											min="0"
											value={period}
											onChange={this.onNumberChange}
											failedFields={failedFields}
											isInvalidSubmit={isInvalidSubmit}
										/>
									</div>
								) : null}
								<div className="col-12 d-flex align-items-center">
									<InputCheckBox
										id="isLifeTime"
										name="isLifeTime"
										checked={isLifeTime}
										labelValue={translationService.translate("TR_IS_LIFE_TIME")}
										onChange={this.onCheckboxChange}
										failedFields={failedFields}
									/>
								</div>
							</div>
							{
								<Auxiliary>
									<div className="row">
										<div className="col-12">
											<hr />
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label>
													{translationService.translate("TR_COMPLETION")}
												</label>
												<div className="info-title d-inline-block">
													<SmallQuestionMark
														id="completion"
														onMouseEnter={() =>
															this.getQuestionMarkData("completion")
														}
													/>
												</div>
												<ReactSelectOption
													value={passRule}
													selectedValue={{
														label:
															translationService.translate(
																COMPLETION_DATA[passRule],
															) || COMPLETION_DATA[passRule],
														value: passRule,
													}}
													items={COMPLETION_DATA.map((data, index) => ({
														label: translationService.translate(data) || data,
														value: index,
													}))}
													isInvalidField={
														(isInvalidCompletion &&
														passRule === 1 &&
														!courseQuizzes?.length
															? true
															: false) ||
														(isInvalidCompletion &&
														passRule === 2 &&
														!this.state.selectedVideoFromLibrary &&
														!this.state.courseVideo
															? true
															: false) ||
														(isInvalidCompletion &&
														passRule === 3 &&
														!assignmentCourse?.length
															? true
															: false)
													}
													onChange={item =>
														this.onReactSelectChange(item, "passRule")
													}
												/>
											</div>
										</div>
										<div className="col-md-6 col-12">
											{passRule === 1 && courseQuizzes?.length ? (
												<div className="form-group">
													<label>{`${translationService.translate(
														"TR_CHOOSE_QUIZ",
													)} *`}</label>
													<ReactSelectOption
														value={toBeCompletedQuizId}
														isInvalidSubmit={isInvalidSubmit}
														selectedValue={(() => {
															const selectedItem = {
																...courseQuizzes.find(
																	data => data.id === toBeCompletedQuizId,
																),
															};
															if (selectedItem) {
																selectedItem.value = selectedItem.id;
																selectedItem.label = selectedItem.name;
															}
															return selectedItem;
														})()}
														items={courseQuizzes.map(data => ({
															...data,
															label: data.name,
															value: data.id,
														}))}
														onChange={item =>
															this.onReactSelectChange(
																item,
																"toBeCompletedQuizId",
															)
														}
													/>
												</div>
											) : null}
											{passRule === 3 && assignmentCourse?.length ? (
												<div className="form-group">
													<label>
														{`${translationService.translate(
															"TR_CHOOSE_ASSIGNMENT",
														)} *`}
													</label>
													<ReactSelectOption
														value={toBeCompletedAssignmentId}
														isInvalidSubmit={isInvalidSubmit}
														selectedValue={(() => {
															const selectedItem = {
																...assignmentCourse.find(
																	data => data.id === toBeCompletedAssignmentId,
																),
															};
															if (selectedItem) {
																selectedItem.value = selectedItem.id;
																selectedItem.label = selectedItem.title;
															}
															return selectedItem;
														})()}
														items={assignmentCourse.map(data => ({
															...data,
															label: data.title,
															value: data.id,
														}))}
														onChange={item =>
															this.onReactSelectChange(
																item,
																"toBeCompletedAssignmentId",
															)
														}
													/>
												</div>
											) : null}
										</div>
									</div>
								</Auxiliary>
							}
							{passRule === 1 && !courseQuizzes?.length ? (
								<small className="red-color">
									{translationService.translate(
										"TR_YOU_DONT_HAVE_ANY_CREATED_QUIZ",
									)}
								</small>
							) : null}
							{passRule === 3 && !assignmentCourse?.length ? (
								<small className="red-color">
									{translationService.translate(
										"TR_YOU_DONT_HAVE_ANY_CREATED_ASSIGNMENT",
									)}
								</small>
							) : null}
							{passRule === 2 &&
							!this.state.selectedVideoFromLibrary &&
							!this.state.courseVideo ? (
								<small className="red-color">
									{translationService.translate(
										"TR_YOU_DONT_HAVE_UPLOADED_VIDEO",
									)}
								</small>
							) : null}
							<div className="row mt-3">
								<div className="col-lg-6 col-md-6 col-12">
									<button
										type="button"
										className={`mindalay--btn-secondary w-100 mb-2 
                  ${
										fileType === "image"
											? "active-upload-button"
											: "border-2-transparent"
									}
                  ${
										isInvalidSubmit &&
										!selectedImageFromLibrary &&
										!this.state.courseImage &&
										!videoLink.trim().length &&
										!courseVideo
											? "error-bordered-2"
											: ""
									}
                  `}
										onClick={() => this.setState({ fileType: "image" })}>
										{translationService.translate("TR_ATTACH_IMAGE")}
									</button>
									{isInvalidSubmit &&
									!selectedImageFromLibrary &&
									!this.state.courseImage &&
									!videoLink.trim().length &&
									!courseVideo ? (
										<small className="red-color">
											{translationService.translate("TR_REQUIRED_FIELD")}
										</small>
									) : null}
								</div>
								<div className="col-lg-6 col-md-6 col-12">
									<button
										type="button"
										className={`mindalay--btn-secondary w-100 mb-2 ${
											fileType === "video"
												? "active-upload-button"
												: "border-2-transparent"
										}`}
										onClick={() => this.setState({ fileType: "video" })}>
										{translationService.translate("TR_ATTACH_VIDEO")}
									</button>
								</div>
							</div>
							<Auxiliary>
								{fileType ? (
									<div
										className={`row ${
											fileType === "image"
												? "justify-content-start"
												: "justify-content-end"
										}`}>
										<div className="col-lg-6 col-md-6 col-12">
											<div>
												<button
													type="button"
													className={`mindalay--btn-default w-100 mb-2`}
													onClick={() =>
														this.setState({
															isYouTubeLink: false,
															showSelectFromFileLibraryModal: true,
														})
													}>
													{translationService.translate("TR_ADD_FROM_LIBRARY")}
												</button>
												{showSelectFromFileLibraryModal ? (
													<AddFromLibraryModal
														fileType={fileType}
														cancel={this.closeModal}
														added={this.attachFileLibrary}
														isShowAddNewFileLibraryModal={
															this.isShowSelectFromFileLibraryModal
														}
													/>
												) : null}
											</div>
											<div>
												<button
													type="button"
													className={`mindalay--btn-default w-100 mb-2`}
													onClick={() =>
														this.setState({
															isYouTubeLink: false,
															showAddNewFileLibraryModal: true,
														})
													}>
													{translationService.translate("TR_ADD_NEW_FILE")}
												</button>
												{showAddNewFileLibraryModal ? (
													<AddFileLibraryModal
														fileType={fileType}
														cancel={this.closeModal}
														added={this.attachFileLibrary}
														name={name}
													/>
												) : null}
												{!isYouTubeLink && this.state.fileType === "video" && (
													<button
														type="button"
														className={`mindalay--btn-default w-100 mb-2`}
														onClick={() => {
															this.setState(prevState => ({
																...prevState,
																isYouTubeLink: true,
															}));
															if (this.video_link.current) {
																// this.video_link.current.focus();
																setTimeout(() => {
																	this.video_link.current.focus();
																}, 300);
															}
														}}>
														{translationService.translate(
															"TR_ATTACH_VIDEO_LINK",
														)}
													</button>
												)}
												<Input
													blockClassName={`${
														isYouTubeLink && this.state.fileType === "video"
															? "d-block"
															: "d-none"
													}`}
													ref={this.video_link}
													type="text"
													id="youtube_video"
													name="videoLink"
													value={videoLink}
													isInvalidSubmit={isInvalidSubmit}
													isInvalidField={
														isInvalidSubmit && videoLink.trim().length < 3
															? true
															: false
													}
													labelValue={translationService.translate(
														"TR_YOUTUBE_VIDEO_LINK",
													)}
													onChange={this.onChangeVideoLink}
													placeholder="Ex. https://www.youtube.com/watch?"
												/>
												{isInvalidSubmit &&
												isInvalidVideoLink &&
												videoLink.trim().length ? (
													<small className="red-color">
														{translationService.translate(
															"TR_DOES_NOT_CONTAIN_YOUTUBE_LINK",
														)}
													</small>
												) : null}
											</div>
										</div>
									</div>
								) : null}

								{/* Selected image-video block */}
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-6 col-12">
										{selectedImageFromLibrary || courseImage ? (
											<div className="course-media-wrapper">
												<div className="upload-file-content">
													{selectedImageFromLibrary ? (
														<div onClick={() => this.removeImg()}>
															<CloseSvg />
														</div>
													) : null}
													<div
														className="img cursor-pointer"
														onClick={() => {
															this.zoomImage(
																selectedImageFromLibrary &&
																	selectedImageFromLibrary.filePath
																	? selectedImageFromLibrary.filePath
																	: courseImage
																	? courseImage
																	: null,
															);
														}}
														style={{
															backgroundImage: `url(${
																selectedImageFromLibrary &&
																selectedImageFromLibrary.filePath
																	? selectedImageFromLibrary.filePath
																	: courseImage
																	? courseImage
																	: null
															})`,
														}}
														alt="course-image"
													/>
												</div>
											</div>
										) : null}
									</div>

									<div className="col-lg-6 col-md-6 col-sm-6 col-12">
										{selectedVideoFromLibrary || videoLink || courseVideo ? (
											<div className="course-media-wrapper h-100 p-1">
												<div className="upload-file-content">
													{selectedVideoFromLibrary || videoLink ? (
														<div onClick={() => this.removeVideo()}>
															<CloseSvg controls={true} />
														</div>
													) : null}
													{/* {videoLink.includes("rutube") ? (
														<div id="my-player" ref={this.playerRef}>
															<iframe
																id="my-player"
																width="100%"
																height="100%"
																src="https://rutube.ru/play/embed/c9e66786365d5ccd35b103d531501add?getPlayOptions=pg_rating,is_adult,is_licensed,duration,title,thumbnail_url&skinColor=b3ff00&t=100"
																frameBorder="0"
																allow="clipboard-write"
																webkitAllowFullScreen
																mozallowfullscreen
																allowFullScreen></iframe>
														</div>
													) : videoLink ? (
														<ReactPlayer
															className="course-video-link"
															url={videoLink}
															// config={{
															// 	youtube: {
															// 		playerVars: { showinfo: 0, controls: 1 },
															// 	},
															// }}
														/>
													) : selectedVideoFromLibrary || courseVideo ? (
														<video
															id="course-video"
															src={
																selectedVideoFromLibrary &&
																selectedVideoFromLibrary.filePath
																	? selectedVideoFromLibrary.filePath
																	: courseVideo
																	? courseVideo
																	: null
															}
															alt="course-image"
															controls
														/>
													) : null} */}
													{videoLink ? (
														<ReactPlayer
															className="course-video-link"
															url={videoLink}
															// config={{
															// 	youtube: {
															// 		playerVars: { showinfo: 0, controls: 1 },
															// 	},
															// }}
														/>
													) : selectedVideoFromLibrary || courseVideo ? (
														<video
															id="course-video"
															src={
																selectedVideoFromLibrary &&
																selectedVideoFromLibrary.filePath
																	? selectedVideoFromLibrary.filePath
																	: courseVideo
																	? courseVideo
																	: null
															}
															alt="course-image"
															controls
														/>
													) : null}
												</div>
											</div>
										) : null}
									</div>
								</div>
							</Auxiliary>
							<hr />
							<div className="mt-4">
								<ActionButton
									spinnerId={spinnerId}
									type="submit"
									className="mindalay--btn-default float-right mb-4 px-5"
									name={translationService.translate("TR_SAVE")}
								/>
							</div>
						</form>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	language: state.language.language,
	courseCategories: state.course.courseCategories,
	courseData: state.course.currentCourse,
	user: state.user.user,
	currentQuestionMarkData: state.main.currentQuestionMarkData,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
	setCurrentCourse,
	getCurrentQuestionMarkData,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(CourseForm);
