import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import * as moment from "moment";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import Sections from "../CourseSections/sections";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import Carousel from "../../Components/Carousel/carousel";
import MainService from "../../Services/mainService";
import CourseBlock from "../../Components/Course/courseBlock";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import Textarea from "../../Components/Inputs/textArea";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import ReurnUrl from "./../../Services/returnUrl";
import StudentFeedback from "./Feedbacks/studentsFeedback";
import InstructorFeedback from "./Feedbacks/instructorFeedback";
import CurrentStudentFeedback from "./Feedbacks/currentStudentFeedback";
import ShowMoreText from "react-show-more-text";
import Parser from "html-react-parser";
import ShareModalComponent from "../../Components/ModalComponent/shareModalComponent";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import InfoSvg from "../../Components/Svg/infoSvg";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import {
	CHECKOUT_KEY,
	COURSES_KEY,
	LOGIN_KEY,
	PASS_COURSE_KEY,
	PROFILE_KEY,
} from "../../Constants/urlKeys";
import {
	changeShoppingCartOrWishListCount,
	changeWishListCount,
	changeShoppingCartCount,
} from "../../Store/Actions/wishListOrShoppingCart";
import TextSpinner from "./../../Components/Spinners/textSpinner";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	addModalSpinner,
	removeModalSpinner,
	addTextSpinner,
	removeTextSpinner,
} from "../../Store/Actions/spinner";
import {
	ERROR_KEY,
	NUMBER_KEY,
	SHOPPING_CART_COUNT_KEY,
	SHOPPING_CART_PRODUCTS_IDS_KEYS,
	SUCCESS_KEY,
	WISH_LIST_COUNT_KEY,
	WISH_LIST_PRODUCTS_IDS_KEYS,
	RATINGS,
	RETURN_URL_KEY,
	BOOLEAN_KEY,
} from "../../Constants/mainKeys";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1224 },
		items: 5,
		slidesToSlide: 5,
	},
	tablet: {
		breakpoint: { max: 1224, min: 968 },
		items: 4,
		slidesToSlide: 4,
	},
	smallTablet: {
		breakpoint: { max: 968, min: 725 },
		items: 3,
		slidesToSlide: 3,
	},
	mobile: {
		breakpoint: { max: 725, min: 575 },
		items: 3,
		slidesToSlide: 3,
	},
	smallMobile: {
		breakpoint: { max: 575, min: 0 },
		items: 2,
		slidesToSlide: 2,
	},
};

class CourseView extends Component {
	ratings = RATINGS;

	state = {
		form: {
			description: "",
			subject: "",
			withRefund: false,
		},
		feedBackForm: {
			rating: 0,
			feedBackDescription: "",
			entitytype: 0,
		},
		courseId: +this.props.match.params.courseId,
		courseData: null,
		deleteSectionId: null,
		selectedSectionId: null,
		sectionsViewCount: 10,
		sections: [],
		whatYouWillLearn: [],
		requirements: [],
		targetStudents: [],
		showInstructorBio: false,
		reviewCountByRating: null,
		showDescriptionShowMore: false,
		translationService: null,
		failedFields: false,
		isShowModal: false,
		subjects: [],
		defaultValue: { label: "", value: "" },
		isInvalidComplaintSubmit: false,
		isInvalidFeedBackSubmit: false,
		canRequestRefund: true,
		feedBackData: null,
		defaultFeedBackData: null,
		feedBackDataId: null,
		entityType: 0,
		spinnerId: null,
		isShowShareModal: false,
		feedBackDataForStudent: [],
		feedBackDataForInstructor: [],
		reviews: [],
		isWishListButtonSpinner: null,
		isShoppingCartButtonSpinner: null,
		descriptionMaxLength: 2000,
	};

	componentDidMount() {
		const { user, language } = this.props;
		this.setTranslations();
		this.getCourseById(this.state.courseId);
		if (user && this.props.location.pathname.includes(RETURN_URL_KEY)) {
			this.props.history.push(
				`/${language}/${COURSES_KEY}/${this.props.match.params.courseId}`,
			);
		}
		if (!user && this.props.location.pathname.includes(RETURN_URL_KEY)) {
			ReurnUrl.setUrl(this.props.location.pathname).then(() => {
				this.props.history.push(`/${language}/${LOGIN_KEY}`);
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		this.setTranslations();
		if (prevProps.match.params.courseId !== this.props.match.params.courseId) {
			this.setState({ courseId: +this.props.match.params.courseId }, () => {
				this.getCourseById(this.state.courseId);
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	getCourseById = courseId => {
		const spinnerId = uuid();
		this.props.addPageSpinner(spinnerId);
		const { user } = this.props;
		ApiService.getPublishedCourseById(courseId)
			.then(response => {
				var courseData = { ...response.data.course };
				var feedBackData = response.data.review ? response.data.review : null;
				var { canRequestRefund } = response.data;
				courseData.reviews = courseData.reviews
					? courseData.reviews.reverse()
					: [];
				const feedBackDataForStudent = courseData.reviews;
				const feedBackDataForInstructor = courseData.reviews;
				this.setState({
					feedBackData: null,
					feedBackDataId: null,
					defaultFeedBackData: null,
					feedBackDataForStudent: feedBackDataForStudent,
					feedBackDataForInstructor: feedBackDataForInstructor,
				});
				if (
					courseData &&
					user &&
					user.id !== courseData.instructor?.userId &&
					feedBackData
				) {
					this.setState({
						feedBackData,
						feedBackDataId: feedBackData.id,
						defaultFeedBackData: feedBackData,
					});
				}
				this.props.removePageSpinner(spinnerId);
				const reviewCountByRating = {};
				if (courseData.approvedTotalReviews && courseData.reviewCountByRating) {
					for (let i in courseData.reviewCountByRating) {
						reviewCountByRating[i] = Math.round(
							(courseData.reviewCountByRating[i] * 100) /
								courseData.approvedTotalReviews,
						);
					}
				}
				if (!user) {
					var wishListProductsByIds = localStorage.getItem(
						WISH_LIST_PRODUCTS_IDS_KEYS,
					);
					if (JSON.parse(wishListProductsByIds) && courseData.otherCourses) {
						JSON.parse(wishListProductsByIds).forEach(productId => {
							courseData.otherCourses.find(course => {
								if (course.id === productId) {
									course.isInWishlist = true;
								}
							});
							if (courseData.id === productId) {
								courseData.isInWishlist = true;
							}
						});
					}
					var shoppingCartProductsByIds = localStorage.getItem(
						SHOPPING_CART_PRODUCTS_IDS_KEYS,
					);
					if (
						shoppingCartProductsByIds &&
						JSON.parse(shoppingCartProductsByIds)
					) {
						var shoppingCartProductsByIdsCount = JSON.parse(
							shoppingCartProductsByIds,
						);
						this.props.changeShoppingCartCount(
							shoppingCartProductsByIdsCount.length,
						);
					}
					if (
						shoppingCartProductsByIds &&
						JSON.parse(shoppingCartProductsByIds).length &&
						courseData.otherCourses
					) {
						JSON.parse(shoppingCartProductsByIds).forEach(productId => {
							if (productId === courseId) {
								courseData.isInShoppingCart = true;
							}
						});
					}
				}
				this.setState({
					courseData,
					courseId,
					reviewCountByRating,
					canRequestRefund,
					sections: courseData.sections,
					whatYouWillLearn: courseData.whatYouWillLearn
						? Object.values(JSON.parse(courseData.whatYouWillLearn))
						: [],
					requirements: courseData.requirements
						? Object.values(JSON.parse(courseData.requirements))
						: [],
					targetStudents: courseData.targetStudents
						? Object.values(JSON.parse(courseData.targetStudents))
						: [],
				});
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	updateCourses = id => {
		const { courseData } = this.state;
		courseData.otherCourses.forEach(element => {
			if (element.id === id) {
				if (element.isInWishlist === true) {
					element.isInWishlist = false;
				} else {
					element.isInWishlist = true;
				}
			}
		});
		this.setState({ courseData });
	};

	removeButtonSpinners = () => {
		this.setState({
			isWishListButtonSpinner: false,
			isShoppingCartButtonSpinner: false,
		});
	};

	addCourseToWishListOrCartWithSpinner = (bool, isShowAlert) => {
		if (!bool) {
			this.setState(
				{
					isWishListButtonSpinner: true,
				},
				() => {
					this.addCourseToWishListOrCart(bool);
				},
			);
		} else {
			this.setState(
				{
					isShoppingCartButtonSpinner: true,
				},
				() => {
					this.addCourseToWishListOrCart(bool, isShowAlert);
				},
			);
		}
	};

	addCourseToWishListOrCart = async (bool, isShowAlert, cb, buyNow) => {
		const { courseId, courseData, translationService } = this.state;
		const { user } = this.props;
		if (user) {
			ApiService.addCourseToWishListOrCart(courseId, bool, buyNow)
				.then(response => {
					if (response) {
						if (!bool) {
							AlertService.alert(
								SUCCESS_KEY,
								`${courseData.name} ${translationService.translate(
									"TR_ADDED_WISH_LIST_MSG",
								)}`,
							);
							courseData.isInWishlist = true;
							if (
								response.data &&
								typeof response.data?.wishListCount === NUMBER_KEY &&
								typeof response.data?.shoppingCartCount === NUMBER_KEY
							) {
								localStorage.setItem(
									WISH_LIST_COUNT_KEY,
									response.data.wishListCount,
								);
								localStorage.setItem(
									SHOPPING_CART_COUNT_KEY,
									response.data.shoppingCartCount,
								);
								this.props.changeShoppingCartOrWishListCount(
									response.data.wishListCount,
									response.data.shoppingCartCount,
								);
							}
							this.setState({ courseData, isWishListButtonSpinner: false });
						} else {
							isShowAlert &&
								AlertService.alert(
									SUCCESS_KEY,
									`${courseData.name} ${translationService.translate(
										"TR_ADDED_SHOPPING_CART_MSG",
									)}`,
								);
							courseData.isInShoppingCart = true;
							if (
								response.data &&
								typeof response.data?.wishListCount === NUMBER_KEY &&
								typeof response.data?.shoppingCartCount === NUMBER_KEY
							) {
								localStorage.setItem(
									WISH_LIST_COUNT_KEY,
									response.data.wishListCount,
								);
								localStorage.setItem(
									SHOPPING_CART_COUNT_KEY,
									response.data.shoppingCartCount,
								);
								this.props.changeShoppingCartOrWishListCount(
									response.data.wishListCount,
									response.data.shoppingCartCount,
								);
							}
							this.setState(
								{ courseData, isShoppingCartButtonSpinner: false },
								() => {
									if (cb) {
										return cb();
									}
								},
							);
						}
					}
				})
				.catch(error => this.getFail(error, null));
		} else {
			if (bool) {
				////Shopping cart add unauth users
				var shoppingCartProductsByIds = localStorage.getItem(
					SHOPPING_CART_PRODUCTS_IDS_KEYS,
				);
				if (!shoppingCartProductsByIds) {
					shoppingCartProductsByIds = [courseId];
					this.props.changeShoppingCartCount(shoppingCartProductsByIds.length);
					localStorage.setItem(
						SHOPPING_CART_PRODUCTS_IDS_KEYS,
						JSON.stringify(shoppingCartProductsByIds),
					);
					AlertService.alert(
						SUCCESS_KEY,
						`${courseData.name} ${translationService.translate(
							"TR_ADDED_WISH_LIST_MSG",
						)}`,
					);
					courseData.isInShoppingCart = true;
					this.setState({ courseData, isShoppingCartButtonSpinner: false });
				} else {
					shoppingCartProductsByIds = JSON.parse(shoppingCartProductsByIds);
					shoppingCartProductsByIds.push(courseId);
					this.props.changeShoppingCartCount(shoppingCartProductsByIds.length);
					localStorage.setItem(
						SHOPPING_CART_PRODUCTS_IDS_KEYS,
						JSON.stringify(shoppingCartProductsByIds),
					);
					AlertService.alert(
						SUCCESS_KEY,
						`${courseData.name} ${translationService.translate(
							"TR_ADDED_SHOPPING_CART_MSG",
						)}`,
					);
					courseData.isInShoppingCart = true;
					this.setState({ courseData, isShoppingCartButtonSpinner: false });
				}
			} else {
				//Wish list add unauth users
				var wishListProductsByIds = localStorage.getItem(
					WISH_LIST_PRODUCTS_IDS_KEYS,
				);
				if (!wishListProductsByIds) {
					wishListProductsByIds = [courseId];
					this.props.changeWishListCount(wishListProductsByIds.length);
					localStorage.setItem(
						WISH_LIST_PRODUCTS_IDS_KEYS,
						JSON.stringify(wishListProductsByIds),
					);
					AlertService.alert(
						SUCCESS_KEY,
						`${courseData.name} ${translationService.translate(
							"TR_ADDED_WISH_LIST_MSG",
						)}`,
					);
					courseData.isInWishlist = true;
					this.setState({ courseData, isWishListButtonSpinner: false });
				} else {
					wishListProductsByIds = JSON.parse(wishListProductsByIds);
					wishListProductsByIds.push(courseId);
					this.props.changeWishListCount(wishListProductsByIds.length);
					localStorage.setItem(
						WISH_LIST_PRODUCTS_IDS_KEYS,
						JSON.stringify(wishListProductsByIds),
					);
					AlertService.alert(
						SUCCESS_KEY,
						`${courseData.name} ${translationService.translate(
							"TR_ADDED_WISH_LIST_MSG",
						)}`,
					);
					courseData.isInWishlist = true;
					this.setState({ courseData, isWishListButtonSpinner: false });
				}
			}
		}
	};

	getFormatedStringFromDays = numberOfDays => {
		const { translationService } = this.state;
		var years = Math.floor(numberOfDays / 365);
		var months = Math.floor((numberOfDays % 365) / 30);
		var days = Math.floor((numberOfDays % 365) % 30);
		var yearsDisplay =
			years > 0
				? years +
				  (years == 1
						? ` ${translationService.translate("TR_YEAR")}, `
						: ` ${translationService.translate("TR_YEARS")}, `)
				: "";
		var monthsDisplay =
			months > 0
				? months +
				  (months == 1
						? ` ${translationService.translate("TR_MONTH")}, `
						: ` ${translationService.translate("TR_MONTS")}, `)
				: "";
		var daysDisplay =
			days > 0
				? days +
				  (days == 1
						? ` ${translationService.translate("TR_DAY")}, `
						: ` ${translationService.translate("TR_DAYS")}`)
				: "";
		return yearsDisplay + monthsDisplay + daysDisplay;
	};

	removeCourseFromShoppingCart = course => {
		const { user } = this.props;
		const { translationService, courseData } = this.state;
		this.setState(
			{
				isShoppingCartButtonSpinner: true,
			},
			() => {
				if (user) {
					ApiService.removeCourseFromShoppingCart(course.id)
						.then(response => {
							if (response) {
								AlertService.alert(
									SUCCESS_KEY,
									`${course.name} ${translationService.translate(
										"TR_REMOVE_SHOPPING_CART_MSG",
									)}`,
								);
								courseData.isInShoppingCart = false;
								if (
									response.data &&
									typeof response.data?.wishListCount === NUMBER_KEY &&
									typeof response.data?.shoppingCartCount === NUMBER_KEY
								) {
									localStorage.setItem(
										WISH_LIST_COUNT_KEY,
										response.data.wishListCount,
									);
									localStorage.setItem(
										SHOPPING_CART_COUNT_KEY,
										response.data.shoppingCartCount,
									);
									this.props.changeShoppingCartOrWishListCount(
										response.data.wishListCount,
										response.data.shoppingCartCount,
									);
								}
								this.setState({
									courseData,
									isShoppingCartButtonSpinner: false,
								});
							}
						})
						.catch(error => this.getFail(error, null));
				} else {
					var shoppingCartProductsByIds = localStorage.getItem(
						SHOPPING_CART_PRODUCTS_IDS_KEYS,
					);
					if (shoppingCartProductsByIds) {
						shoppingCartProductsByIds = JSON.parse(shoppingCartProductsByIds);
						var updateingShoppingCartProductsByIds =
							shoppingCartProductsByIds.filter(
								productId => productId !== course.id,
							);
						this.props.changeShoppingCartCount(
							updateingShoppingCartProductsByIds.length,
						);
						localStorage.setItem(
							SHOPPING_CART_PRODUCTS_IDS_KEYS,
							JSON.stringify(updateingShoppingCartProductsByIds),
						);
						AlertService.alert(
							SUCCESS_KEY,
							`${course.name} ${translationService.translate(
								"TR_REMOVE_SHOPPING_CART_MSG",
							)}`,
						);
						courseData.isInShoppingCart = false;
						this.setState({ courseData, isShoppingCartButtonSpinner: false });
					}
				}
			},
		);
	};

	addCourseToShoppingCartAndRedirectToCheckout = courseData => {
		const { language, user } = this.props;
		if (user) {
			!courseData.isInShoppingCart &&
				this.addCourseToWishListOrCart(true, false);
			this.props.history.push(`/${language}/${CHECKOUT_KEY}/${courseData.id}`);
		} else {
			ReurnUrl.setUrl(this.props.location.pathname).then(() => {
				this.props.history.push(`/${language}/${LOGIN_KEY}`);
			});
		}
	};

	attachStudentToCourse = courseData => {
		const spinnerId = uuid();
		const { language, user } = this.props;
		const { translationService } = this.state;
		if (user) {
			this.props.addPageSpinner(spinnerId);
			ApiService.attachStudentToCourse({ courseId: courseData.id })
				.then(response => {
					if (response && response.data) {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate("TR_GET_FREE_COURSE_MSG"),
						);
						ApiService.removeAllCoursesFromShoppingCart([courseData.id])
							.then(res => {
								this.props.changeShoppingCartOrWishListCount(
									res.data.wishListCount,
									res.data.shoppingCartCount,
								);
								this.props.removePageSpinner(spinnerId);
								this.getCourseById(courseData.id);
							})
							.catch(error => this.getFail(error, spinnerId));
					}
				})
				.catch(error => this.getFail(error, spinnerId));
		} else {
			ReurnUrl.setUrl(this.props.location.pathname).then(() => {
				this.props.history.push(`/${language}/${LOGIN_KEY}`);
			});
		}
	};

	convertSecondsToHoursAndMinutes = d => {
		const { translationService } = this.state;
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);
		var hDisplay =
			h > 0
				? h + `${translationService.translate("TR_SHORT_HOUR")}` + `${"\u00A0"}`
				: "";
		var mDisplay =
			m > 0
				? m +
				  `${translationService.translate("TR_SHORT_MINUTE")}` +
				  `${"\u00A0"}`
				: "";
		var sDisplay =
			s > 0
				? s +
				  `${translationService.translate("TR_SHORT_SECOND")}` +
				  `${"\u00A0"}`
				: "";
		return hDisplay + mDisplay + sDisplay;
	};

	removeCourseFromWishList = course => {
		const { user } = this.props;
		var { translationService, courseData } = this.state;
		this.setState(
			{
				isWishListButtonSpinner: true,
			},
			() => {
				if (user) {
					ApiService.removeCourseFromWishList(course.id)
						.then(response => {
							response &&
								AlertService.alert(
									SUCCESS_KEY,
									`${course.name} ${translationService.translate(
										"TR_REMOVE_WISH_LIST_MSG",
									)}`,
								);
							course.isInWishlist = false;
							if (
								response.data &&
								typeof response.data?.wishListCount === NUMBER_KEY &&
								typeof response.data?.shoppingCartCount === NUMBER_KEY
							) {
								localStorage.setItem(
									WISH_LIST_COUNT_KEY,
									response.data.wishListCount,
								);
								localStorage.setItem(
									SHOPPING_CART_COUNT_KEY,
									response.data.shoppingCartCount,
								);
								this.props.changeShoppingCartOrWishListCount(
									response.data.wishListCount,
									response.data.shoppingCartCount,
								);
							}
							this.setState({
								courseData: course,
								isWishListButtonSpinner: false,
							});
						})
						.catch(error => this.getFail(error, null));
				} else {
					var wishListProductsByIds = localStorage.getItem(
						WISH_LIST_PRODUCTS_IDS_KEYS,
					);
					if (wishListProductsByIds) {
						wishListProductsByIds = JSON.parse(wishListProductsByIds);
						var updateingWishListProductsByIds = wishListProductsByIds.filter(
							productId => productId !== course.id,
						);
						this.props.changeWishListCount(
							updateingWishListProductsByIds.length,
						);
						localStorage.setItem(
							WISH_LIST_PRODUCTS_IDS_KEYS,
							JSON.stringify(updateingWishListProductsByIds),
						);
						courseData.isInWishlist = false;
						AlertService.alert(
							SUCCESS_KEY,
							`${course.name} ${translationService.translate(
								"TR_REMOVE_WISH_LIST_MSG",
							)}`,
						);
						this.setState({ courseData, isWishListButtonSpinner: false });
					}
				}
			},
		);
	};

	onDescriptionChange = (event, maxLength = null) => {
		const failedFields = this.removeFailedFields(event.target.name);
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		this.setState(prevState => ({
			...prevState,
			failedFields,
			form: {
				...prevState.form,
				[event.target.name]: event.target.value,
			},
		}));
	};

	onChangeShowMoreDescFeedback = name => {
		var feedBackData = { ...this.state.feedBackData };
		feedBackData[name] = !feedBackData[name];
		this.setState({ feedBackData });
	};

	onSelectChange = (selectedCategory, name) => {
		if (!selectedCategory || !name) return;
		this.setState(prevState => ({
			...prevState,
			form: {
				...prevState.form,
				[name]: selectedCategory.name,
			},
		}));
	};

	openModal = () => {
		const spinnerId = uuid();
		this.props.addModalSpinner(spinnerId);
		ApiService.getCourseTicketSubjects()
			.then(response => {
				this.props.removeModalSpinner(spinnerId);
				const data = { ...response.data };
				data?.libraryData &&
					this.setState({ subjects: [...data.libraryData], isShowModal: true });
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	makeAcomplaint = courseId => {
		const { translationService } = this.state;
		const spinnerId = uuid();
		const form = { ...this.state.form };
		if (!form.description || !form.description.trim() || !form.subject) {
			this.setState({ isInvalidComplaintSubmit: true });
		} else {
			this.props.addModalSpinner(spinnerId);
			const data = {
				entityType: 0,
				entityId: courseId,
				description: form.description.trim(),
				subject: form.subject,
				withRefund: form.withRefund,
			};
			ApiService.makeAcomplaint(data)
				.then(response => {
					if (response && response.data) {
						this.setState({ canRequestRefund: response.data.canRequestRefund });
					}
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_TICKET_SUCCESSFULLY_SENT"),
					);
					this.props.removeModalSpinner(spinnerId);
					this.closeModal();
				})
				.catch(error => this.getFail(error, spinnerId));
		}
	};

	closeModal = () => {
		this.setState(prevState => ({
			...prevState,
			isShowModal: false,
			isInvalidComplaintSubmit: false,
			form: {
				...prevState.form,
				description: "",
				subject: "",
				withRefund: false,
			},
		}));
	};

	replyFeedback = (event, feedBackData) => {
		const { entityType, courseId } = this.state;
		event.preventDefault();
		let message = null;
		for (const i in this.state) {
			if (i.includes(feedBackData.id)) {
				message = this.state[i];
			}
		}
		if (!message || (message && !message.trim())) {
			return;
		} else {
			const spinnerId = `spinnerId-${feedBackData.id}-${uuid()}`;
			this.setState({ spinnerId }, () => {
				const data = {
					id: feedBackData.id,
					replyText: message,
					entityType,
				};
				ApiService.sendFeedBackFromConsultant(data)
					.then(() => {
						this.setState({ spinnerId: null });
						this.getCourseById(courseId);
					})
					.catch(error => this.getFail(error));
			});
		}
	};

	onCheckboxChange = event => {
		this.setState(prevState => ({
			...prevState,
			form: {
				...prevState.form,
				[event.target.name]: event.target.checked,
			},
		}));
	};

	onMessageChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	annulFeedbackData = () => {
		this.setState({ feedBackData: null });
	};

	cancelUpdateFeedback = () => {
		this.setState({ feedBackData: this.state.defaultFeedBackData });
	};

	redirectToPublicPriofile = instructorId => {
		const { language } = this.props;
		this.props.history.push(`/${language}/${PROFILE_KEY}/${instructorId}`);
	};

	removeFailedFields = name => {
		const failedFields = this.state.failedFields && {
			...this.state.failedFields,
		};
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFields && delete failedFields[fieldName];
		return failedFields;
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		error && error.respcode === 1 && window.history.back();
		spinnerId && this.props.removePageSpinner(spinnerId);
		spinnerId && this.props.removeModalSpinner(spinnerId);
		this.setState({ spinnerId: null });
		this.closeModal();
		this.removeButtonSpinners();
	};

	render() {
		const { language, user } = this.props;
		const { subject, description, withRefund } = this.state.form;
		const {
			translationService,
			courseData,
			whatYouWillLearn,
			requirements,
			targetStudents,
			sections,
			reviewCountByRating,
			courseId,
			sectionsViewCount,
			failedFields,
			isShowModal,
			subjects,
			defaultValue,
			isInvalidComplaintSubmit,
			canRequestRefund,
			isInvalidFeedBackSubmit,
			feedBackData,
			feedBackDataId,
			feedBackDataForInstructor,
			spinnerId,
			feedBackDataForStudent,
			isShowShareModal,
			isWishListButtonSpinner,
			isShoppingCartButtonSpinner,
			descriptionMaxLength,
		} = this.state;

		var leftRewiew =
			user &&
			courseData &&
			courseData.reviews &&
			courseData.reviews.some(review => +review.reviewerUserId === +user.id);
		var topicsCount = 0;
		if (courseData && courseData.sections) {
			courseData.sections.forEach(section => {
				if (section.topics) {
					topicsCount = topicsCount + section.topics.length;
				}
			});
		}

		return translationService && courseData ? (
			<section className="m-section">
				<Helmet>
					<title>{`${courseData.name} | Mindalay`}</title>
				</Helmet>
				{isShowModal ? (
					<ModalComponent
						title={translationService.translate("TR_OPEN_A_TICKET")}
						isLarge={true}
						cancel={this.closeModal}
						contentClassName="library-modal"
						aria-labelledby="example-modal-sizes-title-lg"
						actionButtonTitle={translationService.translate("TR_SUBMIT")}
						onSubmit={() => this.makeAcomplaint(courseData.id)}>
						<form>
							<div className="row p-3">
								<div className="col-12">
									<div className="row">
										<div className="col-12">
											<Textarea
												rows="4"
												id="description"
												name="description"
												value={description}
												max={descriptionMaxLength}
												isInvalidField={
													isInvalidComplaintSubmit && !description.trim()
														? true
														: false
												}
												labelValue={`${translationService.translate(
													"TR_DESCRIPTION",
												)}*`}
												onChange={event =>
													this.onDescriptionChange(event, descriptionMaxLength)
												}
												isInvalidSubmit={isInvalidComplaintSubmit}
												failedFields={failedFields}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-12">
											<label>{`${translationService.translate(
												"TR_COMPLAINT_TYPE",
											)} *`}</label>
											<ReactSelectOption
												defaultValue={defaultValue}
												value={subject ? true : false}
												selectedValue={(() => {
													const selectedValue = {
														...subjects.find(data => data.name === subject),
													};
													if (selectedValue) {
														selectedValue.label = selectedValue.displayName;
														selectedValue.value = selectedValue.name;
													}
													return selectedValue;
												})()}
												isInvalidSubmit={isInvalidComplaintSubmit}
												items={subjects.map(data => ({
													...data,
													label: data.displayName,
													value: data.name,
												}))}
												onChange={item => this.onSelectChange(item, "subject")}
											/>
										</div>
									</div>
									{!courseData.cost ||
									courseData.discount === 100 ? null : canRequestRefund ? (
										<div className="row">
											<div className="col-12 mt-3">
												<InputCheckBox
													id="withRefundet"
													name="withRefund"
													checked={withRefund}
													labelValue={translationService.translate(
														"TR_REQUEST_A_REFUND",
													)}
													onChange={this.onCheckboxChange}
													failedFields={failedFields}
												/>
											</div>
										</div>
									) : (
										<div className="row">
											<div className="col-12 mt-3">
												<p>
													{translationService.translate(
														"TR_REFUND_REQUEST_SENT",
													)}
												</p>
											</div>
										</div>
									)}
								</div>
							</div>
						</form>
					</ModalComponent>
				) : null}
				{isShowShareModal ? (
					<ShareModalComponent
						title={translationService.translate("TR_SHARE_THIS_COURSE")}
						url={`https://mindalay.com${this.props.location.pathname}`}
						cancel={() => this.setState({ isShowShareModal: false })}
					/>
				) : null}
				<div className="container course-info-container">
					<div className="row ">
						<div className="col-12">
							{courseData.categories?.length ? (
								<Auxiliary>
									<nav aria-label="breadcrumb">
										<ol className="breadcrumb">
											{courseData.categories.map(category => (
												<li key={category.id} className="breadcrumb-item">
													<Link to="#" className="cursor-default">
														{category.name}
													</Link>
												</li>
											))}
										</ol>
									</nav>
									<hr />
								</Auxiliary>
							) : null}
						</div>
						<div className="col-xl-3 col-lg-4 col-md-4 col-12">
							<div className="background box-shadow-4 course-main-info">
								{courseData.presentationFilePath ? (
									<div className="course-media-wrapper">
										<div className="course-video-wrapper">
											<video
												className="m-0"
												controls
												controlsList="nodownload nopictonpicture"
												disablePictureInPicture={true}
												width={180}>
												<source
													src={courseData.presentationFilePath}
													type="video/mp4"
												/>
											</video>
										</div>
									</div>
								) : (
									<div
										className="course-image mb-2"
										style={{
											backgroundImage: `url(${courseData.imagePath})`,
										}}></div>
								)}
								<div className="course-card-body p-3">
									<div className="course-price-wrapper">
										{
											<p className="course-new-price">
												{courseData.discount === 100
													? `${translationService.translate("TR_FREE")}`
													: courseData.cost}
											</p>
										}
										{courseData.discount ? (
											<div className="d-flex align-items-center justify-content-between">
												{courseData.oldCost || courseData.oldCost === 0 ? (
													<p className="course-old-price">
														{courseData.oldCost}
													</p>
												) : null}
												<p className="discount">
													{courseData.discount}%{" "}
													<span>{translationService.translate("TR_OFF")}</span>
												</p>
											</div>
										) : null}
									</div>
									{user && courseData.isBlocked && courseData.blockMessage ? (
										<div>
											<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
												<InfoSvg />
												{courseData.blockMessage}
											</p>
										</div>
									) : null}
									{user && courseData.isBlocked ? null : (
										<div className="mt-2">
											<span className="small-text">
												{translationService.translate(
													"TR_MONEY_BACK_GUARANTEE",
												)}
											</span>
											{courseData.isBelongToInstructor ? null : !courseData.isBelongToStudent ? (
												<Auxiliary>
													{
														<button
															type="button"
															className="mindalay--btn-dark mb-2 w-100"
															disabled={isShoppingCartButtonSpinner}
															onClick={
																!courseData.isInShoppingCart
																	? () =>
																			this.addCourseToWishListOrCartWithSpinner(
																				true,
																				true,
																			)
																	: () =>
																			this.removeCourseFromShoppingCart(
																				courseData,
																			)
															}>
															{isShoppingCartButtonSpinner ? (
																<TextSpinner
																	className="mr-3 ml-0"
																	spinner={true}
																	height={30}
																	width={30}
																/>
															) : !courseData.isInShoppingCart ? (
																translationService.translate("TR_ADD_TO_CART")
															) : (
																translationService.translate(
																	"TR_REMOVE_FROM_CART",
																)
															)}
														</button>
													}
													<button
														type="button"
														className="mindalay--btn-secondary w-100"
														onClick={
															courseData.cost && courseData.discount !== 100
																? () =>
																		this.addCourseToShoppingCartAndRedirectToCheckout(
																			courseData,
																		)
																: () => this.attachStudentToCourse(courseData)
														}>
														{courseData.cost && courseData.discount !== 100
															? translationService.translate("TR_BUY_NOW")
															: translationService.translate("TR_GET_COURSE")}
													</button>
												</Auxiliary>
											) : (
												<Auxiliary>
													{courseData.isTimeExpired ? (
														<button
															type="button"
															className="mindalay--btn-default w-100"
															onClick={() =>
																this.addCourseToShoppingCartAndRedirectToCheckout(
																	courseData,
																)
															}>
															{translationService.translate("TR_BUY_AGAIN")}
														</button>
													) : null}
													{courseData.isStarted &&
													!courseData.isTimeExpired &&
													!courseData.isBlocked ? (
														<Link
															to={`/${language}/${PASS_COURSE_KEY}/${courseId}/${courseData.studentCourseId}`}
															className="mindalay--btn-secondary-outline mindalay--btn-link mb-2 w-100">
															{courseData.completionPercentage === 100
																? translationService.translate("TR_VIEW_COURSE")
																: translationService.translate(
																		"TR_CONTINUE_LEARNING",
																  )}
														</Link>
													) : null}
													{!courseData.isStarted &&
													!courseData.isTimeExpired ? (
														<Link
															to={`/${language}/${PASS_COURSE_KEY}/${courseId}/${courseData.studentCourseId}`}
															className="mindalay--btn-secondary-outline mindalay--btn-link mb-2 w-100">
															{translationService.translate(
																"TR_START_LEARNING",
															)}
														</Link>
													) : null}
													<button
														type="button"
														className="mindalay--btn-dark mr-1 w-100 my-2"
														onClick={() => this.openModal(courseId)}>
														{translationService.translate("TR_OPEN_A_TICKET")}
													</button>
												</Auxiliary>
											)}
										</div>
									)}
									<hr />
									<div className="course-property mt-2">
										<p>
											{translationService.translate("TR_THIS_COURSE_INCLUDES")}:
										</p>
										<ul className="list-group mt-1">
											{courseData.isLifeTime ? (
												<li
													className="list-group-item"
													title={translationService.translate("TR_PERIOD")}>
													<i className="fas fa-infinity mr-1"></i>{" "}
													{translationService.translate(
														"TR_FILL_LIFETIME_ACCESS",
													)}
												</li>
											) : (
												<li
													className="list-group-item"
													title={translationService.translate("TR_PERIOD")}>
													<i className="fas fa-infinity mr-1"></i>{" "}
													{this.getFormatedStringFromDays(courseData.period)}
												</li>
											)}
											{courseData.discount &&
											courseData.discountEndDate &&
											!courseData.isBelongToUser ? (
												<li className="list-group-item">
													<i className="fas fa-percent"></i>{" "}
													{translationService.translate("TR_UP_TO")}
													<span className="ml-1">
														{moment(
															MainService.convertUTCDateToLocalDate(
																new Date(courseData.discountEndDate),
															),
														).format("YYYY-MM-DD HH:mm")}
													</span>
												</li>
											) : null}
											{courseData.allVideoDuration ? (
												<li className="list-group-item">
													<i className="far fa-image"></i>
													{this.convertSecondsToHoursAndMinutes(
														courseData.allVideoDuration,
													)}
												</li>
											) : null}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-9 col-lg-8 col-md-8 col-12 mt-md-0 mt-4">
							<div className="course-info-wrapper">
								<div className="course-general-info">
									<h1 className="mb-2">{courseData.name}</h1>
									<div className="course-info-review mt-2 d-flex align-items-center">
										{courseData.isBestSeller ? (
											<p className="course-status course-bestseller">
												{translationService.translate("TR_BESTSELLER")}
											</p>
										) : null}
										{courseData.isNew ? (
											<span className="course-status course-new">
												{translationService.translate("TR_NEW")}
											</span>
										) : null}
										<p className="ml-2 secondary-color">{courseData.rating}</p>
										<div className="rating">
											<div
												style={{
													width: `${Math.round(courseData.rating * 100) / 5}%`,
												}}></div>
										</div>
										{courseData.approvedTotalReviews ||
										courseData.approvedTotalReviews === 0 ? (
											<p>
												<span>(</span>
												{courseData.approvedTotalReviews}
												<span>)</span>
											</p>
										) : null}
										<p>|</p>
										{courseData.studentCount ||
										courseData.studentCount === 0 ? (
											<p>
												{courseData.studentCount}
												<span className="ml-1">
													{translationService.translate("TR_STUDENTS")}
												</span>
											</p>
										) : null}
									</div>
									<div className="d-flex mt-2 icon-wrapper align-items-center">
										{courseData.modificationDate ? (
											<Auxiliary>
												<p>
													<i className="fas fa-sync mr-1"></i>
													{translationService.translate("TR_LAST_UPDATED")}
													<span className="ml-1">
														{moment(
															MainService.convertUTCDateToLocalDate(
																new Date(courseData.modificationDate),
															),
														).format("YYYY-MM-DD")}
													</span>
												</p>
												<p> | </p>
											</Auxiliary>
										) : null}
										<p className="uppercase">
											<i className="fas fa-globe mr-1"></i>
											{courseData.languageName ? courseData.languageName : ""}
										</p>
									</div>
									{user && courseData.isBlocked ? null : (
										<div className="d-flex align-items-center mt-3">
											{!courseData.isBelongToStudent ? (
												courseData.isBelongToStudent ||
												courseData.isBelongToInstructor ? null : (
													<button
														type="button"
														className="mindalay--btn-default mindalay--btn-small mr-2 d-flex align-items-center"
														disabled={isWishListButtonSpinner}
														onClick={
															!courseData.isInWishlist
																? () =>
																		this.addCourseToWishListOrCartWithSpinner(
																			false,
																		)
																: () =>
																		this.removeCourseFromWishList(courseData)
														}>
														{!isWishListButtonSpinner ? (
															courseData.isInWishlist ? (
																<i className="fa fa-heart"></i>
															) : (
																<i className="far fa-heart"></i>
															)
														) : (
															<TextSpinner
																className="mr-3 ml-0"
																spinner={true}
																color={"#ffffff"}
																height={16}
																width={16}
															/>
														)}
														{translationService.translate("TR_WISH_LIST")}
													</button>
												)
											) : null}
											<button
												type="button"
												className="mindalay--btn-dark mindalay--btn-small"
												onClick={() =>
													this.setState({ isShowShareModal: true })
												}>
												<i className="fas fa-share-alt"></i>
												{translationService.translate("TR_SHARE")}
											</button>
										</div>
									)}
								</div>
								{whatYouWillLearn?.length ? (
									<Auxiliary>
										<hr />
										<div className="course-what-we-learn p-3 light-blue-background">
											<div className="row">
												<div className="col-12">
													<h3 className="title">
														{translationService.translate(
															"TR_WHAT_YOU_WILL_LEARN_DESC",
														)}
													</h3>
												</div>
												<div className="col-12">
													<div className="row">
														{whatYouWillLearn.map((description, index) => {
															return description ? (
																<div
																	key={index}
																	className="col-lg-6 col-md-6 col-6">
																	<div className="d-flex chech-icon-list">
																		<i className="fas fa-check"></i>
																		<p>{description}</p>
																	</div>
																</div>
															) : null;
														})}
													</div>
												</div>
											</div>
										</div>
									</Auxiliary>
								) : null}
								{requirements?.length ? (
									<Auxiliary>
										<hr />
										<div className="course-requirements">
											<h3 className="title">
												{translationService.translate("TR_REQUIREMENTS")}
											</h3>
											<ul className="mindalay--order-list">
												{requirements.map((requirement, index) => {
													return (
														<li key={index}>
															<p>{requirement}</p>
														</li>
													);
												})}
											</ul>
										</div>
									</Auxiliary>
								) : null}
								{targetStudents?.length ? (
									<Auxiliary>
										<hr />
										<div className="course-target-student">
											<h3 className="title">
												{translationService.translate(
													"TR_WHO_THIS_COURSE_IS_FOR",
												)}
											</h3>
											<ul className="mindalay--order-list">
												{targetStudents.map((targetStudent, index) => {
													return (
														<li key={index}>
															<p>{targetStudent}</p>
														</li>
													);
												})}
											</ul>
										</div>
									</Auxiliary>
								) : null}
								{courseData.description ? (
									<Auxiliary>
										<hr />
										<div className="course-view-description">
											<h3 className="title">
												{translationService.translate("TR_DESCRIPTION")}
											</h3>
											<div>
												<ShowMoreText
													lines={2}
													more={translationService.translate("TR_SHOW_MORE")}
													less={translationService.translate("TR_LESS")}
													className="content-css"
													anchorClass="my-anchor-css-class"
													expanded={false}
													truncatedEndingComponent={"... "}>
													<span className="course-description-block">
														<ParserComponent text={courseData.description} />
													</span>
												</ShowMoreText>
											</div>
										</div>
									</Auxiliary>
								) : null}
								{sections?.length ? (
									<Auxiliary>
										<hr />
										<div className="course-content">
											<h3 className="title">
												{translationService.translate("TR_CURICULUMN")}
											</h3>
											{
												<div>
													<ul className="pl-0 d-flex">
														{courseData.sections.length ? (
															courseData.sections.length <= 1 ? (
																<li>{`${
																	courseData.sections.length
																} ${translationService.translate(
																	"TR_SECTION",
																)}`}</li>
															) : (
																<li>{`${
																	courseData.sections.length
																} ${translationService.translate(
																	"TR_SECTIONS",
																)}`}</li>
															)
														) : null}
														,{"\u00A0"}
														{topicsCount ? (
															topicsCount <= 1 ? (
																<li>{`${topicsCount} ${translationService.translate(
																	"TR_TOPIC",
																)}`}</li>
															) : (
																<li>{`${topicsCount} ${translationService.translate(
																	"TR_TOPICS",
																)}`}</li>
															)
														) : null}
														,{"\u00A0"}
														<li>
															{this.convertSecondsToHoursAndMinutes(
																courseData.allVideoDuration,
															)}
															{translationService.translate("TR_TOTAL_LENGTH")}
														</li>
													</ul>
												</div>
											}
											{
												<Sections
													sections={sections}
													isEditMode={false}
													isBelongToUser={courseData.isBelongToUser}
													sectionsViewCount={sectionsViewCount}
													topicClassName="cursor-default"
												/>
											}
											{sectionsViewCount < sections.length ? (
												<button
													type="button"
													className="mindalay--btn-default w-100 mt-4"
													onClick={() =>
														this.setState({
															sectionsViewCount: sectionsViewCount + 10,
														})
													}>
													{translationService.translate("TR_SHOW_MORE")}
												</button>
											) : null}
										</div>
									</Auxiliary>
								) : null}
								<hr />
								<div className="course-instructor">
									<h3 className="title">{courseData.creatorUserName}</h3>
									<div className="instructor-profile d-flex">
										<div
											style={{
												backgroundImage: `url(${courseData.instructorImagePath})`,
											}}
											alt="instructor image"
											className="instructor-profile-image cursor-pointer"
											onClick={() =>
												this.redirectToPublicPriofile(
													courseData.instructor.userId,
												)
											}
										/>
										<div className="instructor-profile-info ml-2">
											<p>
												<i className="far fa-star"></i>
												<span>{courseData.instructor.rating}</span>{" "}
												{translationService.translate("TR_INSTRUCTOR_RATING")}
											</p>
											<p>
												<i className="far fa-check-circle"></i>
												<span>{courseData.instructor.totalReviews}</span>{" "}
												{translationService.translate("TR_REVIEWS")}
											</p>
											<p>
												<i className="fas fa-users"></i>
												<span>{courseData.instructor.studentCount}</span>{" "}
												{translationService.translate("TR_STUDENTS")}
											</p>
											<p>
												<i className="far fa-play-circle"></i>
												<span>{courseData.instructor.courseCount}</span>{" "}
												{translationService.translate("TR_COURSES")}
											</p>
										</div>
									</div>
									{courseData.instructor?.bio ? (
										<div className=" mt-3">
											<ShowMoreText
												lines={3}
												more={translationService.translate("TR_SHOW_MORE")}
												less={translationService.translate("TR_LESS")}
												className="content-css"
												anchorClass="my-anchor-css-class"
												expanded={false}
												truncatedEndingComponent={"... "}>
												<p>{courseData.instructor?.bio}</p>
											</ShowMoreText>
										</div>
									) : null}
								</div>
								<hr />
								<div className="course-rate">
									<h3 className="title">
										{translationService.translate("TR_STUDENTS_FEEDBACK")}
									</h3>
									<div
										className={`${
											courseData.rating > 0 ? "d-md-flex d-block" : ""
										} `}>
										<div className="course-rating">
											{courseData.rating > 0 ? (
												<Auxiliary>
													<strong className="text-center">
														{courseData.rating}
													</strong>
													<p className="text-center">
														{translationService.translate("TR_COURSE_RATING")}
													</p>
												</Auxiliary>
											) : (
												<p className="text-center">
													{translationService.translate("TR_NOR_RATED")}
												</p>
											)}
										</div>
										{reviewCountByRating ? (
											<div className="progress-wrapper flex-1">
												<div className="d-flex">
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: `${reviewCountByRating[5] || 0}%`,
																ariaValuenow: `${reviewCountByRating[5] || 0}`,
																ariaValuemin: "0",
																ariaValuemax: "100",
															}}></div>
													</div>
													<div className="rating-wrapper d-flex align-items-center">
														<div className="rating">
															<div style={{ width: `100%` }}></div>
														</div>
														<span>{reviewCountByRating[5] || 0}%</span>
													</div>
												</div>
												<div className="d-flex">
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: `${reviewCountByRating[4] || 0}%`,
																ariaValuenow: `${reviewCountByRating[4] || 0}`,
																ariaValuemin: "0",
																ariaValuemax: "100",
															}}></div>
													</div>
													<div className="rating-wrapper d-flex align-items-center">
														<div className="rating">
															<div style={{ width: `80%` }}></div>
														</div>
														<span>{reviewCountByRating[4] || 0}%</span>
													</div>
												</div>
												<div className="d-flex">
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: `${reviewCountByRating[3] || 0}%`,
																ariaValuenow: `${reviewCountByRating[3] || 0}`,
																ariaValuemin: "0",
																ariaValuemax: "100",
															}}></div>
													</div>
													<div className="rating-wrapper d-flex align-items-center">
														<div className="rating">
															<div style={{ width: `60%` }}></div>
														</div>
														<span>{reviewCountByRating[3] || 0}%</span>
													</div>
												</div>
												<div className="d-flex">
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: `${reviewCountByRating[2] || 0}%`,
																ariaValuenow: `${reviewCountByRating[2] || 0}`,
																ariaValuemin: "0",
																ariaValuemax: "100",
															}}></div>
													</div>
													<div className="rating-wrapper d-flex align-items-center">
														<div className="rating">
															<div style={{ width: `40%` }}></div>
														</div>
														<span>{reviewCountByRating[2] || 0}%</span>
													</div>
												</div>
												<div className="d-flex">
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: `${reviewCountByRating[1] || 0}%`,
																ariaValuenow: `${reviewCountByRating[1] || 0}`,
																ariaValuemin: "0",
																ariaValuemax: "100",
															}}></div>
													</div>
													<div className="rating-wrapper d-flex align-items-center">
														<div className="rating">
															<div style={{ width: `20%` }}></div>
														</div>
														<span>{reviewCountByRating[1] || 0}%</span>
													</div>
												</div>
											</div>
										) : null}
									</div>
								</div>
								{
									//Current student part
									!leftRewiew &&
									user &&
									user.id !== courseData.instructor.userId &&
									courseData.isBelongToStudent ? (
										<CurrentStudentFeedback
											isInvalidFeedBackSubmit={isInvalidFeedBackSubmit}
											feedBackData={feedBackData}
											feedBackDataId={feedBackDataId}
											courseData={courseData}
											getCourseById={this.getCourseById}
											onChangeShowMoreDescFeedback={
												this.onChangeShowMoreDescFeedback
											}
											annulFeedbackData={this.annulFeedbackData}
											cancelUpdateFeedback={this.cancelUpdateFeedback}
										/>
									) : null
								}
								{
									//Students part
									(user && user.id !== courseData.instructor?.userId) ||
									!user ? (
										<StudentFeedback feedBackData={feedBackDataForStudent} />
									) : null
								}
								{
									//Instructor part (feedback)
									user &&
									user.id === courseData.instructor.userId &&
									!courseData.isBelongToStudent ? (
										<InstructorFeedback
											feedBackData={feedBackDataForInstructor}
											spinnerId={spinnerId}
											onMessageChange={this.onMessageChange}
											replyFeedback={this.replyFeedback}
										/>
									) : null
								}
								{courseData.otherCourses?.length ? (
									<Auxiliary>
										<hr />
										<div className="course-info-10">
											<h3 className="title">
												{translationService.translate("TR_MORE_COURSES_BY")}
												<Link
													to={`/${language}/${PROFILE_KEY}/${courseData.instructor.userId}`}
													className="ml-2 blue-color">
													{courseData.creatorUserName}
												</Link>
											</h3>
											<div className="course-slider-wrapper">
												<div className="course-slider course-carousel">
													<Carousel responsive={responsive} arrows={true}>
														{courseData.otherCourses.map(course => (
															<CourseBlock
																key={course.id}
																course={course}
																creatorId={courseData?.instructor?.userId}
																updateCourses={this.updateCourses}
															/>
														))}
													</Carousel>
												</div>
											</div>
										</div>
									</Auxiliary>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</section>
		) : null;
	}
}
const mapStateToProps = state => ({
	buttonSpinners: state.spinner.buttonSpinners,
	language: state.language.language,
	user: state.user.user,
	translations: state.translation.translations,
	textSpinners: state.spinner.textSpinners,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	changeShoppingCartOrWishListCount,
	addModalSpinner,
	removeModalSpinner,
	changeWishListCount,
	changeShoppingCartCount,
	addTextSpinner,
	removeTextSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(CourseView);
