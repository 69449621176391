import React, { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import {
	MESSAGE_NOTIFICATION_KEY,
	NOTIFY_CONNECT_KEY,
	NUMBER_KEY,
	SEND_CALENDAR_AND_WEBINAR_ID_KEY,
	SEND_PAYMENT_APPROVAL_KEY,
	UNREAD_MESSAGES_COUNT_KEY,
	UNREAD_NOTIFICATION_COUNT_KEY,
	USER_KEY,
	WEBINAR_DATA_KEY,
} from "./Constants/mainKeys";
import {
	setGlobalConnection,
	setNotificationCount,
	setUnreadMessagesCount,
	showOrHideWebinarScreenNotification,
} from "./Store/Actions/signalR";
import { withRouter } from "react-router-dom";
import MessageRingtone from "./assets/audio/iphone_msg_sent.mp3";
import {
	setIsShowB2BWebinarNotification,
	setWebinarData,
} from "./Store/Actions/main";
import GamificationModalComponent from "./Components/ModalComponent/gamificationModalComponent";

const Listeners = props => {
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.user);
	const [localConnection, setLocalConnection] = useState(null);
	const [gamificationData, setGamificationData] = useState(null);

	useEffect(() => {
		if (
			localStorage.getItem(USER_KEY) &&
			(!localConnection ||
				(localConnection && localConnection.state !== "Connected")) &&
			!props.location.pathname.includes("under-maintenance")
		) {
			setTimeout(() => connectToSignalR(), 3000);
		}
	}, [
		localConnection && localConnection.state,
		localStorage.getItem(USER_KEY),
	]);

	useEffect(() => {
		if (localConnection) {
			let notificationCount = 0;
			let unreadMessgesCount = 0;

			localConnection.on(UNREAD_NOTIFICATION_COUNT_KEY, count => {
				if (typeof count === NUMBER_KEY && +count !== +notificationCount) {
					+count !== 0 && audioStart();
					notificationCount = count;
					dispatch(setNotificationCount(count));
				}
			});

			localConnection.on(UNREAD_MESSAGES_COUNT_KEY, count => {
				if (typeof count === NUMBER_KEY && +count !== +unreadMessgesCount) {
					+count !== 0 && audioStart();

					unreadMessgesCount = count;
					dispatch(setUnreadMessagesCount(count));
				}
			});

			localConnection.on("GamifyMessage", data => {
				setGamificationData(data);
			});

			localConnection.on(SEND_PAYMENT_APPROVAL_KEY, data => {});

			localConnection.on("OngoingWebinars", bool => {
				dispatch(setIsShowB2BWebinarNotification(bool));
			});

			if (!JSON.parse(localStorage.getItem(WEBINAR_DATA_KEY))) {
				localConnection.on(
					SEND_CALENDAR_AND_WEBINAR_ID_KEY,
					(webinarCalendarId, webinarId, webinarType, lecturerUserId) => {
						if (
							webinarCalendarId &&
							webinarId &&
							typeof webinarType === NUMBER_KEY &&
							lecturerUserId
						) {
							const webinarData = {
								webinarCalendarId,
								webinarId,
								webinarType,
								lecturerUserId,
							};
							dispatch(setWebinarData(webinarData));
							dispatch(showOrHideWebinarScreenNotification(true));
							if (webinarType === 0) {
								setTimeout(() => {
									goToWebinar();
								}, 1000);
							}
						}
					},
				);
			}
		}
	}, [localConnection]);

	const connectToSignalR = () => {
		if (localConnection && localConnection.state === "Connected") {
			return false;
		}
		const connection = new signalR.HubConnectionBuilder()
			.withUrl(`${process.env.REACT_APP_API_URL}/${MESSAGE_NOTIFICATION_KEY}`)
			// .withAutomaticReconnect()
			// .configureLogging(signalR.LogLevel.Trace)
			.configureLogging(signalR.LogLevel.None)
			.build();
		connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;

		connection
			.start()
			.then(() => {
				connection.invoke(NOTIFY_CONNECT_KEY, user.id).catch(err => {
					console.log(err);
				});
			})

			.catch(error => {
				console.log(error);
			});
		if (connection) {
			setLocalConnection(connection);
			dispatch(setGlobalConnection(connection));
		}

		connection.onclose(() => {
			reconnection(connection);
		});
	};

	const reconnection = connection => {
		if (
			connection &&
			connection.state !== "Connected" &&
			localStorage.getItem(USER_KEY)
		) {
			connection
				.start()
				.then(() => {
					connection.invoke(NOTIFY_CONNECT_KEY, user.id).catch(err => {
						console.log(err);
					});
				})
				.catch(error => {
					console.log(error);
					setTimeout(() => {
						reconnection(connection);
					}, 3000);
				});
		}
	};

	const audioStart = () => {
		var audioElement = document.getElementById("audio");
		audioElement.src = MessageRingtone;
		audioElement.autoplay = true;
	};

	const goToWebinar = () => {
		window.location.href = process.env.REACT_APP_WEBINAR_SCREEN_URL_KEY;
		// window.open(`${API_URL_KEY}/${language}${process.env.REACT_APP_WEBINAR_SCREEN_URL_KEY}`, '_blank')
	};

	if (gamificationData) {
		return (
			<GamificationModalComponent
				gamificationData={gamificationData}
				cancel={() => setGamificationData(null)}
			/>
		);
	}

	return <audio id="audio" className="d-none" />;
};

export default withRouter(Listeners);
