import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "./../../../Services/translationService";
import Collapse from "react-bootstrap/Collapse";
import uuid from "react-uuid";
import ApiService from "./../../../Services/apiService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
	addButtonSpinner,
	removeButtonSpinner,
} from "./../../../Store/Actions/spinner";
import AlertService from "../../../Services/alertService";
import {
	AUDIO_KEY,
	ERROR_KEY,
	IMAGE_KEY,
	VIDEO_KEY,
} from "../../../Constants/mainKeys";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import FileSvg from "./../../../Components/Svg/fileSvg";
import CloseSvg from "./../../../Components/Svg/closeSvg";
import InfoSvg from "./../../../Components/Svg/infoSvg";
import ActionButton from "../../../Components/ActionButton/actionButton";
import { SUCCESS_KEY } from "./../../../Constants/mainKeys";
import NoDataImage from "./../../../assets/images/illustrations/nodata.svg";
import videoSvg from "./../../../assets/icons/video.svg";
import audioSvg from "./../../../assets/icons/audio.svg";
import { getCurrentQuestionMarkData } from "../../../Store/Actions/main";
import Parser from "html-react-parser";
import SmallQuestionMark from "../../../Components/SmallQuestionMark/smallQuestionMark";
import HelpService from "../../../Services/helpService";

const buttonSpinnerId = uuid();

const AttachFileToStudent = props => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const currentQuestionMarkData = useSelector(
		state => state.main.currentQuestionMarkData,
	);
	const [translationService, setTranslationService] = useState(null);
	const [fileLibrary, setFileLibrary] = useState([]);
	const [students, setStudents] = useState([]);
	const [folderId, setFolderId] = useState(null);
	const [groupId, setGroupId] = useState(null);

	const [save, setSave] = useState(true);
	const [studentsbyfiles, setStudentsbyfiles] = useState(true);
	const [filesbystudents, setFilesbystudents] = useState(false);

	const [zoomImagePath, setZoomImagePath] = useState(null);
	const [zoomVideoPath, setZoomVideoPath] = useState(null);
	const [zoomAudioPath, setZoomAudioPath] = useState(null);

	const [fileIds, setFileIds] = useState([]);
	const [studentIds, setStudentIds] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getFileLibraryAndStudents();
		window.addEventListener("keydown", zoomOut);
		return () => {
			window.removeEventListener("keydown", zoomOut);
		};
	}, []);

	const getFileLibraryAndStudents = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getFileLibraryAndStudents()
			.then(response => {
				if (response && response.data && response.data.fileResponses) {
					setFileLibrary([...response.data.fileResponses]);
					if (
						response.data.fileResponses[0] &&
						response.data.fileResponses[0].id
					) {
						setFolderId(response.data.fileResponses[0].id);
					}
				}
				if (response && response.data && response.data.studentResponses) {
					setStudents([...response.data.studentResponses]);
					if (
						response.data.studentResponses[0] &&
						response.data.studentResponses[0].id
					) {
						setGroupId(response.data.studentResponses[0].id);
					}
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const onFileCheckboxChange = (event, folderId, fileId) => {
		var fileLibraryCopy = [...fileLibrary];
		var fileIdsCopy = [...fileIds];
		var studentIdsCopy = [];
		fileLibraryCopy.forEach(fileLibraryCopyItem => {
			if (fileLibraryCopyItem.id === folderId) {
				fileLibraryCopyItem.files.forEach(file => {
					if (file.id === fileId) {
						file.checked = event.target.checked;
					}
				});
			}
		});
		setFileLibrary(fileLibraryCopy);

		if (
			(save && studentsbyfiles && event.target.checked) ||
			(save && !studentsbyfiles && event.target.checked) ||
			(!save && studentsbyfiles && event.target.checked) ||
			(!save && !studentsbyfiles && !event.target.checked)
		) {
			fileIdsCopy.push(fileId);
		}

		if (
			(save && studentsbyfiles && !event.target.checked) ||
			(save && !studentsbyfiles && !event.target.checked) ||
			(!save && studentsbyfiles && !event.target.checked)
		) {
			const index = fileIdsCopy.indexOf(fileId);
			if (index > -1) {
				fileIdsCopy.splice(index, 1);
			}
		}

		setFileIds(fileIdsCopy);
		studentsbyfiles &&
			ApiService.getStudentsByFileIds(fileIdsCopy)
				.then(response => {
					setStudentIds(studentIdsCopy);
					if (response.data) {
						let newids = [];
						response.data.forEach(item => {
							if (item.students) {
								item.students.forEach(el => {
									if (el.checked) {
										newids.push(el.id);
									}
								});
							}
						});
						setStudentIds(values => [...values, ...newids]);
						const studentsCopy = [...response.data];
						setStudents(studentsCopy);
					} else {
						setStudents([]);
					}
				})
				.catch(error => getFail(error));
	};

	const onStudentCheckboxChange = (event, groupId, studentId) => {
		var studentsCopy = [...students];
		var studentIdsCopy = [...studentIds];
		var fileIdsCopy = [];
		studentsCopy.forEach(studentsCopyItem => {
			if (studentsCopyItem.id === groupId) {
				studentsCopyItem.students.forEach(student => {
					if (student.id === studentId) {
						student.checked = event.target.checked;
					}
				});
			}
		});
		setStudents(studentsCopy);

		if (
			(save && studentsbyfiles && event.target.checked) ||
			(save && !studentsbyfiles && event.target.checked) ||
			(!save && studentsbyfiles && !event.target.checked) ||
			(!save && !studentsbyfiles && event.target.checked)
		) {
			studentIdsCopy.push(studentId);
		}

		if (
			(save && studentsbyfiles && !event.target.checked) ||
			(save && !studentsbyfiles && !event.target.checked) ||
			(!save && studentsbyfiles && event.target.checked) ||
			(!save && !studentsbyfiles && !event.target.checked) //
		) {
			const index = studentIdsCopy.indexOf(studentId);
			if (index > -1) {
				studentIdsCopy.splice(index, 1);
			}
		}

		setStudentIds(studentIdsCopy);

		filesbystudents &&
			ApiService.getFilesbyStudents(studentIdsCopy)
				.then(response => {
					setFileIds(fileIdsCopy);
					if (response.data) {
						let newids = [];
						response.data.forEach(item => {
							if (item.files) {
								item.files.forEach(el => {
									if (el.checked) {
										newids.push(el.id);
									}
								});
							}
						});
						setFileIds(values => [...values, ...newids]);
						const fileLibraryCopy = [...response.data];
						setFileLibrary(fileLibraryCopy);
					} else {
						setFileLibrary([]);
					}
				})
				.catch(error => getFail(error));
	};

	const resetSettings = () => {
		const fileLibraryCopy = [...fileLibrary];
		const studentsCopy = [...students];
		fileLibraryCopy.forEach(folder => {
			folder.files &&
				folder.files.forEach(file => {
					file.checked = false;
				});
		});
		studentsCopy.forEach(group => {
			group.students &&
				group.students.forEach(student => {
					student.checked = false;
				});
		});
		setStudents(studentsCopy);
		setFileLibrary(fileLibraryCopy);
		setStudentIds([]);
		setFileIds([]);
	};

	const attach = () => {
		const data = {
			save,
			fileIds,
			studentIds,
		};
		dispatch(addButtonSpinner(buttonSpinnerId));
		ApiService.getFilesToStudents(data)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				dispatch(removeButtonSpinner(buttonSpinnerId));
			})
			.catch(error => getFail(error, buttonSpinnerId));
	};

	const zoomImage = imagePath => {
		if (!imagePath) {
			return;
		}
		setZoomImagePath(imagePath);
	};

	const zoomVideo = videoPath => {
		if (!videoPath) {
			return;
		}
		setZoomVideoPath(videoPath);
	};

	const zoomAudio = audioPath => {
		if (!audioPath) {
			return;
		}
		setZoomAudioPath(audioPath);
	};

	const zoomOut = event => {
		if (event.key === "Escape") {
			hideZoomImageVideo();
		}
	};

	const hideZoomImageVideo = () => {
		setZoomImagePath(null);
		setZoomVideoPath(null);
		setZoomAudioPath(null);
	};

	const onSaveChange = event => {
		setSave(event.target.checked);
		resetSettings();
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
	};

	const getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(props.location.pathname)) {
			dispatch(
				getCurrentQuestionMarkData(
					HelpService.setPageName(props.location.pathname),
					key,
				),
			);
		}
	};

	return translationService ? (
		<div className="general-messages">
			{zoomImagePath ? (
				<div className="zoom-image-container" onClick={hideZoomImageVideo}>
					<div className="close-svg">
						<CloseSvg />
					</div>
					<img
						src={zoomImagePath}
						alt="/"
						onClick={event => event.stopPropagation()}
					/>
				</div>
			) : null}
			{zoomVideoPath ? (
				<div className="zoom-image-container" onClick={hideZoomImageVideo}>
					<div className="zoom-image-block">
						<div className="close-svg">
							<CloseSvg />
						</div>
						<video
							controls
							controlsList="nodownload nopictonpicture"
							disablePictureInPicture={true}
							onClick={event => event.stopPropagation()}>
							<source src={zoomVideoPath} type="video/mp4" />
						</video>
					</div>
				</div>
			) : null}
			{zoomAudioPath ? (
				<div className="zoom-image-container" onClick={hideZoomImageVideo}>
					<div className="zoom-image-block">
						<div className="close-svg">
							<CloseSvg />
						</div>
						<audio src={zoomAudioPath} controls />
					</div>
				</div>
			) : null}
			<div className="row mt-3">
				<div className="col-12">
					<p>
						{translationService.translate("TR_ATTACH_FILES_TO_STUDENTS_INFO")}
					</p>
					<hr />
					<div className="row">
						<div className="col-12 col-sm-6">
							<strong className="title d-block mb-1">
								{translationService.translate("TR_ACTION")}
							</strong>
							<div className="custom-control custom-switch">
								<div className="d-flex align-items-center">
									<input
										type="checkbox"
										id="save"
										className="custom-control-input"
										checked={save}
										onChange={onSaveChange}
									/>
									<label htmlFor="save" className="custom-control-label">
										{translationService.translate("TR_ATTACH")}
									</label>
									<SmallQuestionMark
										id="attach"
										onMouseEnter={() => getQuestionMarkData("attach")}
									/>
								</div>
							</div>
						</div>
						<div className="col-12 col-sm-6">
							<div>
								<strong className="title d-block mb-1">
									{translationService.translate("TR_VIEW_MODE")}
								</strong>
								<div className="custom-control custom-switch">
									<div className="d-flex align-items-center">
										<input
											type="checkbox"
											id="studentsbyfiles"
											className="custom-control-input"
											checked={studentsbyfiles}
											onChange={event => {
												setStudentsbyfiles(event.target.checked);
												setFilesbystudents(!event.target.checked);
												resetSettings();
											}}
										/>
										<label
											htmlFor="studentsbyfiles"
											className="custom-control-label">
											{translationService.translate("TR_STUDENTS_BY_FILES")}
										</label>
										<SmallQuestionMark
											id="students_by_files"
											onMouseEnter={() =>
												getQuestionMarkData("students_by_files")
											}
										/>
									</div>
								</div>
								<div className="custom-control custom-switch mt-4">
									<div className="d-flex align-items-center">
										<input
											type="checkbox"
											id="filesbystudents"
											className="custom-control-input"
											checked={filesbystudents}
											onChange={event => {
												setFilesbystudents(event.target.checked);
												setStudentsbyfiles(!event.target.checked);
												resetSettings();
											}}
										/>
										<label
											htmlFor="filesbystudents"
											className="custom-control-label">
											{translationService.translate("TR_FILES_BY_STUDENTS")}
										</label>
										<SmallQuestionMark
											id="files_by_student"
											onMouseEnter={() =>
												getQuestionMarkData("files_by_student")
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-12">
							<div className="row">
								<div className="col-lg-6 col-md-12 col-12 messages-users-container mb-4">
									<strong className="section-title d-block mb-1">
										{translationService.translate("TR_FILES")}
									</strong>
									<div className="message-users-container-height">
										{fileLibrary && fileLibrary.length ? (
											<ul className="d-block category-burger-menu">
												{fileLibrary.map(folder => {
													var folderFiles = folder.files;
													return (
														<ul
															key={folder.id}
															className="d-block category-burger-menu background-transparent ">
															<li className="nav-item box-shadow-4 py-1">
																<div className="d-flex align-items-center position-relative my-2">
																	<Link
																		to="#"
																		className={`nav-link dropdown-toggle white-space-break-spaces word-break-break-word`}
																		onClick={event => {
																			event.preventDefault();
																			if (folderId && folderId === folder.id) {
																				setFolderId(null);
																			} else {
																				setFolderId(folder.id);
																			}
																		}}>
																		{folder.folderName}
																	</Link>
																</div>
															</li>
															<ul className="dropdown-sub-menu background-transparent ml-3 mt-1">
																{folderFiles &&
																	folderFiles.map((file, index) => {
																		return (
																			<li key={index} className="nav-item">
																				<Collapse in={folderId === folder.id}>
																					<div className={`content-block`}>
																						<div
																							className={`messages-user-block cursor-default p-2 justify-content-between align-items-center`}>
																							<div className="d-flex align-items-center">
																								{(() => {
																									switch (true) {
																										case file.mimeType &&
																											file.mimeType.includes(
																												IMAGE_KEY,
																											):
																											return (
																												<div
																													className="file-library-item cursor-pointer"
																													// style={{ backgroundImage: `url(${imageSvg})` }}
																													style={{
																														backgroundImage: `url(${file.filePath})`,
																													}}
																													onClick={() =>
																														zoomImage(
																															file.filePath,
																														)
																													}
																												/>
																											);
																										case file.mimeType &&
																											file.mimeType.includes(
																												VIDEO_KEY,
																											):
																											return (
																												<div
																													className="file-library-item cursor-pointer"
																													style={{
																														backgroundImage: `url(${videoSvg})`,
																													}}
																													onClick={() =>
																														zoomVideo(
																															file.filePath,
																														)
																													}
																												/>
																											);

																										case file.mimeType &&
																											file.mimeType.includes(
																												AUDIO_KEY,
																											):
																											return (
																												<div
																													className="file-library-item cursor-pointer"
																													style={{
																														backgroundImage: `url(${audioSvg})`,
																													}}
																													onClick={() =>
																														zoomAudio(
																															file.filePath,
																														)
																													}
																												/>
																											);

																										case file.mimeType &&
																											(file.mimeType.includes(
																												"pdf",
																											) || //pdf
																												file.mimeType.includes(
																													"text",
																												) || //txt
																												file.mimeType.includes(
																													"application",
																												)):
																											return (
																												<div className="file-library-item cursor-pointer">
																													<div
																														className="file-item"
																														onClick={() =>
																															file.filePath
																																? window.open(
																																		file.filePath,
																																  )
																																: null
																														}>
																														<FileSvg />
																													</div>
																												</div>
																											);
																										default:
																											break;
																									}
																								})()}
																							</div>
																							<h3
																								className="message-user-name max-line-1 mr-2"
																								title={file.name}>
																								{file.name}
																							</h3>
																							<InputCheckBox
																								id={`file_${file.id}`}
																								checked={file.checked}
																								blockClassName="keyword-ckeckbox-block"
																								labelValue={" "}
																								disabled={
																									(save &&
																										filesbystudents &&
																										file.checked) ||
																									(!save &&
																										filesbystudents &&
																										!file.checked) ||
																									(filesbystudents &&
																										!studentIds.length)
																										? true
																										: false
																								}
																								onChange={event =>
																									onFileCheckboxChange(
																										event,
																										folder.id,
																										file.id,
																									)
																								}
																							/>
																						</div>
																					</div>
																				</Collapse>
																			</li>
																		);
																	})}
															</ul>
														</ul>
													);
												})}
											</ul>
										) : (
											<div className="no-data-container">
												<div className="no-data-wrapper">
													<p>
														{translationService.translate(
															"TR_YOU_HAVE_NOT_CREATED_FILES",
														)}
													</p>
													<img src={NoDataImage} alt="/" />
												</div>
											</div>
										)}
									</div>
								</div>

								<div className="col-lg-6 col-md-12 col-12 messages-users-container mb-4">
									<strong className="section-title d-block mb-1">
										{translationService.translate("TR_STUDENTS")}
									</strong>
									<div className="message-users-container-height">
										{students && students.length ? (
											<ul className="d-block category-burger-menu">
												{students.map(group => {
													var groupStudents = group.students;
													return (
														<ul
															key={group.id}
															className="d-block category-burger-menu background-transparent ">
															<li className="nav-item box-shadow-4 py-1">
																<div className="d-flex align-items-center position-relative my-2">
																	<Link
																		to="#"
																		className={`nav-link dropdown-toggle white-space-break-spaces word-break-break-word`}
																		onClick={event => {
																			event.preventDefault();
																			if (groupId && groupId === group.id) {
																				setGroupId(null);
																			} else {
																				setGroupId(group.id);
																			}
																		}}>
																		{group.groupName}
																	</Link>
																</div>
															</li>
															<ul className="dropdown-sub-menu background-transparent ml-3 mt-1">
																{groupStudents &&
																	groupStudents.map(student => {
																		return (
																			<li key={student.id} className="nav-item">
																				<Collapse in={groupId === group.id}>
																					<div className={`content-block`}>
																						<div
																							className={`messages-user-block cursor-default py-2 pr-2 justify-content-between align-items-center`}>
																							<div className="d-flex align-items-center file-name-block">
																								<div
																									className="message-user-image cursor-pointer"
																									style={{
																										backgroundImage: `url(${student.avatarFilePath})`,
																									}}
																								/>
																								<h3 className="message-user-name max-line-1">
																									{student.userName}
																								</h3>
																							</div>
																							<InputCheckBox
																								id={`student_${student.id}`}
																								checked={student.checked}
																								blockClassName="keyword-ckeckbox-block"
																								labelValue={" "}
																								disabled={
																									(save &&
																										studentsbyfiles &&
																										student.checked) ||
																									(!save &&
																										studentsbyfiles &&
																										!student.checked) ||
																									(studentsbyfiles &&
																										!fileIds.length)
																										? true
																										: false
																								}
																								onChange={event =>
																									onStudentCheckboxChange(
																										event,
																										group.id,
																										student.id,
																									)
																								}
																							/>
																						</div>
																					</div>
																				</Collapse>
																			</li>
																		);
																	})}
															</ul>
														</ul>
													);
												})}
											</ul>
										) : (
											<div className="no-data-container">
												<div className="no-data-wrapper">
													<p>{translationService.translate("TR_NO_DATA")}</p>
													<img src={NoDataImage} alt="/" />
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{fileLibrary && fileLibrary.length && students && students.length ? (
				<div className="row">
					<div className="col-12">
						<label htmlFor="d-none d-sm-block ">{"\u00A0"}</label>
						<div className="d-flex justify-content-end">
							<ActionButton
								type="submit"
								spinnerId={buttonSpinnerId}
								clicked={attach}
								className="mindalay--btn-default position-relative"
								name={translationService.translate("TR_SAVE")}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default withRouter(AttachFileToStudent);
