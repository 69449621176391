import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LANGUAGE_KEY } from "../Constants/mainKeys";
import Info from "../OrganizationAdminPages/Info/info";
import Overview from "../OrganizationAdminPages/Overview/overview";
import PaymentPackage from "../OrganizationAdminPages/PaymentPackage/paymentPackage";
import Paymentpackagehistory from "../OrganizationAdminPages/PaymentPackage/paymentPackageHistory";
import Messages from "../OrganizationPages/Messages/messages";
import NotFoundPage from "../Pages/404/notFoundPage";
import Notifications from "../Pages/Notifications/notifications";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import ContactUs from "../UserPages/ContactUs/contactUs";
import FooterMenu from "../UserPages/FooterMenu/footerMenu";
import Finances from "./../Pages/Overview/finances";
import Profile from "./../Pages/Profile/profile";
import _Help from "../Pages/Help/_help";
import CustomPaymentPackage from "../UserPages/CustomPaymentPackage/customPaymentPackage";
import RolesService from "../Services/rolesService";
import { useSelector } from "react-redux";
import CoursesForOrgAdmin from "../OrganizationAdminPages/Courses/CoursesForOrgAdmin";
import Course from "../OrganizationAdminPages/Courses/CoursePriceForm";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import MainService from "../Services/mainService";
import GamificationDashboard from "../OrganizationPages/Gamification/Dashboard/GamificationDashboard";
import Resources from "../OrganizationPages/Gamification/Resources/Resources";
import Badges from "../OrganizationPages/Gamification/Badges/Badges";
import Leaderboards from "../OrganizationPages/Gamification/Leaderboards/Leaderboards";
import Badge from "../OrganizationPages/Gamification/Badges/Badge";
import ShopItems from "../OrganizationPages/Gamification/ShopItems/ShopItems";
import Leaderboard from "../OrganizationPages/Gamification/Leaderboards/Leaderboard";
import Quest from "../OrganizationPages/Gamification/Quests/Quest";
import Settings from "../OrganizationPages/Settings/Settings";
import EducationalUnits from "../OrganizationPages/Structure/EducationalUnit/EducationalUnits";
import EducationalUnitForm from "../OrganizationPages/Structure/EducationalUnit/EducationalUnitForm";
import CourseStudents from "../OrganizationAdminPages/Courses/CourseStudents";
import PaymentScheduleForOrgAdmin from "../OrganizationPages/PaymentSchedule/paymentScheduleForOrgAdmin";
import Divisions from "../OrganizationPages/Structure/Division/Divisions";
import DivisionForm from "../OrganizationPages/Structure/Division/DivisionForm";
import AdminWebinars from "../OrganizationAdminPages/Webinar/adminWebinars";
import Users from "../Pages/Users/Users";
import UserForm from "../Pages/Users/UserForm";
import UserTypes from "../Pages/Users/userTypes";
import TranslationsPage from "../OrganizationPages/translations/TranslationsPage";
import Students from "../Pages/students/Students";
import StudentForm from "../Pages/students/StudentForm";
import About from "../UserPages/About/about";
import TermsAndCondition from "../UserPages/TermsAndCondition/termsAndCondition";
import CookiesPocicy from "../UserPages/Policy/cookiesPolicy";
import PrivacyPolicy from "../UserPages/PrivacyPolicy/privacyPolicy";
import Home from "../OrganizationPages/Home/home";

const OrganizationAdminRoutes = () => {
	const { role } = useSelector(state => state.user);
	const [roleService, setRoleService] = useState(null);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	return roleService ? (
		<Switch>
			<Route path="/:language" exact component={Home} />
			<Route
				path="/:language/organization-admin/payment-package"
				exact
				component={PaymentPackage}
			/>
			<Route
				path="/:language/organization-admin/payment-package/paypalsuccess/returnurl"
				exact
				component={PaymentPackage}
			/>
			<Route
				path="/:language/organization-admin/webinar"
				exact
				component={AdminWebinars}
			/>
			<Route
				path="/:language/organization-admin/payment-package/paypalcancel/returnurl"
				exact
				component={PaymentPackage}
			/>
			<Route
				path="/:language/organization-admin/payment-package/cryllexsuccess/returnurl"
				exact
				component={PaymentPackage}
			/>
			<Route
				path="/:language/organization-admin/payment-package/cryllexcancel/returnurl"
				exact
				component={PaymentPackage}
			/>
			<Route
				path="/:language/organization-admin/payment-package/stripesuccess/returnurl"
				exact
				component={PaymentPackage}
			/>
			<Route
				path="/:language/organization-admin/payment-package/stripecancel/returnurl"
				exact
				component={PaymentPackage}
			/>
			{/* "organization-admin/payment-package/history" */}
			{/* <Route
				path="/:language/organization-admin/payment-package/history"
				exact
				component={PaymentPackage}
			/> */}
			<Route
				path="/:language/organization-admin/history"
				exact
				component={Paymentpackagehistory}
			/>
			<Route
				path="/:language/organization-admin/finances"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/stripesuccess/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/stripecancel/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/paypalsuccess/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/paypalcancel/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/cryllexsuccess/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/cryllexcancel/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/overview"
				exact
				component={Overview}
			/>
			<Route
				path="/:language/organization-admin/messages"
				exact
				component={Messages}
			/>
			<Route path="/:language/organization-admin/info" exact component={Info} />
			<Route path="/:language/notifications" exact component={Notifications} />
			<Route
				path="/:language/organization/edit-profile"
				exact
				component={Profile}
			/>
			<Route path="/:language/tariffs/:pageId" exact component={FooterMenu} />
			<Route
				path="/:language/platform-instructions/:pageId"
				exact
				component={FooterMenu}
			/>
			<Route
				path="/:language/company-policy/:pageId"
				exact
				component={FooterMenu}
			/>
			<Route
				path="/:language/personal-data-processing-policy/:pageId"
				exact
				component={FooterMenu}
			/>
			<Route path="/:language/contact-us" exact component={ContactUs} />
			<Route path="/:language/about/:pageId" exact component={About} />
			<Route
				path="/:language/privacy-policy/:pageId"
				exact
				component={PrivacyPolicy}
			/>
			<Route
				path="/:language/cookies-policy/:pageId"
				exact
				component={CookiesPocicy}
			/>
			<Route
				path="/:language/terms-and-condition/:pageId"
				exact
				component={TermsAndCondition}
			/>
			<Route
				path="/:language/organization-admin/custom-payment-package/form"
				exact
				component={CustomPaymentPackage}
			/>
			<Route
				path="/:language/organization-admin/courses"
				exact
				component={CoursesForOrgAdmin}
			/>
			<Route
				path="/:language/organization-admin/courses/program/:_programId"
				exact
				component={CoursesForOrgAdmin}
			/>
			<Route
				path="/:language/organization-admin/courses/program/:_programId/:_subjectId"
				exact
				component={CoursesForOrgAdmin}
			/>
			<Route
				path="/:language/organization-admin/courses/:courseId"
				exact
				component={Course}
			/>
			<Route
				path="/:language/organization-admin/settings"
				exact
				component={Settings}
			/>
			<Route
				path="/:language/educational-unit"
				exact
				component={EducationalUnits}
			/>
			<Route
				path="/:language/educational-unit-form"
				exact
				component={EducationalUnitForm}
			/>
			<Route
				path="/:language/educational-unit-form/:educationalUnitId"
				exact
				component={EducationalUnitForm}
			/>
			<Route path="/:language/students" exact component={Students} />
			<Route path="/:language/student-form" exact component={StudentForm} />
			<Route path="/:language/users" exact component={Users} />
			<Route path="/:language/user-form" exact component={UserForm} />
			<Route path="/:language/user-form/:userId" exact component={UserForm} />
			<Route path="/:language/user-types" exact component={UserTypes} />
			<Route
				path="/:language/translations"
				exact
				component={TranslationsPage}
			/>
			<Route path="/:language/division" exact component={Divisions} />
			<Route path="/:language/division-form" exact component={DivisionForm} />
			<Route
				path="/:language/division-form/:divisionId"
				exact
				component={DivisionForm}
			/>
			<Route
				path="/:language/organization-admin/course/students/:courseId"
				exact
				component={CourseStudents}
			/>
			<Route
				path="/:language/organization-admin/course/students/:courseId/:studentId"
				exact
				component={PaymentScheduleForOrgAdmin}
			/>
			<Route path="/:language/help" exact component={_Help} />
			<Route path="/:language/help/topic/:topicId" exact component={_Help} />
			<Route path="/:language/help/faq/:faqId" exact component={_Help} />
			<Route
				path="/:language/gamification/dashboard"
				exact
				component={GamificationDashboard}
			/>
			<Route
				path="/:language/gamification/resources"
				exact
				component={Resources}
			/>
			<Route path="/:language/gamification/badges" exact component={Badges} />
			<Route
				path="/:language/gamification/leaderboards"
				exact
				component={Leaderboards}
			/>
			<Route
				path="/:language/gamification/leaderboard/:leaderboardId"
				exact
				component={Leaderboard}
			/>
			<Route
				path="/:language/gamification/badge/:badgeId"
				exact
				component={Badge}
			/>
			<Route
				path="/:language/gamification/shop-items"
				exact
				component={ShopItems}
			/>
			<Route
				path="/:language/gamification/quest/:questId"
				exact
				component={Quest}
			/>
			{/* AuthenticationByRefreshToken */}
			<Route
				path="/:language/authentication/:refreshToken"
				exact
				component={AuthenticationByRefreshToken}
			/>
			{/* 404 */}
			<Route path="/:language/not-found/404" exact component={NotFoundPage} />
			{/* Server error page */}
			<Route
				path="/:language/under-maintenance"
				exact
				component={UnderMaintenancePage}
			/>
			<Redirect
				to={`/${localStorage.getItem(LANGUAGE_KEY)}/educational-unit`}
			/>
			{/* <Route component={NotFoundPage} /> */}
		</Switch>
	) : null;
};

export default OrganizationAdminRoutes;
